import React from 'react';
import {hot} from 'react-hot-loader';
import {connect} from 'react-redux';
import {Row, Col, Form, FormGroup, Label, Input, Button, FormText, CustomInput} from 'reactstrap';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';

import {round02} from './utils';
import {TRANSFER_TYPES, calc_lost, calc_found, calc_base_rate, calc_prepay_effect,
    calc_payments_effects, calc_transfer_total, calc_transfer_profit} from './transfers-math';
import {TextInput, NumberInput, DateInput, BoundNumberFieldTransfer} from './form-controls';
import {USER_ROLE} from './Users';
import * as dropzoneCSS from './CalculatorTransfer.css';
import { upload_file, api_base } from './Api';



const field_names = {
    sell_amount: 'Сумма поступившая',
    buy_amount: 'Сумма оплаченная',
};

const initial_state = {
    transfer_type: TRANSFER_TYPES.buy_transfer,

    sell_amount: 1000,
    buy_amount: 1000,

    payments: [
        {date: '2018-04-13', sum: 400000},
        {date: '2018-04-16', sum: 150000},
        {date: '2018-04-20', sum: 313760}
    ]
};

const CHANGE_TRANSFER_FIELD = 'CHANGE_TRANSFER_FIELD';
const change_transfer_field = (field, value) => ({type: CHANGE_TRANSFER_FIELD, field, value});

const CHANGE_PAYMENT_FIELD = 'CHANGE_PAYMENT_FIELD';
const change_payment_field = (payment_no, field, value) => ({type: CHANGE_PAYMENT_FIELD, payment_no, field, value});

const ADD_PAYMENT = 'ADD_PAYMENT';
const add_payment = () => ({type: ADD_PAYMENT});

const REMOVE_PAYMENT = 'REMOVE_PAYMENT';
const remove_payment = (payment_no) => ({type: REMOVE_PAYMENT, payment_no});


export const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case CHANGE_TRANSFER_FIELD:
            return {...state, [action.field]: action.value};

        case CHANGE_PAYMENT_FIELD:
            return {
                ...state,
                payments: state.payments.map((payment, i) => {
                    if (i == action.payment_no)
                        return {...payment, [action.field]: action.value};
                    return payment;
                })
            }

        case ADD_PAYMENT:
            return {
                ...state,
                payments: [...state.payments, {sum: 0, date: ''}]
            }

        case REMOVE_PAYMENT:
            return {
                ...state,
                payments: state.payments.filter((payment, i) => i != action.payment_no)
            }

        default: return state;
    }
};



const get_transfer_fields = (transfer_type) => {
    switch (transfer_type) {
        case TRANSFER_TYPES.buy_transfer:
            return ['sell_amount', 'buy_amount'];
    }
};

const TransferFields = ({params, readOnly, onChangeField, onChangeFields}) => {
    const common = {
        transfer: params,
        readOnly,
        onChangeField: onChangeField
    };

    const fields = get_transfer_fields(params.transfer_type);

    const field_elements = fields.filter(field_name => ['sell_amount', 'buy_amount']).map(field_name =>
        <Col sm={6} key={field_name}>
            <BoundNumberFieldTransfer field_name={field_name} label={field_names[field_name]} {...common}/>
        </Col>
    );

    const rows = [<Row key='field_elements'>{field_elements}</Row>];

    return <React.Fragment>{rows}</React.Fragment>;
};


export const Calculator = ({params, readOnly, role, onChangeField, onChangeFields, onAddPayment, onRemovePayment, onChangePaymentField}) => {
    const is_manager = [USER_ROLE.hunter, USER_ROLE.farmer].indexOf(role) != -1;
    const editable = !readOnly && (
        role == USER_ROLE.admin
	 || USER_ROLE.logist && params.status != 'paid' && !params.locked
     || is_manager && params.status != 'paid' && !params.locked
    );
    const penalty_editable = !readOnly && (
        role == USER_ROLE.admin
	 || USER_ROLE.logist && !params.penalty_paid && !params.locked
     || is_manager && !params.penalty_paid && !params.locked
    );
    const paid_editable = !readOnly && (
        role == USER_ROLE.admin
     || role == USER_ROLE.accountant
    );


    params = {
        transfer_type: 'buy_transfer',
        payments: [],
        ...params
    };

    const lost = calc_lost(params);
    const found = calc_found(params);
    // const profit = found - lost;
    const profit = calc_transfer_profit(params);
    const base_rate = calc_base_rate(params);
    // const prepay_effect = calc_prepay_effect(params);
    // const payments_effects = calc_payments_effects(params);

    const total = calc_transfer_total(params);

    return (
        <Form style={{marginTop: '30px'}}>
            <Row>
                <Col sm={4}>
                    <TextInput readOnly={!editable} label='Грузополучатель'
                        value={params.title || ''} onChange={value => onChangeField('title', value)}
                        />
                </Col>
                <Col sm={4}>
                    <TextInput readOnly={!editable} label='Перевозчик'
                        value={params.basis || ''} onChange={value => onChangeField('basis', value)}
                        />
                </Col>
                <Col sm={4}>
                    <TextInput readOnly={!editable} label='Маршрут'
                        value={params.fuel_type || ''} onChange={value => onChangeField('fuel_type', value)}
                        />
                </Col>
            </Row>
            <Row className='justify-content-between'>
                <Col sm={5}>
                    <TextInput readOnly={!editable} label='Номер автомобиля, ФИО водителя'
                        value={params.ttn_number || ''} onChange={value => onChangeField('ttn_number', value)}
                        />
                </Col>
				<Col sm={2}>
                    <DateInput readOnly={!editable} name='date' label='Дата доставки груза'
                        value={params.date || ''} onChange={(value) => onChangeField('date', value)}/>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label>Статус</Label>
                        <Input readOnly={!paid_editable} disabled={!paid_editable} type='select' value={params.status || 'unpaid'} onChange={ev => onChangeField('status', ev.target.value)}
                            className={classnames({
                                'border-success': params.status == 'paid',
                            })}>
                            <option value='unpaid'>Не оплачено</option>
                            <option value='paid'>Оплачено</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <CustomInput type='checkbox' id='exclude_from_ranktable'
                            disabled={!editable}
                            label='Учитывать в Таблице'
                            checked={!params.exclude_from_ranktable}
                            onChange={ev => onChangeField('exclude_from_ranktable', !ev.target.checked)}/>
                    </FormGroup>
                </Col>
            </Row>

            <hr/>

			<Row>
                <Col sm={6}>
                    <DateInput readOnly={!editable} name='expected_pay_date' label='Дата поступления оплаты'
                        value={params.expected_pay_date || ''} onChange={value => onChangeField('expected_pay_date', value)}/>
                </Col>
                <Col sm={6}>
                    <DateInput readOnly={!editable} name='supplier_pay_date' label='Дата оплаты перевозчику'
                        value={params.supplier_pay_date || ''} onChange={(value) => onChangeField('supplier_pay_date', value)}/>
                </Col>
            </Row>
			
			<TransferFields readOnly={!editable} params={params} onChangeField={onChangeField} onChangeFields={onChangeFields}/>

            <dl className="row mb-0 text-muted small">
                <dd className="col-3 col-sm-2">Прибыль</dd>
                <dt className="col-3 col-sm-2">{round02(profit)}</dt>
                <dd className="col-3 col-sm-2">База</dd>
                <dt className="col-3 col-sm-6">{(round02(base_rate)).toString()}</dt>
            </dl>

            <hr className="mt-4"/>

            <Row>
                <Col md={6}>
                    <h2>Итог: {round02(total)} {total ? '₽' : ''}</h2>
                </Col>
                {role == USER_ROLE.admin && <Col sm={6} className='text-right'>
                    <FormGroup>
                        <CustomInput type='checkbox' id='locked'
                            label='Проверено'
                            readOnly={readOnly} checked={params.locked || false}
                            onChange={ev => onChangeField('locked', ev.target.checked)}/>
                    </FormGroup>
                </Col>}
            </Row>

            <hr/>

            <Row className='mb-3'>
                <Col md={4}>
                    <FileUploader readOnly={!penalty_editable} title='Заявка' file_spec={params.file_upd} onChange={file_spec => onChangeField('file_upd', file_spec)} />
                </Col>
                <Col md={4}>
                    <FileUploader readOnly={!penalty_editable} title='Документы от перевозчика' file_spec={params.file_spec} onChange={file_spec => onChangeField('file_spec', file_spec)} />
                </Col>
                <Col md={4}>
                    <FileUploader readOnly={!penalty_editable} title='УПД' file_spec={params.file_upd_spec} onChange={file_spec => onChangeField('file_upd_spec', file_spec)} />
                </Col>
            </Row>
        </Form>
    );
};


export const TransferForm = hot(module)(connect(
    ({calculator}) => ({
        transfer: calculator,
        result: calc_base_rate(calculator)
    }),

    {
        onChangeField: change_transfer_field,
        onChangePaymentField: change_payment_field,
        onAddPayment: add_payment,
        onRemovePayment: remove_payment
    }
)(Calculator));



class FileUploader extends React.PureComponent {

    constructor() {
        super(...arguments);

        this.onDrop = this.onDrop.bind(this);
        this.onClear = this.onClear.bind(this);

        this.state = {
            loading: false,
            error: null
        };
    }

    async onDrop(files) {
        if (files.length != 1)
            return;

        const file = files[0];

        const filename = file.name;
        this.setState({error: null, loading: true})

        try
        {
            const {ok, id} = await upload_file('upload-file', {}, file);
            if (ok) {
                this.setState({loading: false, error: null});
                this.props.onChange({id, filename, size: file.size});
            }
            else
                this.setState({loading: false, error: 'Не удалось загрузить файл'});
        }
        catch (e)
        {
            this.setState({loading: false, error: 'Не удалось загрузить файл'});
        }
    }

    onClear(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.onChange(null);
    }
    
    render() {
        return <Dropzone onDrop={this.onDrop} disabled={this.props.readOnly || this.state.loading}>
            {({getRootProps, getInputProps, isDragActive}) => 
                <div {...getRootProps({className: classnames(dropzoneCSS.dropzone, {
                    [dropzoneCSS.active]: isDragActive,
                    [dropzoneCSS.filled]: this.props.file_spec != null,
                    [dropzoneCSS.error]: this.state.error != null
                })})}>
                    <h5>{this.props.title}</h5>
                    <input {...getInputProps()}/>

                    {this.props.file_spec == null &&
                        <small>Перетащите сюда файл или&nbsp;нажмите для выбора</small>}

                    {this.props.file_spec &&
                        <div className={dropzoneCSS.row}>
                            <a href={`${api_base}download-file?id=${this.props.file_spec.id}`} className={dropzoneCSS.title}
                                onClick={event => event.stopPropagation()}
                                title={this.props.file_spec.filename}>
                                <span className={`oi oi-document ${dropzoneCSS.icon}`}/>
                                {this.props.file_spec.filename}
                            </a>
                            {!this.props.readOnly &&
                                <a href='#' className='text-danger' onClick={this.onClear}>
                                    <span className='oi oi-trash'/>
                                </a>}
                        </div>}

                    {this.state.loading &&
                        <div className={dropzoneCSS.loading}>загрузка файла...</div>}

                    {this.state.error &&
                        <div>{this.state.error}</div>}
                </div>
            }
        </Dropzone>;
    }
};

import React from 'react';
import {Row, Col, Form, FormGroup, Label, Input, Button, Alert, FormText} from 'reactstrap';
import { round02 } from './utils';
import { Page } from './Page';


const Hint = ({children}) =>
    <td className='text-muted' style={{paddingLeft: '2em', verticalAlign: 'top'}}>{children}</td>;


export class TransportCalc extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            total_km: 20000,
            ko: 1.5,
            own_km_fraction: 0.5,

            cost_per_km_with_cargo: 25,
            cost_per_km_without_cargo: 20,

            hire_cost_per_km: 65,
            hire_earn_per_km: 75,
            own_earn_per_km: 65,
            alien_earn_per_km: 70,
        };
    }

    input_field({state_name, label, step, min, max}) {
        return <FormGroup>
            <Label>{label}</Label>
            <Input type='number' value={this.state[state_name]} step={step} min={min} max={max}
                onChange={(ev) => this.setState({[state_name]: parseFloat(ev.target.value)})}/>
        </FormGroup>;
    }

    render() {
        const {total_km, ko, own_km_fraction,
               cost_per_km_with_cargo, cost_per_km_without_cargo,
               hire_cost_per_km, hire_earn_per_km,
               own_earn_per_km, alien_earn_per_km} = this.state;

        const km_with_cargo = total_km * ko / (1 + ko);
        const km_without_cargo = total_km / (1 + ko);

        const cost_cars = km_with_cargo * cost_per_km_with_cargo + km_without_cargo * cost_per_km_without_cargo;

        const own_km = total_km * own_km_fraction;
        const alien_km = total_km * (1 - own_km_fraction);
        const hire_km = (total_km - own_km) / 2;

        const cost_hire = hire_km * hire_cost_per_km;
        const earn_hire = hire_km * hire_earn_per_km;
        const earn_alien = alien_km * alien_earn_per_km;
        const earn_own = own_km * own_earn_per_km;

        const total_cost = cost_cars + cost_hire;
        const total_earn = earn_own + earn_alien + earn_alien;
        const profit = total_earn - total_cost;

        const total_km_str = round02(total_km);

        return <Page>
            <Form>
            <Row>
                <Col sm={4}>
                    {this.input_field({state_name: 'total_km', label: 'Общий пробег в месяц'})}
                </Col>
                <Col sm={4}>
                    {this.input_field({state_name: 'ko', label: 'KO', step: 0.1, min: 0})}
                </Col>
                <Col sm={4}>
                    {this.input_field({state_name: 'own_km_fraction', label: 'Доля своих рейсов', step: 0.1, min: 0, max: 1})}
                </Col>
                <Col sm={4}>
                    {this.input_field({state_name: 'cost_per_km_with_cargo', label: 'Себестоимость км с грузом'})}
                </Col>
                <Col sm={4}>
                    {this.input_field({state_name: 'cost_per_km_without_cargo', label: 'Себестоимость км без груза'})}
                </Col>

                <Col sm={4}>
                    {this.input_field({state_name: 'hire_cost_per_km', label: 'Затраты на км найма'})}
                </Col>
                <Col sm={4}>
                    {this.input_field({state_name: 'hire_earn_per_km', label: 'Выручка с км найма'})}
                </Col>
                <Col sm={4}>
                    {this.input_field({state_name: 'own_earn_per_km', label: 'Выручка с км своих рейсов'})}
                </Col>
                <Col sm={4}>
                    {this.input_field({state_name: 'alien_earn_per_km', label: 'Выручка с км чужих рейсов'})}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert color='secondary'>
                        <h5>Расчёт</h5>

                        <table>
                            <tbody>
                                <tr>
                                    <td>Пробег с грузом: {round02(km_with_cargo)} км</td>
                                    <Hint>({total_km_str} × {ko}) ÷ (1 + {ko})</Hint>
                                </tr>
                                <tr>
                                    <td style={{paddingBottom: '1em'}}>Пробег без груза: {round02(km_without_cargo)} км</td>
                                    <Hint>{total_km_str} ÷ (1 + {ko})</Hint>
                                </tr>

                                <tr>
                                    <td>Пробег своих рейсов: {round02(own_km)} км</td>
                                    <Hint>{total_km_str} × {own_km_fraction}</Hint>
                                </tr>
                                <tr>
                                    <td>Пробег чужих рейсов: {round02(alien_km)} км</td>
                                    <Hint>{total_km_str} × (1 – {own_km_fraction})</Hint>
                                </tr>
                                <tr>
                                    <td style={{paddingBottom: '1em'}}>Пробег найм: {round02(hire_km)} км</td>
                                    <Hint>({total_km_str} – {round02(own_km)}) ÷ 2</Hint>
                                </tr>

                                <tr>
                                    <td>Себестоимость своих машин: {round02(cost_cars)} ₽</td>
                                    <Hint>{round02(km_with_cargo)} × {cost_per_km_with_cargo} + {round02(km_without_cargo)} × {cost_per_km_without_cargo}</Hint>
                                </tr>
                                <tr>
                                    <td style={{paddingBottom: '1em'}}>Стоимость найма: {round02(cost_hire)} ₽</td>
                                    <Hint>{round02(hire_km)} × {hire_cost_per_km}</Hint>
                                </tr>

                                <tr>
                                    <td>Выручка со своих рейсов: {round02(earn_own)} ₽</td>
                                    <Hint>{round02(own_km)} × {own_earn_per_km}</Hint>
                                </tr>
                                <tr>
                                    <td>Выручка с чужих рейсов: {round02(earn_alien)} ₽</td>
                                    <Hint>{round02(alien_km)} × {alien_earn_per_km}</Hint>
                                </tr>
                                <tr>
                                    <td style={{paddingBottom: '1em'}}>Выручка с найма: {round02(earn_hire)} ₽</td>
                                    <Hint>{round02(hire_km)} × {hire_earn_per_km}</Hint>
                                </tr>

                                <tr>
                                    <td>Полные затраты: {round02(total_cost)} ₽</td>
                                    <Hint>{round02(cost_cars)} + {round02(cost_hire)}</Hint>
                                </tr>
                                <tr>
                                    <td>Полная выручка: {round02(total_earn)} ₽</td>
                                    <Hint>{round02(earn_own)} + {round02(earn_alien)} + {round02(earn_hire)}</Hint>
                                </tr>
                                <tr>
                                    <td>Прибыль: <b>{round02(profit)}</b> ₽</td>
                                    <Hint>{round02(total_earn)} – {round02(total_cost)}</Hint>
                                </tr>
                            </tbody>
                        </table>
                    </Alert>
                </Col>
            </Row>
            </Form>
        </Page>;
    }
}

import React from 'react';
import {connect} from 'react-redux';
import {Row, Col, Table, Button, Badge, Nav, NavItem, NavLink, TabContent, TabPane,
        Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter,
        InputGroup, InputGroupAddon, Card, CardTitle, CardText} from 'reactstrap';
import {push} from 'react-router-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import {get, post} from './Api';
import {Calculator} from './CalculatorTransporter';
import {LOGOUT} from './Login';
import {load_users} from './Users';
import {date_from_string, iso_date_string, unique_sort, format_price} from './utils.js';
import {transporter_validation_errors} from './transporters-math';
import {BaseField, TextInput} from './form-controls';
import Select from 'react-select';
import {Page} from './Page';


const LOADING_TRANSPORTERS = 'LOADING_TRANSPORTERS';
const LOAD_TRANSPORTERS__OK = 'LOAD_TRANSPORTERS__OK';
const LOAD_TRANSPORTERS__FAIL = 'LOAD_TRANSPORTERS__FAIL';

const SAVING_TRANSPORTER = 'SAVING_TRANSPORTER';
const SAVING_TRANSPORTER__OK = 'SAVING_TRANSPORTER__OK';
const SAVING_TRANSPORTER__FAIL = 'SAVING_TRANSPORTER__FAIL';

const SAVING_NEW_TRANSPORTER = 'SAVING_NEW_TRANSPORTER';
const SAVING_NEW_TRANSPORTER__OK = 'SAVING_NEW_TRANSPORTER__OK';
const SAVING_NEW_TRANSPORTER__FAIL = 'SAVING_NEW_TRANSPORTER__FAIL';

const DELETE_TRANSPORTER = 'DELETE_TRANSPORTER';

const LOADING_ALL_TRANSPORTERS = 'LOADING_ALL_TRANSPORTERS';
const LOAD_ALL_TRANSPORTERS__OK = 'LOAD_ALL_TRANSPORTERS__OK';
const LOAD_ALL_TRANSPORTERS__FAIL = 'LOAD_ALL_TRANSPORTERS__FAIL';


export const load_transporters = (user_id) => async (dispatch) => {
    dispatch({type: LOADING_TRANSPORTERS, user_id});
    try {
        const response = await get('transporters', {user_id});
        dispatch({type: LOAD_TRANSPORTERS__OK, user_id, transporters: response});
    }
    catch (failure) {
        dispatch({type: LOAD_TRANSPORTERS__FAIL, user_id, loading_failure: failure});
    }
};

const load_all_transporters = (done_filter, date_from, date_to) => async (dispatch) => {
    dispatch({type: LOADING_ALL_TRANSPORTERS, date_from, date_to});
    try {
        const response = await get('all-transporters', {
            done_filter: done_filter,
            date_from: null,
            date_to: null,
        });
        dispatch({
            type: LOAD_ALL_TRANSPORTERS__OK,
            done_filter,
            date_from, date_to,
            transporters: response.transporters,
            usernames: response.usernames
        });
    }
    catch (failure) {
        dispatch({type: LOAD_ALL_TRANSPORTERS__FAIL, loading_failure: failure});
    }
};

const save_transporter = (user_id, transporter_id, params, ok_url) => async (dispatch) => {
    dispatch({type: SAVING_TRANSPORTER, user_id});
    try {
        const response = await post('save-transporter', {
            user_id, transporter_id,
            params: {
                ...params
            }
        });
        dispatch({type: SAVING_TRANSPORTER__OK, user_id, transporter_id, params});
        dispatch(push(ok_url));
    }
    catch (failure) {
        console.log(failure);
        dispatch({type: SAVING_TRANSPORTER__FAIL, user_id, failure});
    }
};

const save_new_transporter = (user_id, params, ok_url) => async (dispatch) => {
    dispatch({type: SAVING_NEW_TRANSPORTER, user_id});
    try {
        const response = await post('save-new-transporter', {
            user_id, 
            params: {
                ...params
            }
        });
        dispatch({
            type: SAVING_NEW_TRANSPORTER__OK,
            user_id,
            transporter_id: response.id,
            params: response.params
        });
        dispatch(push(ok_url));
    }
    catch (failure) {
        console.log(failure);
        dispatch({type: SAVING_NEW_TRANSPORTER__FAIL, user_id, failure});
    }
};

const delete_transporter = (user_id, transporter_id) => (dispatch) => {
    if (confirm('Действительно удалить запись о перевозчике?')) {
        post('delete-transporter', {user_id, id: transporter_id});
        dispatch({type: DELETE_TRANSPORTER, user_id, transporter_id});
    }
};


const by_user_initial = {
    loaded: false,
    loading: false,
    fail: false,

    transporters: [],

    saving_transporter: false,
    saving_transporter_fail: false,

    saving_new_transporter: false,
    saving_new_transporter_fail: false,
};


const sort_transporters = (transporters) => transporters.slice().sort((d1, d2) => d1.params.date > d2.params.date);

const user_reducer = (state = by_user_initial, action) => {
    switch (action.type) {
        case LOADING_TRANSPORTERS: return {...state, loading: true, fail: false};
        case LOAD_TRANSPORTERS__OK: return {...state, loading: false, loaded: true, fail: false, transporters: action.transporters};
        case LOAD_TRANSPORTERS__FAIL: return {...state, loading: false, loaded: true, fail: true, transporters: []};

        case SAVING_TRANSPORTER: return {...state, saving_transporter: true, saving_transporter_fail: false};
        case SAVING_TRANSPORTER__OK: return {
            ...state,
            saving_transporter: false,
            saving_transporter_fail: false,
            transporters: sort_transporters(state.transporters.map(transporter => transporter.id == action.transporter_id ? {...transporter, params: action.params} : transporter))
        };
        case SAVING_TRANSPORTER__FAIL: return {...state, saving_transporter: false, saving_transporter_fail: true};

        case SAVING_NEW_TRANSPORTER: return {...state, saving_new_transporter: true, saving_new_transporter_fail: false};
        case SAVING_NEW_TRANSPORTER__OK:
            return {
                ...state,
                saving_new_transporter: false,
                saving_new_transporter_fail: false,
                transporters: sort_transporters([
                    ...state.transporters,
                    {id: action.transporter_id, params: action.params}
                ])
            };
        case SAVING_NEW_TRANSPORTER__FAIL: return {...state, saving_new_transporter: false, saving_new_transporter_fail: true};

        case DELETE_TRANSPORTER: return {
            ...state,
            transporters: state.transporters.filter(transporter => transporter.id != action.transporter_id)
        }

        case LOGOUT: return by_user_initial;

        default: return state;
    }
};


const all_transporters_initial = {
    loaded: false,
    loading: false,
    fail: false,

    date_from: null,
    date_to: null,
    transporters: [],
    usernames: {}
};

const all_transporters_reducer = (state = all_transporters_initial, action) => {
    switch (action.type) {
        case LOADING_ALL_TRANSPORTERS: return {
            ...state,
            loading: true, fail: false,
            date_from: action.date_from, date_to: action.date_to
        };
        case LOAD_ALL_TRANSPORTERS__OK: return {
            ...state,
            loading: false, loaded: true, fail: null,
            date_from: action.date_from,
            date_to: action.date_to,
            transporters: action.transporters,
            usernames: action.usernames
        };
        case LOAD_ALL_TRANSPORTERS__FAIL: return {
            ...state,
            loading: false, loaded: true,
            fail: action.loading_failure
        };
        default: return state;
    }
};


const initial = {
    by_user: by_user_initial,
    all_transporters: all_transporters_initial
}

export const reducer = (state = initial, action) => {
    if (action.user_id != null) {
        return {
            ...state,
            by_user: {
                ...state.by_user,
                [action.user_id]: user_reducer(state.by_user[action.user_id], action)
            }
        }
    }
    else
        return {
            ...state,
            all_transporters: all_transporters_reducer(state.all_transporters, action)
        };
};


const cmp_transporters_by_date = (a, b) => {
    if (! a.params.date) return -1;
    if (! b.params.date) return +1;
    return date_from_string(b.params.date).getTime() - date_from_string(a.params.date).getTime();
};

const TransportersListImpl = ({transporters, role, view_href, edit_href, firstHeader, firstCell, onDeleteTransporter, onPushUrl}) => {
    const rows = transporters.slice().sort(cmp_transporters_by_date).map(transporter => {

        // const tarif_name = tarif_options(transporter.params.tarif) ? tarif_options(transporter.params.tarif).name : "";
        // const tarif_price = tarif_options(transporter.params.tarif).price;

        return <tr key={transporter.id}
                    onClick={(ev) => {
                       // FIXME: too hacky :(
                       if (role == "admin" && ev.target.tagName != 'A' && (ev.target.parentElement && ev.target.parentElement.tagName) != 'A')
                           onPushUrl(edit_href(transporter.user_id, transporter.id))}
                    }
                    style={{
                       cursor: role == "admin" ? 'pointer' : initial
                    }}>
            {firstCell && firstCell(transporter)}
            <td>{transporter.params && transporter.params.region}</td>
            <td>{transporter.params && transporter.params.title}</td>
            <td>{transporter.params && transporter.params.name}</td>
            <td>{transporter.params && transporter.params.phone}</td>
            <td>{transporter.params && transporter.params.calibration}</td>
            <td>{transporter.params && transporter.params.calibrationByCell}</td>
            <td width='1' style={{whiteSpace: 'nowrap'}}>
                {view_href &&
                    <Link to={view_href(transporter.user_id, transporter.id)} className='btn btn-sm btn-outline-primary'>
                        <span className='oi oi-eye'/>
                    </Link>
                }
                {role == "admin" && <Link to={edit_href(transporter.user_id, transporter.id)} className={
                    classnames('btn', 'btn-sm', 'ml-2', {
                        'btn-outline-primary': !view_href,
                        'btn-outline-info': !!view_href,
                    })
                }>
                    {view_href ? <span className='oi oi-pencil'/> : 'Редактировать'}
                </Link>}
                {onDeleteTransporter && role == "admin" && <a href='#' className='btn btn-sm btn-outline-danger ml-4'
                    onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteTransporter(transporter.user_id, transporter.id);
                    }}>
                    <span className='oi oi-trash'/>
                </a>}
            </td>
        </tr>;
    });

    // const rows2 = transporters.slice().sort(cmp_transporters_by_date).map(transporter => {

    //     return <Col sm="4" key={transporter.id}
    //             onClick={(ev) => {
    //                 // FIXME: too hacky :(
    //                 if (role == "admin" && ev.target.tagName != 'A' && (ev.target.parentElement && ev.target.parentElement.tagName) != 'A')
    //                     onPushUrl(edit_href(transporter.user_id, transporter.id))}
    //             }
    //             style={{
    //                 cursor: 'pointer',
    //                 backgroundColor: transporter.params.exclude_from_ranktable ? '#e0e0e0' : null
    //             }}>
    //                 <Card body>
    //                     <CardTitle>Регион: {transporter.params && transporter.params.region}</CardTitle>
    //                     <hr/>
    //                     <CardText>Перевозчик: {transporter.params && transporter.params.title}</CardText>
    //                     <CardText>Имя: {transporter.params && transporter.params.name}</CardText>
    //                     <CardText>Телефон: {transporter.params && transporter.params.phone}</CardText>
    //                     <CardText>Калибровка общая: {transporter.params && transporter.params.calibration}</CardText>
    //                     <CardText>Калибровка по отсекам: {transporter.params && transporter.params.calibrationByCell}</CardText>
    //                     <hr/>
    //                     <div width='1' style={{whiteSpace: 'nowrap'}}>
    //                         {view_href &&
    //                             <Link to={view_href(transporter.user_id, transporter.id)} className='btn btn-sm btn-outline-primary'>
    //                                 {view_href && role == "admin" ? <span className='oi oi-eye'/> : 'Подробнее'}
    //                             </Link>
    //                         }
    //                         {role == "admin" && <Link to={edit_href(transporter.user_id, transporter.id)} className={
    //                             classnames('btn', 'btn-sm', 'ml-2', {
    //                                 'btn-outline-primary': !view_href,
    //                                 'btn-outline-info': !!view_href,
    //                             })
    //                         }>
    //                             {view_href ? <span className='oi oi-pencil'/> : 'Редактировать'}
    //                         </Link>}
    //                         {onDeleteTransporter && role == "admin" && <a href='#' className='btn btn-sm btn-outline-danger ml-4'
    //                             onClick={(ev) => {
    //                                 ev.preventDefault();
    //                                 onDeleteTransporter(transporter.user_id, transporter.id);
    //                             }}>
    //                             <span className='oi oi-trash'/>
    //                         </a>}
    //                     </div>
    //                 </Card>
    //             </Col>;
        
    // });

    return <div className='mt-4'>
        <Table responsive hover>
            <thead>
                <tr>
                    {firstHeader && firstHeader()}
                    <th>Регион</th>
                    <th>Перевозчик</th>
                    <th>Имя</th>
                    <th>Телефон</th>
                    <th>Калибровка общая</th>
                    <th>Калибровка по отсекам</th>
                    <th>Действия</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    </div>
};

export const TransportersList = connect(
    (state, {user_id, transporter_id}) => {
        return {
            user_id,
            role: state.login.role,
        }
    },

    {
        onPushUrl: push
    }
)(TransportersListImpl);

const transporters_url = (date_from, date_to, done_filter) =>
    `${iso_date_string(date_from)}--${iso_date_string(date_to)}--${done_filter}`;


class TransportersPageImpl extends React.Component {
    constructor(props) {
        super(...arguments);

        this.setDateFrom = this.setDateFrom.bind(this);
        this.setDateTo = this.setDateTo.bind(this);
        this.setDoneFilter = this.setDoneFilter.bind(this);

        this.state = {
            searchRegion: '',
            searchTitle: '',
            searchPhone: '',
            searchCalibration: '',
            searchCalibrationByCell: ''
        };
    }

    redirect({date_from, date_to, done_filter}) {
        this.props.onRedirect(transporters_url(
            date_from !== undefined ? date_from : this.props.date_from,
            date_to !== undefined ? date_to : this.props.date_to,
            done_filter !== undefined ? done_filter : this.props.done_filter,
        ));
    }

    filter_transporters() {
        
        let transporters = this.props.transporters;
        
        const normalize_for_search = subject =>
            (subject || '').trim().toLowerCase().replace(/,/g, '.');

        if(this.state.searchRegion){
            const needleRegion = this.state.searchRegion;
            
            const transporter_matches_search_region = transporter => {
                if (needleRegion.length == 0) return true;
                const results = needleRegion.map(function(item) {
                    if (normalize_for_search(transporter.params.region).indexOf(normalize_for_search(item.value)) !== -1) return true;
                });
               
                if (results.indexOf(true) >= 0) return true;
                return false;
            }
            transporters = transporters.filter(transporter_matches_search_region);
        }
        const needleTitle = normalize_for_search(this.state.searchTitle);
        const needlePhone = normalize_for_search(this.state.searchPhone);
        const needleCalibration = normalize_for_search(this.state.searchCalibration);
        const needleCalibrationByCell = normalize_for_search(this.state.searchCalibrationByCell);
        const transporter_matches_search_title = transporter => {
            if (needleTitle.length == 0) return true;
            if (normalize_for_search(transporter.params.title).indexOf(needleTitle) !== -1) return true;
            return false;
        }
        const transporter_matches_search_phone = transporter => {
            if (needlePhone.length == 0) return true;
            if (normalize_for_search(transporter.params.phone).indexOf(needlePhone) !== -1) return true;
            return false;
        }
        const transporter_matches_search_calibration = transporter => {
            if (needleCalibration.length == 0) return true;
            const calibration = normalize_for_search(transporter.params.calibration);
            if(needleCalibration.length == calibration.length){
                const t = needleCalibration.split("", needleCalibration.length);
                const results = t.map(function(item) {
                    let nt;
                    nt = item;
                    if(item == "*"){
                        nt = "[0-9]";
                    }
                    return nt;
                });
                let newStr = results.join("");
                const regexp = new RegExp("^"+newStr, "gi");
                                                                
                if (normalize_for_search(transporter.params.calibration).search(regexp) !== -1) return true;
            }
            return false;
        }
        
        const transporter_matches_search_calibrationByCell = transporter => {
            if (needleCalibrationByCell.length == 0) return true;
            const calibration = normalize_for_search(transporter.params.calibrationByCell);
            const cell = calibration.split("/", calibration.length);
            const digit = needleCalibrationByCell.split("", needleCalibrationByCell.length);
            const results = digit.map(function(item) {
                let nt;
                nt = item;
                if(item == "*"){
                    nt = "[0-9]";
                }
                return nt;
            });
            let newStr = results.join("");
            
            const inCells = cell.map(function(numSecond) {
                const regexp = new RegExp("^"+newStr, "gi");
                let t = numSecond.search(regexp);
                if(numSecond.length == needleCalibrationByCell.length){
                    return t;  
                }
            });
            if (inCells.indexOf(0) >= 0) return true;
            return false;
        }
        
        transporters = transporters.filter(transporter_matches_search_title);
        transporters = transporters.filter(transporter_matches_search_phone);
        transporters = transporters.filter(transporter_matches_search_calibration);
        transporters = transporters.filter(transporter_matches_search_calibrationByCell);
        return transporters;
    }

    setDateFrom(ev) {
        const date_from = date_from_string(ev.target.value);
        this.redirect({date_from});
        this.raiseOnQueryChanged({date_from});
    }

    setDateTo(ev) {
        const date_to = date_from_string(ev.target.value);
        this.redirect({date_to});
        this.raiseOnQueryChanged({date_to});
    }

    setDoneFilter(done_filter) {
        this.redirect({done_filter});
        this.raiseOnQueryChanged({done_filter});
    }

    raiseOnQueryChanged({done_filter, date_from, date_to}) {
        if (!this.props.onQueryChanged)
            return;
        this.props.onQueryChanged(
            done_filter === undefined ? this.props.done_filter : done_filter,
            date_from === undefined ? this.props.date_from : date_from,
            date_to === undefined ? this.props.date_to : date_to
        );
    }

    render() {
       
        const transporters_all = this.props.transporters;
        const transporter_list = transporters_all.map((transporters) => {            
            return transporters.params.region;
        });
        const transporter_list_unique = unique_sort(transporter_list).map((transporters) => {
            return {value: transporters, label: transporters};
        });

        const transporters = this.filter_transporters();

        const formRegion = <Col sm={3}>
                <FormGroup>
                        <Label>Поиск по Региону</Label>
                        <Select 
                            options={transporter_list_unique}
                            value={this.state.searchRegion}
                            onChange={value => this.setState({searchRegion: value})}
                            placeholder='Выберите регионы...' 
                            isMulti="true"
                            />
                </FormGroup>
            </Col>;
        const formPhone = <Col sm={2}>
                <TextInput label='Поиск по телефону' value={this.state.searchPhone} onChange={value => this.setState({searchPhone: value})}/>
            </Col>;
        const formTitle = <Col sm={3}>
                <TextInput label='Поиск по Перевозчику' value={this.state.searchTitle} onChange={value => this.setState({searchTitle: value})}/>
            </Col>;
        const formCalibration = <Col sm={2}>
                <TextInput label='Поиск по Калибровке общей' value={this.state.searchCalibration} onChange={value => this.setState({searchCalibration: value})}/>
            </Col>;
        const formCalibrationByCell = <Col sm={2}>
                <TextInput label='Поиск по Калибровке по отсекам' value={this.state.searchCalibrationByCell} onChange={value => this.setState({searchCalibrationByCell: value})}/>
            </Col>;

        
        let content = null;
        if (this.props.loading)
            content = <p className="text-muted">Загрузка...</p>;

        else if (this.props.fail)
            content = <p className="text-danger">Ошибка загрузки</p>;

        else
            content = <React.Fragment>
                <React.Fragment>
                    <TransportersList transporters={transporters}
                        period_start={this.props.date_from}
                        period_end={this.props.date_to}
                        view_href={this.props.view_href}
                        edit_href={this.props.edit_href}
                        onDeleteTransporter={this.props.onDeleteTransporter}
                        firstHeader={this.props.firstHeader}
                        firstCell={this.props.firstCell}/>
                </React.Fragment>
            </React.Fragment>;


        return <React.Fragment>
            <Row className='mt-2 mb-3'>
                {formRegion}
                {formTitle}
                {formPhone}
                {formCalibration}
                {formCalibrationByCell}
            </Row>
            {content}
        </React.Fragment>;
    }
}

export const TransportersPage = connect(
    null,
    (dispatch) => ({
        onDeleteTransporter: (user_id, transporter_id) => dispatch(delete_transporter(user_id, transporter_id)),
    })
)(TransportersPageImpl);


class MyTransportersPageImpl extends React.Component {
    componentDidMount() {
        this.props.onLoadTransporters(this.props.user_id);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.loaded && !nextProps.loading)
            this.props.onLoadTransporters(nextProps.user_id);
    }

    render() {
        return <Page wide>
            <Row className='align-items-center'>
                <Col xs={7}><h1>Перевозчики</h1></Col>
                {this.props.role == "admin" && <Col xs={5} className='text-right'>
                    <Link to='/new-transporter' className='btn btn-outline-success'>Добавить перевозчика</Link>
                </Col>}
            </Row>
            <TransportersPage
                {...this.props}
                edit_href={(user_id, transporter_id) => `/transporter/${transporter_id}/edit`}
				view_href={(user_id, transporter_id) => `/transporter/${transporter_id}/view`}
            />
        </Page>;
    }
}

export const MyTransportersPage = connect(
    (state) => ({
        user_id: state.login.user_id,
        role: state.login.role,
        ...(state.transporters.by_user[state.login.user_id] || by_user_initial)
    }),
    dispatch => ({
        onLoadTransporters: user_id => dispatch(load_transporters(user_id))
    })
)(MyTransportersPageImpl);


class OthersTransportersPageImpl extends React.Component {
    componentDidMount() {
        if (!this.props.username)
            this.props.onLoadUsers();

        this.props.onLoadTransporters(this.props.user_id);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.username)
            nextProps.onLoadUsers();

        if (!nextProps.loaded && !nextProps.loading)
            this.props.onLoadTransporters(nextProps.user_id);
    }


    render() {
        return <Page wide>
		    <Link to={this.props.back_href}>← к списку перевозчиков</Link>
            <Row className='align-items-center'>
                <Col xs={7}><h1>
                    Перевозчики пользователя {this.props.username}
                </h1></Col>
                {this.props.role != "accountant" &&
					<Col xs={5} className='text-right'>
						<Link to={`/users/${this.props.user_id}/new-transporter`} className='btn btn-outline-success'>Добавить перевозчика</Link>
					</Col>
				}
            </Row>
            <TransportersPage
                {...this.props}
				role={this.props.role}
                edit_href={(user_id, transporter_id) => `/users/${user_id}/transporter/${transporter_id}/edit`}
                view_href={(user_id, transporter_id) => `/users/${user_id}/transporter/${transporter_id}/view`}
            />
        </Page>;
    }
}

export const OthersTransportersPage = connect(
    (state, {user_id}) => {
        const user = (state.users.users || []).filter(user => user.id == user_id)[0];
        return {
            username: user && user.username,
			role: state.login.role,
            ...(state.transporters.by_user[user_id] || by_user_initial),
        }
    },
    dispatch => ({
        onLoadUsers: () => dispatch(load_users()),
        onLoadTransporters: user_id => dispatch(load_transporters(user_id))
    })
)(OthersTransportersPageImpl);


class AllTransportersPageImpl extends React.PureComponent {
    componentDidMount() {
        this.props.onLoadTransporters(this.props.done_filter, this.props.date_from, this.props.date_to);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.state.loaded && !nextProps.state.loading)
            this.props.onLoadTransporters(this.props.done_filter, nextProps.date_from, nextProps.date_to);
    }

    render() {
        return <Page wide>
            <Row className='align-items-center'>
                <Col><h1>Все перевозчики:</h1></Col>
            </Row>
            <TransportersPage
                {...this.props.state}
                date_from={this.props.date_from}
                date_to={this.props.date_to}
                done_filter={this.props.done_filter}
                tab={this.props.tab}
                onRedirect={this.props.onRedirect}
                edit_href={(user_id, transporter_id) => `/users/${user_id}/transporter/${transporter_id}/edit`}
                view_href={(user_id, transporter_id) => `/users/${user_id}/transporter/${transporter_id}/view`}
                onQueryChanged={this.props.onLoadTransporters}
                firstHeader={() => <th>Менеджер</th>}
                firstCell={transporter => <td>{this.props.state.usernames[transporter.user_id]}</td>}
            />
        </Page>
    }
}

export const AllTransportersPage = connect(
    state => ({state: state.transporters.all_transporters}),
    {
        onLoadTransporters: load_all_transporters
    }
)(AllTransportersPageImpl);



class StatefulCalculator extends React.Component {
    constructor(props) {
        super(props);

        this.state = props.transporter || {
            params: {
                transporter_type: 'buy_transporter'
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.transporter != nextProps.transporter)
            this.setState(nextProps.transporter);
    }

    render() {
        return <Calculator
            params={this.state.params}
            role={this.props.role}
            onChangeField={(field, value) => this.setState({params: {...this.state.params, [field]: value}})}
        />
    }
}


class EditOrNewTransporterForm extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            shown_errors: null
        };

        this.calculator = React.createRef();

        this.onSave = this.onSave.bind(this);
        this.closeErrors = this.closeErrors.bind(this);
    }

    onSave() {
        const errors = transporter_validation_errors(this.calculator.current.state.params);
        this.setState({shown_errors: errors});
        if (errors.length == 0)
            this.props.onSave(this.calculator.current.state.params);
    }

    closeErrors() { this.setState({shown_errors: null}); }

    buttons() {
        return <React.Fragment>
            <Button outline color='secondary' onClick={this.props.onCancel}>Отмена</Button>
            {' '}
            <Button outline color='primary' onClick={this.onSave} disabled={this.props.saving}>
                {this.props.fail ? 'Ошибка сохранения' : 'Сохранить'}
            </Button>
        </React.Fragment>
    }

    render() {
        return <Page>
            <Row className='align-items-center'>
                <Col sm={8}><h1>{this.props.title}</h1></Col>
                <Col sm={4} className="text-sm-right">{this.buttons()}</Col>
            </Row>
            <StatefulCalculator ref={this.calculator} transporter={this.props.transporter} role={this.props.role}/>
            <Row>
                <Col className="text-sm-right">{this.buttons()}</Col>
            </Row>

            <Modal isOpen={this.state.shown_errors && this.state.shown_errors.length > 0} toggle={this.closeErrors}>
                <ModalHeader toggle={this.closeErrors}>Форма содержит ошибки</ModalHeader>
                <ModalBody>
                    <ul>{(this.state.shown_errors || []).map((error, i) =>
                        <li key={i}>{error}</li>
                    )}</ul>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={this.closeErrors}>Вернуться</Button>
                </ModalFooter>
            </Modal>
        </Page>;
    }
}


class TransporterEditPageImpl extends React.Component {
    componentDidMount() {
        // if (!this.props.loaded && !this.props.loading)
            this.props.onLoadTransporters(this.props.user_id);
    }

    render() {
        if (!this.props.loaded && this.props.loading)
            return <h2 className="text-muted">Загрузка...</h2>

        if (!this.props.loaded)
            return <h2 className="text-danger">Ошибка загрузки</h2>

        return <EditOrNewTransporterForm
            title={<span>
                Редактирование перевозчика {
                    this.props.transporter.params.locked && 
                    <span className='text-muted' style={{fontSize: '70%'}}><span className='oi oi-lock-locked'/></span>
                }
            </span>}
            transporter={this.props.transporter}
            role={this.props.role}
            onCancel={() => this.props.onPushUrl(this.props.cancel_href)}
            onSave={transporter => this.props.onSaveTransporter(this.props.user_id, this.props.transporter.id, transporter, this.props.ok_href)}
            saving={this.props.saving_transporter}
            fail={this.props.saving_transporter_fail}
        />;
    }
}

export const TransporterEditPage = connect(
    (state, {user_id, transporter_id}) => {
        const by_user_state = state.transporters.by_user[user_id] || by_user_initial;
        return {
            user_id,
            role: state.login.role,
            loaded: by_user_state.loaded,
            loading: by_user_state.loading,
            transporter: by_user_state.transporters.filter(transporter => transporter.id == transporter_id)[0],
            saving_transporter: by_user_state.saving_transporter,
            saving_transporter_fail: by_user_state.saving_transporter_fail,
        }
    },

    {
        onLoadTransporters: load_transporters,
        onSaveTransporter: save_transporter,
        onPushUrl: push
    }
)(TransporterEditPageImpl)

export const MyTransporterEditPage = connect(
    (state) => ({
        user_id: state.login.user_id,
    })
)(TransporterEditPage);



class TransporterViewPageImpl extends React.Component {
    componentDidMount() {
        this.props.onLoadTransporters(this.props.user_id);
    }

    buttons() {
        return <Link to={this.props.cancel_href} className='btn btn-outline-secondary'>Назад</Link>;
    }
    
    render() {
        if (!this.props.loaded && this.props.loading)
            return <h2 className="text-muted">Загрузка...</h2>

        if (!this.props.loaded)
            return <h2 className="text-danger">Ошибка загрузки</h2>

        return <Page>
            <Row className='align-items-center'>
                <Col sm={8}><h1>Просмотр перевозчика</h1></Col>
                <Col sm={4} className="text-sm-right">{this.buttons()}</Col>
            </Row>
            <Calculator params={this.props.transporter.params} readOnly/>
            <Row>
                <Col className="text-sm-right">{this.buttons()}</Col>
            </Row>
        </Page>;
    }
}

export const TransporterViewPage = connect(
    (state, {user_id, transporter_id}) => {
        const by_user_state = state.transporters.by_user[user_id] || by_user_initial;
        return {
            user_id,
            loaded: by_user_state.loaded,
            loading: by_user_state.loading,
            transporter: by_user_state.transporters.filter(transporter => transporter.id == transporter_id)[0],
        }
    },

    (dispatch) => ({
        onLoadTransporters: (user_id) => dispatch(load_transporters(user_id)),
    })
)(TransporterViewPageImpl);


export const MyTransporterViewPage = connect(
    (state) => ({
        user_id: state.login.user_id,
    })
)(TransporterViewPage);



const NewTransporterPageImpl = (props) => <EditOrNewTransporterForm
    title='Новый перевозчик'
    transporter={undefined}
    role={props.role}
    onCancel={() => props.onPushUrl(props.cancel_href)}
    onSave={transporter => props.onSaveNewTransporter(props.user_id, transporter, props.ok_href)}
    saving={props.adding_transporter}
    fail={props.adding_transporter_fail}
/>;

export const NewTransporterPage = connect(
    (state, {user_id}) => {
        const by_user_state = state.transporters.by_user[user_id] || by_user_initial;
        return {
            role: state.login.role,
            adding_transporter: by_user_state.adding_transporter,
            adding_transporter_fail: by_user_state.adding_transporter_fail,
        }
    },

    {
        onSaveNewTransporter: save_new_transporter,
        onPushUrl: push
    }
)(NewTransporterPageImpl);

export const MyNewTransporterPage = connect(
    (state) => ({
        user_id: state.login.user_id,
    })
)(NewTransporterPage);
import React from 'react';
import {connect} from 'react-redux';
import {Row, Col, Table} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {replace} from 'react-router-redux';

import {get} from './Api';
import {BaseField} from './form-controls';
import {date_from_string, format_price, iso_date_string, group_by, rus_date_time_string, month_names, month_names_short} from './utils';
import {calc_found, calc_base_rate, deal_debt, DONE_FILTER,
    filter_by_done, is_expected_to_be_paid, deal_result_in_period,
    week_day_1_to_7, add_days, DEBT_FILTER, calc_payments_effects, deal_result_if_paid_today,
    calc_prepay_effect} from './deals-math';
import {Page} from './Page';
import {USER_STATUS, USER_ROLE} from './Users';
import {get_user_month_plan} from './user-utils';

import {rankTable, star} from './rank-table.css';
import { DealsList } from './Deals';


const LOADING_TABLE = 'LOADING_TABLE';
const LOAD_TABLE__OK = 'LOAD_TABLE__OK';
const LOAD_TABLE__FAIL = 'LOAD_TABLE__FAIL';


const load_table = (min_date, max_date, done_filter, clear_while_loading) => async (dispatch, getState) => {
    dispatch({type: LOADING_TABLE, clear_while_loading});
    try {
        const is_undone = [DONE_FILTER.NOT_PAID, DONE_FILTER.NOT_SHIPPED].indexOf(done_filter) != -1;

        const response = await get('ranktable2', {
            done_filter,
            min_date: is_undone ? null : min_date.getTime()/1e3,
            max_date: is_undone ? null : max_date.getTime()/1e3
        });
        const {deals, users, workdays} = response;
        dispatch({type: LOAD_TABLE__OK, min_date, max_date, deals, users, workdays});
    }
    catch (failure) {
        dispatch({type: LOAD_TABLE__FAIL, failure});
    }
};


const initial = {
    loaded: false,
    loading: false,
    fail: false,
    min_date: null,
    max_date: null,
    loaded_at: null,

    users: [],
    deals: [],
    workdays: []
};

export const reducer = (state = initial, action) => {
    switch (action.type) {
        case LOADING_TABLE:
            if (action.clear_while_loading)
                return {...state, loaded: false, loading: true, deals: [], users: [], loaded_at: null};
            else
                return {...state, loading: true};
        case LOAD_TABLE__OK: return {
            ...state,
            loaded: true,
            loading: false,
            deals: action.deals,
            users: action.users,
            workdays: action.workdays,
            min_date: action.min_date,
            max_date: action.max_date,
            loaded_at: new Date()
        };
        case LOAD_TABLE__FAIL:
            return {...state, loaded: true, loading: false, fail: true, deals: [], users: [], loaded_at: new Date()};
        default: return state;
    }
};


const user_deals = (deals, user) => deals.filter(deal => deal.user_id == user.id);

const filter_deals_by_account_date = (deals, start, end) => deals.filter(deal => {
    return deal_result_in_period(deal.params, start, end, {payments_not_after: new Date()}) != 0;
});

const filter_deals_by_selling_date = (deals, start, end) => deals.filter(deal => {
    const date = date_from_string(deal.params.date);
    if (!date) return false;
    return start <= date && date < end;
});

const calc_negative_payment_effects = deal =>
    calc_payments_effects(deal, new Date()).filter(eff => eff > 0).reduce((acc, eff) => acc + eff, 0);

const calc_positive_payments_and_prepay_effects = deal =>
    calc_prepay_effect(deal) - calc_payments_effects(deal, new Date()).filter(eff => eff < 0).reduce((acc, eff) => acc + eff, 0);

const sum_deal_func = (deals, func) => deals.reduce((acc, deal) => acc + (func(deal.params) || 0), 0);


const RelativePercentCell = ({value, base_value, element='td'}) => {
    let percent = NaN;
    if (value != null && base_value != null && !isNaN(value) && !isNaN(base_value))
        percent = Math.round((value / base_value - 1) * 100)
    return React.createElement(
        element,
        {className: classnames(
            'text-right',
            {
                'text-success': percent >= 0,
                'text-danger': percent < 0
            }
        )},
        !isNaN(percent) && <React.Fragment>
            {percent >= 0 ? '+' : '–'}
            {Math.abs(percent)}%
        </React.Fragment>
    );
};

const AbsolutePercentCell = ({value, base_value, element='td'}) => {
    let percent = NaN;
    if (value != null && base_value != null && !isNaN(value) && !isNaN(base_value))
        percent = Math.round((value / base_value) * 100)

    return React.createElement(
        element,
        {className: classnames(
            'text-right',
            {
                'text-success': percent >= 100,
                'text-danger': percent < 100
            }
        )},
        !isNaN(percent) && <React.Fragment>
            {percent}%
        </React.Fragment>
    );
};



// Calculates fraction of working days past of total working days number in specified period
// workdays is array of working days of form [[y, m, d], [y, m, d], ...]
const calc_past_workdays_fraction = (workdays, start, end_p1, now) => {
    let in_period = 0;
    let past = 0;
    for (const [year, month, day] of workdays) {
        const date = new Date(year, month-1, day, 0, 0, 0, 0);
        if (date < start) continue;
        if (date >= end_p1) continue;

        in_period++;
        if (date < now)
            past++;
    }
    return past / in_period;
};


const format_base_found_percent = (total_base_rate, total_found) => {
    if (total_found != 0)
        return (parseInt(total_base_rate / total_found * 1000)/10).toFixed(1) + '%';
    else
        return '';
}


const sum = collection => collection.reduce((acc, item) => acc + item, 0);


const calc_table_rows = (deals, date_start, date_next, users, primary_result_func) => {
    const date_end = add_days(date_next, -1);

    const user_results = users.map(user => {
        const u_deals = user_deals(deals, user);
        // const cur_deals = filter_deals_by_account_date(u_deals, date_start, date_next);

        const now = new Date();

        return {
            id: user.id,
            username: user.username,
            user_active: user.status == USER_STATUS.active,
            month_plan: get_user_month_plan(user, date_start).base,
            deal_count: u_deals.length,

            cur_result: sum_deal_func(u_deals, deal => deal_result_in_period(deal, date_start, date_end, {payments_not_after: now})),
            pending_undone_result: sum_deal_func(u_deals, deal_result_if_paid_today),

            total_found: sum_deal_func(u_deals, calc_found),
            total_base_rate: sum_deal_func(u_deals, calc_base_rate),

            debt: sum_deal_func(u_deals, deal => deal_debt(deal, now)),
            expired_debt: sum_deal_func(u_deals.filter(is_expected_to_be_paid), deal => deal_debt(deal, now)),

            bonuses: sum_deal_func(u_deals, calc_positive_payments_and_prepay_effects),
            fines: sum_deal_func(u_deals, calc_negative_payment_effects),
        };
    });

    const sorted = user_results.slice().sort((a, b) => primary_result_func(b) - primary_result_func(a));

    const rows = sorted.map(user => ({
        user_id: user.id,
        user_active: user.user_active,
        // best_index: best_index(user),
        // is_looser: is_looser(user),
        username: user.username,
        deal_count: user.deal_count,
        base_rate: user.total_base_rate,
        found: user.total_found,
        cur_result: user.cur_result,
        pending_undone_result: user.pending_undone_result,
        debt: user.debt,
        expired_debt: user.expired_debt,
        month_plan: user.month_plan,
        bonuses: user.bonuses,
        fines: user.fines
    })).filter(row => row.user_active || primary_result_func(row) != 0);

    // const totals = {
    //     deal_count: sum(rows.map(row => row.deal_count)),
    //     cur_result: sum(rows.map(row => row.cur_result)),
    //     pending_undone_result: sum(rows.map(row => row.pending_undone_result)),
    //     debt: sum(rows.map(row => row.debt)),
    //     expired_debt: sum(rows.map(row => row.expired_debt)),
    //     month_plan: sum(rows.map(row => row.month_plan)),
    //     base_rate: sum(rows.map(row => row.base_rate)),
    //     found: sum(rows.map(row => row.found)),
    //     bonuses: sum(rows.map(row => row.bonuses)),
    //     fines: sum(rows.map(row => row.fines)),
    // };

    return rows;
};


const calc_medals = (sorted_rows, primary_result_func) => {
    const best_results = Array.from(new Set(sorted_rows.map(primary_result_func))).sort((a, b) => b - a).slice(0, 3);
    const best_index = user_r => best_results.indexOf(primary_result_func(user_r));
    const is_looser = user_r => sorted_rows.length > 0 && primary_result_func(user_r) == primary_result_func(sorted_rows[sorted_rows.length-1]);

    const user_id_to_medals = {};
    sorted_rows.forEach(row => user_id_to_medals[row.user_id] = {
        best_index: best_index(row),
        is_looser: is_looser(row)
    });

    return user_id_to_medals;
};


const calc_totals = rows => ({
    deal_count: sum(rows.map(row => row.deal_count)),
    cur_result: sum(rows.map(row => row.cur_result)),
    pending_undone_result: sum(rows.map(row => row.pending_undone_result)),
    debt: sum(rows.map(row => row.debt)),
    expired_debt: sum(rows.map(row => row.expired_debt)),
    month_plan: sum(rows.map(row => row.month_plan)),
    base_rate: sum(rows.map(row => row.base_rate)),
    found: sum(rows.map(row => row.found)),
    bonuses: sum(rows.map(row => row.bonuses)),
    fines: sum(rows.map(row => row.fines)),
});


const medals = ['🥇', '🥈', '🥉'];

// const PrevPeriodTable = ({title, calcs}) =>
//     <Table hover className={rankTable} responsive>
//         <thead>
//             <tr>
//                 <th colSpan='2'>Менеджер</th>
//                 <th>Сделок</th>
//                 <th className='text-right'>Средняя наценка</th>
//                 <th>Результат за {title}</th>
//             </tr>
//             <tr>
//                 <th colSpan='2'>ИТОГО</th>
//                 <th className='text-right'>
//                     {calcs.totals.deal_count}
//                 </th>
//                 <th className='text-right'>{format_base_found_percent(calcs.totals.base_rate, calcs.totals.found)}</th>
//                 <th className='text-right'>{format_price(calcs.totals.cur_result) || 0}</th>
//             </tr>
//         </thead>
//         <tbody>
//             {calcs.rows.map(row =>
//                 <tr key={row.user_id} className={classnames({
//                     'table-success': row.best_index != -1,
//                     'table-danger': row.is_looser
//                 })}>
//                     <th className={star}>{medals[row.best_index]}&nbsp;</th>
//                     <th style={{paddingLeft: 0}}>{row.username}</th>
//                     <td className='text-right'>{row.deal_count}</td>
//                     <td className='text-right'>{format_base_found_percent(row.base_rate, row.found)}</td>
//                     <td className='text-right'>{format_price(row.cur_result) || 0}</td>
//                 </tr>
//             )}
//         </tbody>
//     </Table>;


const RankTable = ({my_role, my_user_id, period_descriptor, cur_date, users, deals, workdays, done_filter}) => {
    const {title} = period_descriptor;
    const now = cur_date;
    const prev_period_start = period_descriptor.prev_period_start(now);
    const cur_period_start = period_descriptor.cur_period_start(now);
    // const prev_period_end_date = add_days(cur_period_start, -1);
    const next_period_start = period_descriptor.next_period_start(now);
    const cur_period_end_date = add_days(next_period_start, -1);
    // const cur_period_length = (next_period_start.getTime() - cur_period_start.getTime()) / 24/60/60e3;

    const is_undone_selected = [DONE_FILTER.NOT_PAID, DONE_FILTER.NOT_SHIPPED].indexOf(done_filter) != -1;

    const period_includes_today = cur_period_start <= new Date() && new Date() < next_period_start;
    const show_prognosis = period_includes_today && !is_undone_selected;
    const show_plan = period_descriptor.show_plan && !is_undone_selected;

    let cur_period_fraction;
    if (show_prognosis)
        cur_period_fraction = calc_past_workdays_fraction(workdays, cur_period_start, next_period_start, new Date());
    else
        cur_period_fraction = 1;

    const show_expired_debt = is_undone_selected;

    deals = deals.filter(deal => !deal.params.exclude_from_ranktable);
    deals = filter_by_done(deals, done_filter, new Date());

    const user_id_2_role = {};
    users.forEach(user => user_id_2_role[user.id] = user.role);


    const show_link_to_deals = user_id => {
        switch (my_role) {
            case USER_ROLE.admin: return true;
            case USER_ROLE.accountant: return true;
            default: return user_id == my_user_id;
        }
    };
        
    const deals_url_prefix = user_id =>
        user_id == my_user_id ? '/deals/' : `/users/${user_id}/deals/`;

    const all_user_deals_url = (user_id) => 
        `${deals_url_prefix(user_id)}null--null--${done_filter}--all--all`;

    const user_deals_url = (user_id) => 
        `${deals_url_prefix(user_id)}${iso_date_string(cur_period_start)}--${iso_date_string(cur_period_end_date)}--${done_filter}--all--all`;

    const user_debt_url = (user_id) => 
        `${deals_url_prefix(user_id)}${iso_date_string(cur_period_start)}--${iso_date_string(cur_period_end_date)}--${done_filter}--all--all`;

    const user_expired_debt_url = (user_id) => 
        `${deals_url_prefix(user_id)}${iso_date_string(cur_period_start)}--${iso_date_string(cur_period_end_date)}--${DONE_FILTER.EXPIRED_DEBT}--all--all`;

    const all_deals_url =
        `/all-deals/${iso_date_string(cur_period_start)}--${iso_date_string(cur_period_end_date)}--${done_filter}--all--all`;


    const plan_percent = row => {
        const plan_result = row.cur_result / row.month_plan;
        if (isNaN(plan_result))
            return 0;
        return plan_result;
    };


    const render_prev_totals = (caption, totals) =>
        <tr>
            <th colSpan='2'>{caption}</th>
            <th className='text-right'>
                {totals.deal_count}
            </th>
            <th className='text-right'>{format_base_found_percent(totals.base_rate, totals.found)}</th>
            <th className='text-right'>{format_price(totals.cur_result) || 0}</th>
        </tr>;

    const render_prev_row = (row, user_id_to_medals) =>
        <tr key={row.user_id} className={classnames({
            'table-success': user_id_to_medals[row.user_id].best_index != -1,
            'table-danger': user_id_to_medals[row.user_id].is_looser
        })}>
            <th className={star}>{medals[user_id_to_medals[row.user_id].best_index]}&nbsp;</th>
            <th style={{paddingLeft: 0}}>{row.username}</th>
            <td className='text-right'>{row.deal_count}</td>
            <td className='text-right'>{format_base_found_percent(row.base_rate, row.found)}</td>
            <td className='text-right'>{format_price(row.cur_result) || 0}</td>
        </tr>;


    const render_cur_totals = (caption, totals, show_links) =>
        <tr>
            <th colSpan='2'>{caption}</th>
            <th className='text-right'>
            {show_links && show_link_to_deals(-1) ?  // show_link_to_deals(-1) means "Current user can see deals from any other user" (i.e. admin or accountant)
                <Link to={all_deals_url}>{totals.deal_count}</Link>
                :
                totals.deal_count
            }
            </th>
            <th className='text-right'>{format_base_found_percent(totals.base_rate, totals.found)}</th>
            <th className='text-right' style={{whiteSpace: 'nowrap'}}>{format_price(totals.bonuses)}</th>
            <th className='text-right' style={{whiteSpace: 'nowrap'}}>{format_price(totals.fines)}</th>
            <th className='text-right' style={{whiteSpace: 'nowrap'}}>{format_price(totals.debt) || 0}</th>
            {show_expired_debt && <th className='text-right' style={{whiteSpace: 'nowrap'}}>{format_price(totals.expired_debt) || 0}</th>}
            <th className='text-right'>{format_price(result_func(totals)) || 0}</th>
            {show_prognosis && <th className='text-right'>{format_price(Math.max(0, totals.cur_result / cur_period_fraction)) || 0}</th>}
            {show_plan && <th>{format_price(totals.month_plan)}</th>}
            {show_plan && <AbsolutePercentCell element='th'
                value={totals.cur_result}
                base_value={totals.month_plan}/>}
        </tr>;

    const render_cur_row = (row, user_id_to_medals) =>
        <tr key={row.user_id} className={classnames({
            'table-success': user_id_to_medals[row.user_id].best_index != -1,
            'table-danger': user_id_to_medals[row.user_id].is_looser
        })}>
            <th className={star}>{medals[user_id_to_medals[row.user_id].best_index]}&nbsp;</th>
            <th style={{paddingLeft: 0}}>{
                show_link_to_deals(row.user_id) ?
                    <Link to={all_user_deals_url(row.user_id)}>{row.username}</Link>
                    :
                    row.username
            }</th>
            <td className='text-right'>{
                show_link_to_deals(row.user_id) ?
                    <Link to={user_deals_url(row.user_id)}>
                        {row.deal_count}
                    </Link>
                    :
                    row.deal_count
            }</td>
            <td className='text-right'>{format_base_found_percent(row.base_rate, row.found)}</td>
            <td className='text-right'>{format_price(row.bonuses)}</td>
            <td className='text-right'>{format_price(row.fines)}</td>
            <td className='text-right' style={{whiteSpace: 'nowrap'}}>
                {show_link_to_deals(row.user_id) ?
                    <Link to={user_debt_url(row.user_id)}>{format_price(row.debt) || 0}</Link>
                    :
                    (format_price(row.debt) || 0)
                }
            </td>
            {show_expired_debt && <td className='text-right' style={{whiteSpace: 'nowrap'}}>
                {show_link_to_deals(row.user_id) ?
                    <Link to={user_expired_debt_url(row.user_id)}>{format_price(row.expired_debt) || 0}</Link>
                    :
                    (format_price(row.expired_debt) || 0)
                }
            </td>}
            <td className='text-right'>{format_price(result_func(row)) || 0}</td>
            {show_prognosis &&
                <td className='text-right'>{format_price(row.cur_result / cur_period_fraction) || 0}</td>
            }
            {show_plan && <td>{row.month_plan && format_price(row.month_plan)}</td>}
            {show_plan && <AbsolutePercentCell
                value={row.cur_result}
                base_value={row.month_plan}/>}
        </tr>;


    
    const render_group = (caption, rows, render_totals, render_row) => {
        const medals = calc_medals(rows, plan_percent);

        return <React.Fragment>
            {render_totals(caption, calc_totals(rows), true)}
            {rows.map(row => render_row(row, medals))}
        </React.Fragment>;
    };



    const PREV = calc_table_rows(filter_deals_by_account_date(deals, prev_period_start, cur_period_start), prev_period_start, cur_period_start, users, plan_percent);

    const result_func = is_undone_selected
        ? (row => row.pending_undone_result)
        : (row => row.cur_result)
    ;
    const filtered_by_date = is_undone_selected
        ? deals
        : filter_deals_by_account_date(deals, cur_period_start, next_period_start);
    const CUR = calc_table_rows(filtered_by_date, cur_period_start, next_period_start, users, plan_percent);

    const is_hunter = row => user_id_2_role[row.user_id] == USER_ROLE.hunter;
    const is_farmer = row => user_id_2_role[row.user_id] == USER_ROLE.farmer;

    return <Row className='mt-4' style={{fontSize: '90%'}}>
        {!is_undone_selected &&
            <Col sm={4} style={{borderRight: '2px solid #ddd'}}>
                <h4>Предыдущий {title}</h4>
                <Table hover className={rankTable} responsive>
                    <thead>
                        <tr>
                            <th colSpan='2'>Менеджер</th>
                            <th>Сделок</th>
                            <th className='text-right'>Средняя наценка</th>
                            <th>Результат за {title}</th>
                        </tr>
                        {render_prev_totals('ИТОГО', calc_totals(PREV))}
                    </thead>
                    <tbody>
                        {render_group('ХАНТЕРЫ', PREV.filter(is_hunter), render_prev_totals, render_prev_row)}
                        {render_group('ФЕРМЕРЫ', PREV.filter(is_farmer), render_prev_totals, render_prev_row)}
                    </tbody>
                </Table>
            </Col>
        }
        <Col sm={is_undone_selected ? 12 : 8}>
            <h4>Текущий {title}</h4>
            <Table hover className={rankTable} responsive>
                <thead>
                    <tr>
                        <th colSpan='2'>Менеджер</th>
                        <th>Сделок</th>
                        <th className='text-right'>Средняя наценка</th>
                        <th className='text-right'>Бонус за предоплату</th>
                        <th className='text-right'>Затраты&nbsp;на рассрочку</th>
                        <th className='text-right'>Долг</th>
                        {show_expired_debt && <th className='text-right'>Просроченный долг</th>}
                        <th className='text-right'>{(function () {
                            if (is_undone_selected)
                                return 'Расчётная база';
                            if (period_includes_today)
                                return 'На сегодня';
                            return 'Результат';
                        })()}</th>
                        {show_prognosis && <th>Прогноз</th>}
                        {show_plan && <th>План</th>}
                        {show_plan && <th>%&nbsp;выполнения на сегодня</th>}
                    </tr>
                    {render_cur_totals('ИТОГО', calc_totals(CUR), true)}
                </thead>
                <tbody>
                    {render_group('ХАНТЕРЫ', CUR.filter(is_hunter), render_cur_totals, render_cur_row)}
                    {render_group('ФЕРМЕРЫ', CUR.filter(is_farmer), render_cur_totals, render_cur_row)}
                </tbody>
            </Table>
        </Col>
    </Row>;
};


const RankTableContent = ({loaded, fail, my_role, my_user_id, users, deals, workdays, period_descriptor,
                           cur_date, done_filter}) => {
    if (!loaded)
        return <h4 className="text-muted">Загрузка...</h4>;
        
    if (fail)
        return <h4 className="text-danger">Ошибка загрузки</h4>;

    const cur_period_start = period_descriptor.cur_period_start(cur_date);
    const next_period_start = period_descriptor.next_period_start(cur_date);
    const cur_period_end_date = add_days(next_period_start, -1);

    const user_by_id = {};
    users.forEach(user => user_by_id[user.id] = user);

    return <React.Fragment>
        <RankTable
            my_role={my_role}
            my_user_id={my_user_id}
            loaded={loaded}
            fail={fail}
            users={users}
            deals={deals}
            workdays={workdays}
            period_descriptor={period_descriptor}
            cur_date={cur_date}
            done_filter={done_filter}
            />
        {loaded && my_role == USER_ROLE.admin &&
            <React.Fragment>
                <h1 className='mt-5'>Неучтённые сделки</h1>
                <DealsList
                    deals={filter_by_done(deals.filter(deal => deal.params.exclude_from_ranktable), done_filter, new Date())}
                    period_start={cur_period_start}
                    period_end={cur_period_end_date}
                    edit_href={(user_id, deal_id) => `/users/${user_id}/deal/${deal_id}/edit`}
                    view_href={(user_id, deal_id) => `/users/${user_id}/deal/${deal_id}/view`}
                    firstHeader={() => <th>Менеджер</th>}
                    firstCell={deal => {
                        const user = user_by_id[deal.user_id];
                        return <td>{user && user.username}</td>;
                    }}
                />
            </React.Fragment>
        }
    </React.Fragment>;
};


const min_date = new Date(2018, 0, 1);

const period_descriptors = {
    week: {
        title: 'неделя',
        show_plan: false,
        period_title: (dt) => {
            const a = period_descriptors.week.cur_period_start(dt);
            const b = add_days(a, 6);
            return `${a.getDate()} ${month_names_short[a.getMonth()]} — ${b.getDate()} ${month_names_short[b.getMonth()]}`;
        },
        cur_period_start: (dt) => add_days(dt, - week_day_1_to_7(dt) + 1),
        prev_period_start: (dt) => add_days(dt, - week_day_1_to_7(dt) + 1 - 7),
        next_period_start: (dt) => add_days(dt, - week_day_1_to_7(dt) + 1 + 7),
    },
    month: {
        title: 'месяц',
        show_plan: true,
        period_title: (dt) => `${month_names[dt.getMonth()]} ${dt.getFullYear()}`,
        cur_period_start: (dt) => new Date(dt.getFullYear(), dt.getMonth(), 1),
        prev_period_start: (dt) => new Date(dt.getFullYear(), dt.getMonth()-1, 1),
        next_period_start: (dt) => new Date(dt.getFullYear(), dt.getMonth()+1, 1),
    },
    year: {
        title: 'год',
        show_plan: false,
        period_title: (dt) => dt.getFullYear().toString(),
        cur_period_start: (dt) => new Date(dt.getFullYear(), 0, 1),
        prev_period_start: (dt) => new Date(dt.getFullYear()-1, 0, 1),
        next_period_start: (dt) => new Date(dt.getFullYear()+1, 0, 1),
    }
};


const ranktable_url = (period_type, date, done_filter) =>
    `/table/${period_type}/${iso_date_string(date)}/${done_filter}`;

class RankTablePageImpl extends React.Component {
    constructor() {
        super(...arguments);

        this.reload_timer = null;
    }

    componentDidMount() {
        this.load(this.props.period_type, this.props.date, this.props.done_filter);

        if (this.reload_timer)
            clearInterval(this.reload_timer);
        this.reload_timer = setInterval(() => {
            console.log('RELOAD');
            this.load(this.props.period_type, this.props.date, this.props.done_filter, true, false);
        }, 60*1000);
    }

    componentWillUnmount() {
        if (this.reload_timer)
            clearInterval(this.reload_timer);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.period_type != nextProps.period_type ||
            this.props.date != nextProps.date ||
            this.props.done_filter != nextProps.done_filter)
            this.load(nextProps.period_type, nextProps.date, nextProps.done_filter);
    }

    redirect({period_type, date, done_filter}) {
        this.props.onReplaceUrl(ranktable_url(
            period_type || this.props.period_type,
            date || this.props.date,
            done_filter || this.props.done_filter
        ));
    }

    load(period_type, cur_date, done_filter, force_update = false, clear_while_loading = true) {
        const period_descriptor = period_descriptors[period_type];
        const new_start = period_descriptor.prev_period_start(cur_date);
        const new_end = period_descriptor.next_period_start(cur_date);

        // if (!force_update && this.props.loaded
        //         && this.props.min_date.getTime() == new_start.getTime()
        //         && this.props.max_date.getTime() == new_end.getTime()
        //         && this.props.done_filter == done_filter)
        //     return;

        this.props.onLoad(new_start, new_end, done_filter, clear_while_loading);
    }

    render() {
        const period_descriptor = period_descriptors[this.props.period_type];
        const possible_periods = [];
        let cur = period_descriptor.cur_period_start(min_date);
        const now = new Date();
        let selected_date = cur;
        while (cur < now) {
            possible_periods.push({start: cur, title: period_descriptor.period_title(cur)});
            cur = period_descriptor.next_period_start(cur);
            if (cur <= this.props.date)
                selected_date = cur;
        }
        possible_periods.push({start: cur, title: period_descriptor.period_title(cur)});

        return <Page wide={true}>
            <h1>Таблица результатов</h1>
            <Row>
                <Col sm={3}>
                    <BaseField label='Учитывать: ' type='select' value={this.props.done_filter} onChange={value => this.redirect({done_filter: value})}>
                        <option value={DONE_FILTER.DONE}>Завершённые</option>
                        <option value={DONE_FILTER.NOT_PAID}>Неоплаченные</option>
                        <option value={DONE_FILTER.NOT_SHIPPED}>Неотгруженные</option>
                        <option value={DONE_FILTER.ALL}>Все</option>
                    </BaseField>
                </Col>
                {[DONE_FILTER.ALL, DONE_FILTER.DONE].indexOf(this.props.done_filter) != -1 && <React.Fragment>
                    <Col sm={3}>
                        <BaseField label='Интервал' type='select' value={this.props.period_type}
                                onChange={value => this.redirect({period_type: value})}>
                            <option value='week'>неделя</option>
                            <option value='month'>месяц</option>
                            <option value='year'>год</option>
                        </BaseField>
                    </Col>
                    <Col sm={3}>
                        <BaseField label='Период' type='select' value={selected_date.getTime().toString()}
                                onChange={value => {
                                    const new_cur_date = new Date(parseInt(value));
                                    this.redirect({date: new_cur_date});
                                }}>{
                            Array.from(group_by(possible_periods.reverse(), period => period.start.getFullYear())).map(({key, items}) =>
                                <optgroup key={key} label={key.toString()}>{
                                    items.map(period =>
                                        <option key={period.start.getTime()} value={period.start.getTime()}>{period.title}</option>
                                    )
                                }</optgroup>
                            )
                        }</BaseField>
                    </Col>
                </React.Fragment>}
            </Row>
            <RankTableContent
                my_role={this.props.my_role}
                my_user_id={this.props.my_user_id}
                loaded={this.props.loaded}
                fail={this.props.fail}
                users={this.props.users}
                deals={this.props.deals}
                workdays={this.props.workdays}
                period_descriptor={period_descriptor}
                cur_date={this.props.date}
                done_filter={this.props.done_filter}
            />
            {this.props.loaded_at && <Row>
                <Col>
                    <p style={{marginTop: '2em', textAlign: 'center', fontStyle: 'italic', color: '#aaa'}}>
                        загружено {rus_date_time_string(this.props.loaded_at)}
                    </p>
                </Col>
            </Row>}
        </Page>
    }
}

export const RankTablePage = connect(
    (state) => ({
        ...state.ranktable,
        my_role: state.login.role,
        my_user_id: state.login.user_id,
    }),
    {
        onLoad: load_table,
        onReplaceUrl: replace
    }
)(RankTablePageImpl);

import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector, createSelector} from 'reselect';
import {Row, Col, Form, Table, FormGroup, Label, Input, Button, Alert, FormText} from 'reactstrap';
import UAParser from 'ua-parser-js';

import {Page} from './Page';
import { BaseField } from './form-controls';
import { cmp_by_username, load_users } from './Users';
import { post } from './Api';



const SESSIONS__LOAD__REQUEST = 'SESSIONS__LOAD__REQUEST';
const SESSIONS__LOAD__OK = 'SESSIONS__LOAD__OK';
const SESSIONS__LOAD__FAIL = 'SESSIONS__LOAD__FAIL';


const load_sessions = () => async dispatch => {
    dispatch({type: SESSIONS__LOAD__REQUEST});
    try {
        const {sessions} = await post('sessions');
        dispatch({type: SESSIONS__LOAD__OK, sessions});
    }
    catch (failure) {
        dispatch({type: SESSIONS__LOAD__FAIL, failure});
    }
};


const initial = {
    loading: false,
    loaded: false,
    error: null,
    sessions: null,
};


export const reducer = (state = initial, action) => {
    switch (action.type) {
        case SESSIONS__LOAD__REQUEST:
            return {...state, loading: true, loaded: false, error: null, sessions: null};
        case SESSIONS__LOAD__OK:
            return {...state, loading: false, loaded: true, error: null, sessions: action.sessions};
        case SESSIONS__LOAD__FAIL:
            return {...state, loading: false, loaded: true, error: action.failure, sessions: null};
        default: return state;
    }
}



// class UserSelectorImpl extends React.PureComponent {
//     componentDidMount() {
//         if (!this.props.users.loading && !this.props.users.loaded)
//             this.props.onLoadUsers();
//     }

//     render() {
//         if (!this.props.users.loaded && this.props.users.loading)
//             return <h2 className='text-muted'>Загрузка...</h2>

//         if (!this.props.users.loaded)
//             return <h2 className='text-danger'>Ошибка загрузки</h2>

//         return <React.Fragment>
//             <Col sm={4}>
//                 <BaseField label='Пользователь' type='select' value={this.props.user_id == null ? '' : this.props.user_id}
//                            onChange={value => this.props.onChangeUserId(value == '' ? null : +value)}>
//                     <option value=''>Выберите пользователя</option>
//                     <option value=''>———————————</option>
//                     {this.props.users.list.slice().sort(cmp_by_username).map(
//                         user => <option key={user.id} value={user.id}>{user.username}</option>
//                     )}
//                 </BaseField>
//             </Col>
//         </React.Fragment>
//     }
// };

// const UserSelector = connect(
// )(UserSelectorImpl);


class SessionsPageImpl extends React.PureComponent {
    constructor() {
        super(...arguments);

        this.state = {
            user_id: null,
        };
    }

    componentDidMount() {
        console.log(this.props);
        if (!this.props.sessions.loading && !this.props.sessions.loaded)
            this.props.onLoadSessions();

        if (!this.props.users.loading && !this.props.users.loaded)
            this.props.onLoadUsers();
    }

    render() {
        if (!this.props.sessions.loaded && this.props.sessions.loading 
            || !this.props.users.loaded && this.props.users.loading)
            return <h2 className='text-muted'>Загрузка...</h2>;

        if (!this.props.sessions.loaded || !this.props.users.loaded)
            return <h2 className='text-danger'>Ошибка загрузки</h2>;


        let sessions = this.props.sessions.sessions;
        if (this.state.user_id != null)
            sessions = sessions.filter(sess => sess.user_id == this.state.user_id);

        return <Page>
            <h1>Сессии пользователей</h1>

            <Row>
                <Col sm={4}>
                    <BaseField type='select' value={this.state.user_id != null ? this.state.user_id : ''}
                               onChange={value => this.setState({user_id: value === '' ? null : +value})}>
                        <option value=''>Все пользователи</option> 
                        <option value=''>———————————</option>
                        {this.props.users.users.slice().sort(cmp_by_username).map(user =>
                            <option key={user.id} value={user.id}>{user.username}</option>
                        )}
                    </BaseField>
                </Col>
            </Row>

            <Table hover responsive>
                <thead>
                    <tr>
                        <th>Пользователь</th>
                        <th>ID сессии</th>
                        <th>Время последнего обращения</th>
                        <th>Последний IP-адрес</th>
                        <th>Браузер</th>
                    </tr>
                </thead>
                <tbody>
                    {sessions.map(session => {
                        let ua_str = null;
                        if (session.last_user_agent) {
                            const ua = UAParser(session.last_user_agent);
                            ua_str = `${ua.browser.name} ${ua.browser.major} :: ${ua.os.name} ${ua.os.version} :: ${ua.device.vendor || ''} ${ua.device.model || ''}`;
                        }

                        return <tr key={`${session.session_id}-${session.user_id}-${session.ip}`}>
                            <td>{this.props.usernames[session.user_id]}</td>
                            <td>{session.session_id.substr(0, 6)}</td>
                            <td>{session.last_access}</td>
                            <td>
                                <a href={`https://whatismyipaddress.com/ip/${session.ip}`} target='_blank'>
                                    {session.ip}
                                </a>
                            </td>
                            <td>{ua_str}</td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </Page>;
    }
}

export const SessionsPage = connect(
    createStructuredSelector({
        sessions: state => state.sessions,
        users: state => state.users,
        usernames: createSelector(
            state => state.users.users,
            users => {
                if (users == null)
                    return null;
                return Object.fromEntries(users.map(({id, username}) => [id, username]));
            }
        )
    }),

    {
        onLoadUsers: load_users,
        onLoadSessions: load_sessions
    }
)(SessionsPageImpl);

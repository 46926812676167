import React from 'react';
import {combineReducers} from 'redux';
import {connect} from 'react-redux';
import {Row, Col, FormGroup, FormText, Label, Form, Input, Button, Table, Alert} from 'reactstrap';

import { Page } from "./Page";
import { BaseField, NumberInput, TextInput, UnlabelledNumberInput } from './form-controls';
import { post, get } from './Api';
import {cmp_by_username} from './Users';
import { pad0, group_by, format_price, month_names } from './utils';
import {salaryForm, withInput, narrowField, salaryCalcTable, centered} from './SalaryCalc.css';
import { filter_by_done, DONE_FILTER, deal_result_in_period, add_days, get_cash_tax } from './deals-math';
import { get_user_month_plan } from './user-utils';


const SALARY__LOAD_USERS = 'SALARY__LOAD_USERS';
const SALARY__LOAD_USERS__OK = 'SALARY__LOAD_USERS__OK';
const SALARY__LOAD_USERS__FAIL = 'SALARY__LOAD_USERS__FAIL';
const SALARY__LOAD_CALC = 'SALARY__LOAD_CALC';
const SALARY__LOAD_CALC__OK = 'SALARY__LOAD_CALC__OK';
const SALARY__LOAD_CALC__FAIL = 'SALARY__LOAD_CALC__FAIL';
const SALARY__SAVE_CALC = 'SALARY__SAVE_CALC';
const SALARY__SAVE_CALC__OK = 'SALARY__SAVE_CALC__OK';
const SALARY__SAVE_CALC__FAIL = 'SALARY__SAVE_CALC__FAIL';


const load_users = () => async dispatch => {
    dispatch({type: SALARY__LOAD_USERS});
    try {
        const users = await post('users');
        dispatch({type: SALARY__LOAD_USERS__OK, users});
    }
    catch (error) {
        dispatch({type: SALARY__LOAD_USERS__FAIL, error});
    }
};


const load_calc = (user_id, year, month) => async dispatch => {
    dispatch({type: SALARY__LOAD_CALC, user_id, year, month});
    try {
        const response = await get('salary-calc', {user_id, year, month});
        const done_deals = filter_by_done(response.deals, DONE_FILTER.DONE).filter(deal => !deal.params.exclude_from_ranktable);
        const period_start = new Date(year, month-1, 1);
        const period_end = add_days(new Date(year, month, 1), -1);
        const deal_result = done_deals.reduce((acc, deal) => acc + deal_result_in_period(deal.params, period_start, period_end), 0);
        const calc = {
            ...response.calc,
            deal_count: done_deals.length,
            deal_result,
            successful_calls_count: response.calls_count,
            call_duration: Math.floor(response.calls_duration/60),
            crm_days_without_expired_tasks: +response.crm_days_without_expired_tasks,
            crm_days_deals_without_tasks: +response.crm_days_deals_without_tasks,
            crm_days_companies_without_deals: +response.crm_days_companies_without_deals,
        };
        if (calc.workday_total == null)
            calc.workday_total = response.workdays_from_db;
        dispatch({type: SALARY__LOAD_CALC__OK, user_id, year, month, calc});
    }
    catch (error) {
        console.error(error);
        dispatch({type: SALARY__LOAD_CALC__FAIL, user_id, year, month, error});
    }
};


const save_calc = (user_id, year, month, calc) => async dispatch => {
    dispatch({type: SALARY__SAVE_CALC, user_id, year, month});
    try {
        await post('save-salary-calc', {user_id, year, month, calc});
        dispatch({type: SALARY__SAVE_CALC__OK, user_id, year, month});
    }
    catch (failure) {
        console.log(failure);
        dispatch({type: SALARY__SAVE_CALC__FAIL, user_id, year, month, failure});
    }
};


const users_initial = {
    loading: false,
    loaded: false,
    error: null,
    list: null,
    by_id: null
};

const users_list_to_map = list => {
    const by_id = {};
    for (let user of list)
        by_id[user.id] = user;
    return by_id;
};

const users_reducer = (state = users_initial, action) => {
    switch (action.type) {
        case SALARY__LOAD_USERS:
            return {...state, loading: true, error: null};
        case SALARY__LOAD_USERS__OK:
            return {...state, loading: false, loaded: true, error: null, list: action.users, by_id: users_list_to_map(action.users)};
        case SALARY__LOAD_USERS__FAIL:
            return {...state, loading: false, loaded: true, error: action.error, list: null, by_id: null};
        default: return state;
    }
};


const calcs_initial = {
    by_user_year_month: {}
};

const set_by_uid_year_month = (map, user_id, year, month, value) => {
    const by_year = map[user_id] || {};
    const by_month = by_year[year] || {};
    return {...map, [user_id]: {...by_year, [year]: {...by_month, [month]: value}}};
};

const update_by_uid_year_month = (map, user_id, year, month, setter) => {
    return set_by_uid_year_month(map, user_id, year, month, {
        ...get_by_uid_year_month(map, user_id, year, month),
        ...setter
    })
};

const get_by_uid_year_month = (map, user_id, year, month) => {
    const by_year = map[user_id] || {};
    const by_month = by_year[year] || {};
    return by_month[month];
};

const calcs_reducer = (state = calcs_initial, action) => {
    switch (action.type) {
        case SALARY__LOAD_CALC:
            return {...state, by_user_year_month: set_by_uid_year_month(state.by_user_year_month, action.user_id, action.year, action.month, {
                loading: true,
                loaded: false,
                error: null
            })};

        case SALARY__LOAD_CALC__OK:
            return {...state, by_user_year_month: set_by_uid_year_month(state.by_user_year_month, action.user_id, action.year, action.month, {
                loading: false,
                loaded: true,
                calc: action.calc,
                error: null
            })};

        case SALARY__LOAD_CALC__FAIL:
            return {...state, by_user_year_month: set_by_uid_year_month(state.by_user_year_month, action.user_id, action.year, action.month, {
                loading: false,
                loaded: true,
                calc: null,
                error: action.error
            })};

        case SALARY__SAVE_CALC:
            return {...state, by_user_year_month: update_by_uid_year_month(state.by_user_year_month, action.user_id, action.year, action.month, {
                saving: true,
                saving_error: null
            })};

        case SALARY__SAVE_CALC__OK:
            return {...state, by_user_year_month: update_by_uid_year_month(state.by_user_year_month, action.user_id, action.year, action.month, {
                saving: false,
                saving_error: null
            })};

        case SALARY__SAVE_CALC__FAIL:
            return {...state, by_user_year_month: update_by_uid_year_month(state.by_user_year_month, action.user_id, action.year, action.month, {
                saving: false,
                saving_error: action.failure
            })};

        default: return state;
    }
};


export const reducer = combineReducers({
    users: users_reducer,
    calcs: calcs_reducer
});



const workday_ratio = calc => {
    return Math.min(1, calc.workday_real / calc.workday_total);
};

const calc_workday_points = calc => {
    const percent = Math.min(1, calc.workday_real / calc.workday_total);
    const points = percent * WEIGHTS.WORKDAYS;
    return {percent, points};
};

const deal_result_points = (calc, plan) => {
    const percent = Math.min(1, calc.deal_result / plan.base);
    const points = percent * WEIGHTS.INCOME;
    return {percent, points};
};

const deal_count_points = (calc, plan) => {
    const percent = Math.min(1, calc.deal_count / plan.deal_count);
    const points = percent * WEIGHTS.DEAL_COUNT;
    return {percent, points};
};

const calc_crm_points = calc => {
    const percents = {
        companies_without_deals: Math.min(1, calc.crm_days_companies_without_deals / calc.workday_total),
        deals_without_tasks: Math.min(1, calc.crm_days_deals_without_tasks / calc.workday_total),
        without_expired_tasks: Math.min(1, calc.crm_days_without_expired_tasks / calc.workday_total),
    };
    const points = {
        companies_without_deals: percents.companies_without_deals * 0.25,
        deals_without_tasks: percents.deals_without_tasks * 0.25,
        without_expired_tasks: percents.without_expired_tasks * 0.25,
        match_zpcalc: Math.min(1, calc.crm_percent_match_zpcalc) * 0.25
    };
    const total_percent = (
        zin(points.companies_without_deals) +
        zin(points.deals_without_tasks) +
        zin(points.without_expired_tasks) +
        zin(points.match_zpcalc)
    );

    return {
        percents, points,
        total_percent,
        total_points: total_percent * WEIGHTS.CRM
    };
};


const calc_call_duration = (calc, plan) => {
    const month_plan = plan.call_duration_per_day * calc.workday_total;
    const percent = Math.min(1, calc.call_duration / month_plan);
    const points = percent * WEIGHTS.CALL_DURATION;
    return {plan: month_plan, percent, points};
};

const calc_successful_calls_count = (calc, plan) => {
    const month_plan = plan.successful_calls_count_per_day * calc.workday_total;
    const percent = Math.min(1, calc.successful_calls_count / month_plan);
    const points = percent * WEIGHTS.SUCCESSFUL_CALLS_COUNT;
    return {plan: month_plan, percent, points};
};


const calc_total_points = (calc, plan) => {
    const result_points = deal_result_points(calc, plan);
    const dc_points = deal_count_points(calc, plan);
    const workday_points = calc_workday_points(calc);
    const crm_points = calc_crm_points(calc);
    const call_duration = calc_call_duration(calc, plan);
    const successful_calls_count = calc_successful_calls_count(calc, plan);

    return (
        result_points.points + 
        dc_points.points + 
        crm_points.total_points + 
        workday_points.points + 
        call_duration.points + 
        successful_calls_count.points
    );
};


const calc_income_over_points = (calc, plan) => {
    const max_1_5 = Math.min(calc.deal_result / plan.income, 1.5);
    return max_1_5 * WEIGHTS.INCOME;
};


const calc_total_salary = (calc, plan, year, month) => {
    const result_points = deal_result_points(calc, plan);
    const total_points = calc_total_points(calc, plan);

    const income_over_max_1_5 = Math.min(calc.deal_result / plan.base, 1.5);
    const income_over_points = income_over_max_1_5 * WEIGHTS.INCOME;
    const income_over_amount = calc.deal_result - plan.base;

    const cash_tax = get_cash_tax(new Date(year, month - 1, 2));

    const income_over_total_points = total_points - result_points.points + income_over_points;
    const income_over_salary = income_over_amount * cash_tax * INCOME_BONUS * income_over_total_points;
    return plan.base * cash_tax * INCOME_BONUS * total_points + income_over_salary;
};



const format_fixed = (value, digits) => {
    if (value !== '' && !isNaN(value))
        return value.toFixed(digits);
    return null;
};

const format_percent = value => {
    if (value == '' || isNaN(value))
        return null;

    return Math.round(value * 100) + '%';
};



const WEIGHTS = {
    INCOME: 0.4,
    CRM: 0.2,
    DEAL_COUNT: 0.1,
    WORKDAYS: 0.1,
    CALL_DURATION: 0.1,
    SUCCESSFUL_CALLS_COUNT: 0.1
};

const INCOME_BONUS = 0.2;


// empty string if null
const esin = value => value == null || isNaN(value) ? '' : value;

// zero if null
const zin = value => value == null || isNaN(value) ? 0 : value;



const PlanBlock = ({plan}) => <React.Fragment>
    <h4>План</h4>

    <Table size='sm' style={{width: 'auto'}}>
        <tbody>
            <tr>
                <td>База</td>
                <td><b><big>{format_price(plan.base)}</big></b></td>
            </tr>
            <tr>
                <td>Количество сделок</td>
                <td><b><big>{plan.deal_count}</big></b></td>
            </tr>
            <tr>
                <td>Продолжительность разговоров</td>
                <td><b><big>{plan.call_duration_per_day}</big></b> мин/день</td>
            </tr>
            <tr>
                <td>Кол-во успешных звонков</td>
                <td><b><big>{plan.successful_calls_count_per_day}</big></b> в день</td>
            </tr>
        </tbody>
    </Table>
</React.Fragment>;


const WorkdaysBlock = ({calc, readOnly, onChange}) => <React.Fragment>
    <h4>Рабочее время</h4>
    <Row>
        <Col sm={6}>
            <NumberInput label='Рабочих дней' value={esin(calc.workday_total)}
                onChange={value => onChange({workday_total: value})}
                readOnly={readOnly}/>
        </Col>
        <Col sm={6}>
            <NumberInput label='Соблюден регламент' value={esin(calc.workday_real)}
                min={0} max={calc.workday_total || null}
                onChange={value => onChange({workday_real: value})}
                readOnly={readOnly}/>
        </Col>
        <Col sm={12}>
            <p>Коэффициент соблюдения регламента: <b><big>{format_fixed(workday_ratio(calc), 2)}</big></b></p>
        </Col>
    </Row>
</React.Fragment>;


const SalaryForm = ({calc, plan, year, month, readOnly, onChange}) => {
    const result_points = deal_result_points(calc, plan);
    const dc_points = deal_count_points(calc, plan);
    const workday_points = calc_workday_points(calc);
    const crm_points = calc_crm_points(calc);
    const call_duration = calc_call_duration(calc, plan);
    const successful_calls_count = calc_successful_calls_count(calc, plan);

    const total_points = calc_total_points(calc, plan);

    // const income_over_max_1_5 = Math.min(calc.deal_result / PLAN.income, 1.5);
    // const income_over_points = income_over_max_1_5 * WEIGHTS.INCOME;
    // const income_over_amount = calc.deal_result - PLAN.income;

    // const income_over_total_points = total_points - result_points.points + income_over_points;
    // const income_over_salary = income_over_amount * CASH_TAX * INCOME_BONUS * income_over_total_points;

    // const income_over_bonus = income_over_amount * CASH_TAX * INCOME_BONUS * (income_over_total_points - 1);
    // const total_salary = PLAN.income * CASH_TAX * INCOME_BONUS * total_points + income_over_salary;
    // const total_salary = calc_total_salary_from_points(calc, income_over_total_points);
    const total_salary = calc_total_salary(calc, plan, year, month);

    const now = new Date();
    const prognosis = year == now.getFullYear() && month == now.getMonth()+1;

    return <div className={salaryForm}>
        <Row>
            <Col sm={8}>
                <h4>Ведение CRM</h4>
                <Table className={salaryCalcTable} hover size='sm' responsive>
                    <thead>
                        <tr>
                            <th>Стандарт</th>
                            <th className='text-right'>Вес</th>
                            <th className='text-right'>Рабочих дней</th>
                            <th className='text-right'>Соблюдены стандарты</th>
                            <th className='text-right'>%&nbsp;выполнения</th>
                            <th className='text-right'>С учётом веса</th>
                            <th className='text-right'>Потери</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Отсутствие компаний без сделок</th>
                            <td>25%</td>
                            <td>{calc.workday_total}</td>
                            {/* <td className={withInput}>
                                <UnlabelledNumberInput readOnly={readOnly}
                                    className={narrowField}
                                    value={esin(calc.crm_days_companies_without_deals)}
                                    min={0} max={calc.workday_total || null}
                                    onChange={value => onChange({crm_days_companies_without_deals: value})}/>
                            </td> */}
                            <td>{calc.crm_days_companies_without_deals}</td>
                            <td className={centered}>{format_fixed(crm_points.percents.companies_without_deals, 2)}</td>
                            <td>{format_fixed(crm_points.points.companies_without_deals, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                crm_days_companies_without_deals: calc.workday_total
                            }, plan, year, month))}</td>
                        </tr>
                        <tr>
                            <th>Отсутствие сделок без задач</th>
                            <td>25%</td>
                            <td>{calc.workday_total}</td>
                            {/* <td className={withInput}>
                                <UnlabelledNumberInput readOnly={readOnly}
                                    className={narrowField}
                                    value={esin(calc.crm_days_deals_without_tasks)} 
                                    min={0} max={calc.workday_total || null}
                                    onChange={value => onChange({crm_days_deals_without_tasks: value})}/>
                            </td> */}
                            <td>{calc.crm_days_deals_without_tasks}</td>
                            <td className={centered}>{format_fixed(crm_points.percents.deals_without_tasks, 2)}</td>
                            <td>{format_fixed(crm_points.points.deals_without_tasks, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                crm_days_deals_without_tasks: calc.workday_total
                            }, plan, year, month))}</td>
                        </tr>
                        <tr>
                            <th>Отсутствие просроченных задач</th>
                            <td>25%</td>
                            <td>{calc.workday_total}</td>
                            {/* <td className={withInput}>
                                <UnlabelledNumberInput readOnly={readOnly}
                                    className={narrowField}
                                    value={esin(calc.crm_days_without_expired_tasks)} 
                                    min={0} max={calc.workday_total || null}
                                    onChange={value => onChange({crm_days_without_expired_tasks: value})}/>
                            </td> */}
                            <td>{calc.crm_days_without_expired_tasks}</td>
                            <td className={centered}>{format_fixed(crm_points.percents.without_expired_tasks, 2)}</td>
                            <td>{format_fixed(crm_points.points.without_expired_tasks, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                crm_days_without_expired_tasks: calc.workday_total
                            }, plan, year, month))}</td>
                        </tr>
                        <tr>
                            <th>Соответствие amoCRM c с калькулятором ЗП</th>
                            <td>25%</td>
                            <td></td>
                            <td></td>
                            <td className={withInput + ' ' + centered}>
                                <UnlabelledNumberInput readOnly={readOnly}
                                    value={esin(calc.crm_percent_match_zpcalc)}
                                    min={0} max={1} step={0.1}
                                    onChange={value => onChange({crm_percent_match_zpcalc: value})}/>
                            </td>
                            <td>{format_fixed(crm_points.points.match_zpcalc, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                crm_percent_match_zpcalc: 1
                            }, plan, year, month))}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style={{fontWeight: 'bold', fontSize: '120%'}}>
                            <th colSpan={5} style={{textAlign: 'right'}}>Эффективность ведения CRM</th>
                            <td>{format_fixed(crm_points.total_percent, 2)}</td>
                            <td/>
                        </tr>
                    </tfoot>
                </Table>
            </Col>
            <Col sm={1}/>
            <Col sm={3}>
                <WorkdaysBlock calc={calc} prognosis={prognosis} readOnly={readOnly} onChange={onChange}/>
            </Col>
        </Row>

        {/* <hr/> */}

        <Row>
            <Col sm={8}>
                <h4>Эффективность</h4>
                <Table className={salaryCalcTable} hover size='sm' responsive>
                    <thead>
                        <tr>
                            <th>Показатель</th>
                            <th>Вес</th>
                            <th>Факт</th>
                            <th>План</th>
                            <th>% выполнения</th>
                            <th>С учётом веса</th>
                            <th>Бонус/потери</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Принесённая прибыль, база</th>
                            <td>{format_percent(WEIGHTS.INCOME)}</td>
                            <td>{format_price(calc.deal_result)}</td>
                            <td>{format_price(plan.base)}</td>
                            <td>{format_fixed(result_points.percent, 2)}</td>
                            <td>{format_fixed(result_points.points, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                deal_result: plan.base
                            }, plan, year, month))}</td>
                        </tr>
                        <tr>
                            <th>Ведение CRM, %</th>
                            <td>{format_percent(WEIGHTS.CRM)}</td>
                            <td>{format_fixed(crm_points.total_percent, 2)}</td>
                            <td>1</td>
                            <td>{format_fixed(crm_points.total_percent, 2)}</td>
                            <td>{format_fixed(crm_points.total_points, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                crm_days_companies_without_deals: calc.workday_total,
                                crm_days_deals_without_tasks: calc.workday_total,
                                crm_days_without_expired_tasks: calc.workday_total,
                                crm_percent_match_zpcalc: 1
                            }, plan, year, month))}</td>
                        </tr>
                        <tr>
                            <th>Количество сделок</th>
                            <td>{format_percent(WEIGHTS.DEAL_COUNT)}</td>
                            <td>{calc.deal_count}</td>
                            <td>{plan.deal_count}</td>
                            <td>{format_fixed(dc_points.percent, 2)}</td>
                            <td>{format_fixed(dc_points.points, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                deal_count: plan.deal_count
                            }, plan, year, month))}</td>
                        </tr>
                        <tr>
                            <th>Соблюдение регламента рабочего времени, %</th>
                            <td>{format_percent(WEIGHTS.WORKDAYS)}</td>
                            <td>{calc.workday_real}</td>
                            <td>{calc.workday_total}</td>
                            <td>{format_fixed(workday_points.percent, 2)}</td>
                            <td>{format_fixed(workday_points.points, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                workday_real: calc.workday_total
                            }, plan, year, month))}</td>
                        </tr>
                        <tr>
                            <th>Продолжительность телефонных разговоров, мин</th>
                            <td>{format_percent(WEIGHTS.CALL_DURATION)}</td>
                            {/* <td className={withInput}>
                                <UnlabelledNumberInput readOnly={readOnly}
                                    className={narrowField}
                                    value={esin(calc.call_duration)}
                                    onChange={value => onChange({call_duration: value})}/>
                            </td> */}
                            <td>{calc.call_duration || 0}</td>
                            <td>{call_duration.plan || ''}</td>
                            <td>{format_fixed(call_duration.percent, 2)}</td>
                            <td>{format_fixed(call_duration.points, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                call_duration: plan.call_duration_per_day * calc.workday_total
                            }, plan, year, month))}</td>
                        </tr>
                        <tr>
                            <th>Количество успешных телефонных звонков, шт</th>
                            <td>{format_percent(WEIGHTS.SUCCESSFUL_CALLS_COUNT)}</td>
                            {/* <td className={withInput}>
                                <UnlabelledNumberInput readOnly={readOnly}
                                    className={narrowField}
                                    value={esin(calc.successful_calls_count)}
                                    onChange={value => onChange({successful_calls_count: value})}/>
                            </td> */}
                            <td>{calc.successful_calls_count || 0}</td>
                            <td>{successful_calls_count.plan || ''}</td>
                            <td>{format_fixed(successful_calls_count.percent, 2)}</td>
                            <td>{format_fixed(successful_calls_count.points, 2)}</td>
                            <td>{format_price(total_salary - calc_total_salary({
                                ...calc,
                                successful_calls_count: plan.successful_calls_count_per_day * calc.workday_total
                            }, plan, year, month))}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style={{fontWeight: 'bold', fontSize: '120%'}}>
                            <th colSpan={5} style={{textAlign: 'right'}}>Коэффициент результативности</th>
                            <td>{format_fixed(total_points, 2)}</td>
                            <td/>
                        </tr>
                    </tfoot>
                </Table>
            </Col>
            <Col sm={1}/>
            <Col sm={3}>
                <PlanBlock plan={plan}/>
            </Col>
        </Row>

        {/*<Row>
            <Col sm={12}>
                <Alert color='secondary'>
                    <p>
                        Выполненная доля от плана (макс. 1.5): {format_fixed(income_over_max_1_5, 2)}
                    </p>
                    <p>
                        С учётом веса: {format_fixed(income_over_points, 2)}
                    </p>
                    <p>
                        Сумма перевыполнения базы: {format_fixed(income_over_amount, 2)}
                    </p>
                    <p>
                        Коэф. результативности с клипированием по 1.5 от плана: {format_fixed(income_over_total_points, 2)}
                    </p>
                    <p>
                        Оплата за превышение базы: {format_fixed(income_over_salary, 2)}
                    </p>
                    <p>
                        Бонус за перевыполнение базы: {format_fixed(income_over_bonus, 2)}
                    </p>
                </Alert>
            </Col>
        </Row>*/}
        <Row>
            <Col>
                <h3>Бонус к выплате, ИТОГО: {format_price(total_salary)}</h3>
            </Col>
        </Row>
    </div>;
};


class StatefulSalaryForm extends React.PureComponent {
    constructor() {
        super(...arguments);

        this.state = {
            calc: this.props.calc || {}
        };

        this.onChange = this.onChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.calc != this.props.calc)
            this.setState({calc: nextProps.calc || {}});
    }

    onChange(setter) {
        this.setState({calc: {...this.state.calc, ...setter}});
    }

    render() {
        return <SalaryForm {...this.props} calc={this.state.calc} onChange={this.onChange}/>
    }
}


function* iter_months() {
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth()+1;
    while (year >= 2018) {
        yield [year, month];
        if (--month == 0) {
            year--;
            month = 12;
        }
    }
}


const month_key = (year, month) => `${year}-${pad0(month, 2)}`;

const MonthChooser = ({year, month, onChangeMonth}) =>
    <Col sm={4}>
        <BaseField label='Месяц' type='select' value={month_key(year, month)} onChange={value => {
                const [year, month] = value.split('-');
                onChangeMonth(year, month);
            }}>
            {Array.from(group_by(iter_months(), ([year, month]) => year)).map(
                ({key, items}) => <optgroup key={key} label={key.toString()}>{
                    items.map(([year, month]) =>
                        <option key={month} value={month_key(year, month)}>{
                            `${month_names[month-1]} ${year}`
                        }</option>
                    )
                }</optgroup>
            )}
        </BaseField>
    </Col>;


class UserMonthChooserImpl extends React.PureComponent {

    componentDidMount() {
        if (!this.props.users.loading && !this.props.users.loaded)
            this.props.onLoadUsers();
    }

    render() {
        if (!this.props.users.loaded)
            return <h2 className='text-muted'>Загрузка...</h2>;

        if (!this.props.users.loaded)
            return <h2 className='text-danger'>Ошибка загрузки</h2>;

        return <React.Fragment>
                <Col sm={4}>
                    <BaseField label='Менеджер' type='select' value={this.props.user_id == null ? '' : this.props.user_id}
                               onChange={value => this.props.onChangeUserId(value == '' ? null : +value)}>
                        <option value="">Выберите менеджера</option>
                        <option value="">———————————</option>
                        {this.props.users.list.slice().sort(cmp_by_username).map(
                            user => <option key={user.id} value={user.id}>{user.username}</option>
                        )}
                    </BaseField>
                </Col>
                <MonthChooser year={this.props.year} month={this.props.month}
                                onChangeMonth={this.props.onChangeMonth}/>
        </React.Fragment>;
    }
};

const UserMonthChooser = connect(
    state => ({
        users: state.salary.users
    }),

    {
        onLoadUsers: load_users
    }
)(UserMonthChooserImpl);



class CalcLoaderImpl extends React.PureComponent {
    constructor() {
        super(...arguments);

        this.calc_form = React.createRef();

        this.on_save = this.on_save.bind(this);
    }

    componentDidMount() {
        this.maybe_load(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.maybe_load(nextProps);
    }

    maybe_load(props) {
        if (!props.calc_state || (!props.calc_state.loaded && !props.calc_state.loading))
            props.onLoadSalaryCalc(props.user.id, props.year, props.month);
    }

    on_save() {
        this.props.onSaveSalaryCalc(this.props.user.id, this.props.year, this.props.month, this.calc_form.current.state.calc);
    }

    save_button() {
        let color, text, disabled;
        if (this.props.calc_state.saving)
            [color, text, disabled] = ['success', 'Сохранение...', true];
        else if (this.props.calc_state.saving_error)
            [color, text, disabled] = ['danger', 'Ошибка сохранения', false];
        else
            [color, text, disabled] = ['success', 'Сохранить', false];
        return <Button onClick={this.on_save} color={color} disabled={disabled}>{text}</Button>;
    }

    render() {
        if (!this.props.calc_state || !this.props.calc_state.loaded)
            return <Col xs={12}><p>Загрузка...</p></Col>;

        if (this.props.calc_state.error)
            return <Col xs={12}><p>Ошибка загрузки</p></Col>;

        return <React.Fragment>
            {!this.props.readOnly &&
                <Col sm={4} className='text-right'>
                    <label style={{display: 'block'}}>&nbsp;</label>
                    {this.save_button()}
                </Col>}
            {/*<Row>
                <Col className='text-right'>{this.save_button()}</Col>
            </Row>*/}
            <Col xs={12} className='mt-4'>
                <StatefulSalaryForm ref={this.calc_form}
                    readOnly={this.props.readOnly}
                    calc={this.props.calc_state.calc} plan={this.props.plan}
                    year={this.props.year} month={this.props.month}/>
                {!this.props.readOnly && <Row>
                    <Col className='text-right'>{this.save_button()}</Col>
                </Row>}
            </Col>
        </React.Fragment>;
    }
}
const CalcLoader = connect(
    (state, {user, year, month}) => ({
        calc_state: get_by_uid_year_month(state.salary.calcs.by_user_year_month, user.id, year, month),
        plan: get_user_month_plan(user, new Date(year, month-1, 1)),
    }),

    {
        onLoadSalaryCalc: load_calc,
        onSaveSalaryCalc: save_calc
    }
)(CalcLoaderImpl);


class SalaryPageImpl extends React.PureComponent {
    render() {
        const {user_id, onChangeUserId, year, month, onChangeMonth, users} = this.props;
        const user = users.by_id && users.by_id[user_id];

        const now = new Date();

        return <Page wide>
            <h1>
                Расчёт за месяц
                {/* {year == now.getFullYear() && month == now.getMonth()+1 &&
                    <span> (прогноз)</span>} */}
            </h1>

            <Row>
                <UserMonthChooser
                    user_id={user_id} onChangeUserId={onChangeUserId}
                    year={year} month={month} onChangeMonth={onChangeMonth}
                    />
                {user && <CalcLoader readOnly={this.props.readOnly} user={user} year={year} month={month}/>}
            </Row>
        </Page>
    }
};

export const SalaryPage = connect(
    state => ({
        users: state.salary.users
    })
)(SalaryPageImpl);



class UserSalaryPageImpl extends React.PureComponent {
    componentDidMount() {
        if (!this.props.users.loading && !this.props.users.loaded)
            this.props.onLoadUsers();
    }

    render() {
        const users = this.props.users;
        const user = users.by_id && users.by_id[this.props.user_id];

        return <Page wide>
            <h1>
                Расчёт за месяц
                {/* {year == now.getFullYear() && month == now.getMonth()+1 &&
                    <span> (прогноз)</span>} */}
            </h1>

            <Row>
                <MonthChooser year={this.props.year} month={this.props.month}
                    onChangeMonth={this.props.onChangeMonth}/>
                {user && <CalcLoader readOnly user={user} year={this.props.year} month={this.props.month}/>}
            </Row>
        </Page>;
    }
}

export const UserSalaryPage = connect(
    state => ({
        users: state.salary.users,
        user_id: state.login.user_id
    }),

    {
        onLoadUsers: load_users
    }
)(UserSalaryPageImpl);

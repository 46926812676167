export default (num, form1, form234, formMany) => {
    const rem10 = num % 10;
    const rem100 = num % 100;

    if (rem100 > 10 && rem100 < 20)
        return formMany;

    if (rem10 == 1) return form1;
    if (rem10 >= 2 && rem10 <= 4) return form234;

    return formMany;
}

import React from 'react';
import {connect} from 'react-redux';
import {Container, Button, Row, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import {push} from 'react-router-redux';

import {get, post} from './Api';


const LOADING_LOGIN = 'LOADING_LOGIN';
const LOGIN_KNOWN = 'LOGIN_KNOWN';
const LOGIN_LOADING_FAIL = 'LOGIN_LOADING_FAIL';

const TRY_LOGIN = 'TRY_LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';



const response2actionprops = (response) => ({
    logged_in: response.logged_in,
    user_id: response.user_id || null,
    username: response.username || null,
    role: response.role || null
});

const check_login = () => async (dispatch) => {
    dispatch({type: LOADING_LOGIN});
    try {
        const response = await get('login');
        dispatch({
            type: LOGIN_KNOWN,
            user_props: response2actionprops(response)
        });
    }
    catch (failure) {
        dispatch({type: LOGIN_LOADING_FAIL});
    }
};

const try_login = (username, password) => async (dispatch) => {
    dispatch({type: TRY_LOGIN});
    try {
        const response = await post('login', { username, password });
        if (response.logged_in)
            dispatch({type: LOGIN_SUCCESS, user_props: response2actionprops(response)});
        else
            dispatch({type: LOGIN_FAIL});
    }
    catch (failure) {
        dispatch({type: LOGIN_FAIL});
    }
};

export const logout = () => (dispatch) => {
    post('logout');
    dispatch(push('/'));
    dispatch({type: LOGOUT});
};


const initial = {
    known: false,
    loading: false,
    loading_fail: false,
    logged_in: false,
    user_id: null,
    username: null,
    role: null,
    trying: false,
    fail: false,
};

export const reducer = (state = initial, action) => {
    switch (action.type) {
        case LOADING_LOGIN:
            return {...state, loading: true};
        case LOGIN_KNOWN:
            return {
                ...state,
                loading: false,
                known: true,
                ...action.user_props
            };
        case LOGIN_LOADING_FAIL:
            return {...state, loading: false, loading_fail: true};
        case TRY_LOGIN:
            return {...state, trying: true, fail: false};
        case LOGIN_SUCCESS:
            return {
                ...state,
                trying: false,
                fail: false,
                ...action.user_props,
            }
        case LOGIN_FAIL:
            return {...state, trying: false, fail: true};
        case LOGOUT:
            return {...state, logged_in: false};
        default:
            return state;
    }
}

class LoginFormImpl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: ''
        }
    }

    try_login() {
        this.props.onLoginButton(this.state.username, this.state.password);
    }

    render() {
        let error = null;
        if (this.props.fail)
            error = <p className='text-danger'>Неправильный логин или пароль</p>;

        return <Container className="mt-5">
            <Row>
                <Col md={{size: 5, offset: 3}}>
                    <Form onKeyDown={(ev) => {if (ev.which == 13) this.try_login();}}>
                        <FormGroup>
                            <Label>Логин</Label>
                            <Input type='text' value={this.state.username}
                                onChange={(ev) => this.setState({username: ev.target.value})}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Пароль</Label>
                            <Input type='password' value={this.state.password}
                                onChange={(ev) => this.setState({password: ev.target.value})}/>
                        </FormGroup>
                        {error}
                        <Button onClick={() => this.try_login()}
                            disabled={this.props.trying}
                        >
                            {this.props.trying ? 'Проверка...' : 'Вход'}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    }
};

const LoginForm = connect(
    (state) => state.login,

    {
        onLoginButton: try_login
    }
)(LoginFormImpl);


class LoginPageImpl extends React.Component {
    componentDidMount() {
        if (!this.props.known && !this.props.loading)
            this.props.onCheckLogin();
    }

    // componentWillReceiveProps(nextProps) {
    //     if (!this.props.logged_in && nextProps.logged_in) {
    //         console.log('goto deals');
    //         this.props.onGotoDeals();
    //     }
    // }

    render() {
        if (this.props.known)
            return <LoginForm/>;
        else
            return <h1 className="text-center text-muted font-italic">Загрузка...</h1>;
    }
}

export const LoginPage = connect(
    (state) => state.login,

    (dispatch) => ({
        onCheckLogin: () => dispatch(check_login()),
    })
)(LoginPageImpl);

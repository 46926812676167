import React from 'react';
import {hot} from 'react-hot-loader';
import {connect} from 'react-redux';
import {Table, Form, FormGroup, Label, Input, Row, Col, Button} from 'reactstrap';
import {push} from 'react-router-redux';
import { Link } from 'react-router-dom';

import { get, post } from './Api';
import {LOGOUT} from './Login';
import plural from './plural';
import {TextInput, NumberInput, BaseField} from './form-controls';
import { Page } from './Page';
import { get_user_month_plan } from './user-utils';


export const USER_STATUS = {
    active: 'active',
    inactive: 'inactive'
};


export const USER_ROLE = {
    accountant: 'accountant',
    admin: 'admin',
    hunter: 'hunter',
    farmer: 'farmer',
    logist: 'logist',
};


const LOADING_USERS = 'LOADING_USERS';
const LOAD_USERS__OK = 'LOAD_USERS__OK';
const LOAD_USERS__FAIL = 'LOAD_USERS__FAIL';

const CHANGING_PASSWORD = 'CHANGING_PASSWORD';
const CHANGE_PASSWORD__OK = 'CHANGE_PASSWORD__OK';
const CHANGE_PASSWORD__FAIL = 'CHANGE_PASSWORD__FAIL';

const CREATING_USER = 'CREATING_USER';
const CREATE_USER__OK = 'CREATE_USER__OK';
const CREATE_USER__FAIL = 'CREATE_USER__FAIL';

const DELETE_USER = 'DELETE_USER';

const SAVING_USER = 'SAVING_USER';
const SAVE_USER__OK = 'SAVE_USER__OK';
const SAVE_USER__FAIL = 'SAVE_USER__FAIL';


export const load_users = () => async (dispatch) => {
    dispatch({type: LOADING_USERS});
    try {
        const response = await get('users');
        dispatch({type: LOAD_USERS__OK, users: response});
    }
    catch (failure) {
        dispatch({type: LOAD_USERS__FAIL, loading_failure: failure});
    }
};

const change_password = (user_id, password) => async (dispatch) => {
    dispatch({type: CHANGING_PASSWORD});
    try {
        const response = await post('change-password', {user_id, password});
        dispatch({type: CHANGE_PASSWORD__OK});
        dispatch(push('/'));
    }
    catch (failure) {
        dispatch({type: CHANGE_PASSWORD__FAIL});
    }
};

const create_user = (username, password) => async (dispatch) => {
    dispatch({type: CREATING_USER});
    try {
        const response = await post('create-user', {username, password});
        if (response.ok) {
            dispatch({type: CREATE_USER__OK, user_id: response.user_id, username: username});
            dispatch(push('/'));
        }
        else
            dispatch({type: CREATE_USER__FAIL, error: response.error});
    }
    catch (failure) {
        dispatch({type: CREATE_USER__FAIL, error: 'Не удалось добавить пользователя'});
    }
};

const delete_user = (user_id) => (dispatch) => {
    if (confirm('Действительно удалить пользователя?')) {
        post('delete-user', {id: user_id});
        dispatch({type: DELETE_USER, user_id});
    }
}


const save_user = (user) => async (dispatch) => {
    dispatch({type: SAVING_USER});
    try {
        await post('save-user', user);
        dispatch({type: SAVE_USER__OK, user});
        dispatch(push('/'));
    }
    catch (failure) {
        console.error(failure);
        dispatch({type: SAVE_USER__FAIL});
    }
};


const initial = {
    loaded: false,
    loading: false,
    fail: false,

    users: null,

    changing_password: false,
    changing_password_fail: false,

    adding_user: false,
    adding_user_error: null,

    saving_user: false,
    saving_user_fail: false,
};

export const reducer = (state = initial, action) => {
    switch (action.type) {
        case LOADING_USERS: return {...state, loading: true, fail: false};
        case LOAD_USERS__OK: return {...state, loading: false, loaded: true, fail: false, users: action.users};
        case LOAD_USERS__FAIL: return {...state, loading: false, loaded: true, fail: true, users: []};

        case CHANGING_PASSWORD: return {...state, changing_password: true, changing_password_fail: false};
        case CHANGE_PASSWORD__OK: return {...state, changing_password: false, changing_password_fail: false};
        case CHANGE_PASSWORD__FAIL: return {...state, changing_password: false, changing_password_fail: true};

        case CREATING_USER: return {...state, adding_user: true, adding_user_error: null};
        case CREATE_USER__OK: return {...state, adding_user: false, adding_user_error: null};
        case CREATE_USER__FAIL: return {...state, adding_user: false, adding_user_error: action.error};

        case DELETE_USER: return {
            ...state,
            users: state.users.filter(user => user.id != action.user_id)
        };

        case LOGOUT: return initial;

        case SAVING_USER: return {...state, saving_user: true, saving_user_fail: false};
        case SAVE_USER__OK: return {
            ...state,
            users: state.users.map(user => {
                if (user.id != action.user.id)
                    return user;

                const {plan, ...rest} = action.user;

                const cur_year = new Date().getFullYear();
                const cur_month = new Date().getMonth() + 1;

                return {
                    ...user,
                    ...rest,
                    month_plans: user.month_plans
                        .filter(([year, month, _]) => year != cur_year || month != cur_month)
                        .concat([cur_year, cur_month, plan.base, plan.deal_count, plan.call_duration_per_day, plan.successful_calls_count_per_day])
                };
            }),
            saving_user: false,
            saving_user_fail: false
        };
        case SAVE_USER__FAIL: return {...state, saving_user: false, saving_user_fail: true};

        default: return state;
    }
};


export const cmp_by_username = (u1, u2) => {
    if (u1.username.toLowerCase() < u2.username.toLowerCase()) return -1;
    if (u1.username.toLowerCase() > u2.username.toLowerCase()) return +1;
    return 0;
};


const UserList = ({users, onDeleteUser}) => {
    const rows = users.slice().sort(cmp_by_username).map((user) => 
        <tr key={user.id}>
            <td>
                <Link to={`/users/${user.id}/edit`}>
                    {user.role == USER_ROLE.admin && '🔑 '}
                    {user.role == USER_ROLE.accountant && '💰 '}
					{user.role == USER_ROLE.logist && '🚗 '}
                    {user.username}
                </Link>
            </td>
            <td>
				{user.role != USER_ROLE.logist &&
					<Link to={`/users/${user.id}/deals`}>
						{user.deal_count} {plural(user.deal_count, 'сделка', 'сделки', 'сделок')}
					</Link>
				}
				{user.role == USER_ROLE.logist &&
					<Link to={`/users/${user.id}/transfers`}>
						{user.transfer_count} {plural(user.transfer_count, 'перевозка', 'перевозки', 'перевозок')}
					</Link>					
				}
            </td>
            <td>
                <Link to={`/users/${user.id}/change-password`}>Сменить пароль</Link>
                {user.deal_count == 0 && user.role != USER_ROLE.admin &&
                    <a href='#' className='text-danger ml-3' onClick={(ev) => {ev.preventDefault(); onDeleteUser(user.id)}}>
                        Удалить
                    </a>
                }
            </td>
        </tr>
    );

    return <Table className='mt-4' responsive>
        <thead>
            <tr>
                <th>Логин</th>
                <th>Количество сделок</th>
                <th></th>
            </tr>
        </thead>
        <tbody>{rows}</tbody>
    </Table>;
};


const CompleteUserList = ({loading, fail, users, onDeleteUser}) => {
    if (fail)
        return <p className='text-danger'>Ошибка загрузки</p>;

    if (users == null)
        return <p className='text-muted'>Загрузка...</p>;

    const active = users.filter(user => user.status == USER_STATUS.active);
    const inactive = users.filter(user => user.status != USER_STATUS.active);

    return <div>
        <UserList users={active} onDeleteUser={onDeleteUser}/>
        {inactive.length > 0 && <React.Fragment>
            <hr/>
            <h2>Неактивные</h2>
            <UserList users={inactive} onDeleteUser={onDeleteUser}/>
        </React.Fragment>}
    </div>;
};


class UsersPageImpl extends React.Component {
    componentDidMount() {
        this.props.onLoadUsers();
    }

    render() {
        return <Page>
            <Row className='align-items-center'>
                <Col sm={7}><h1>Пользователи</h1></Col>
                <Col sm={5} className='text-sm-right'>
                    <Link to='/users/new' className='btn btn-outline-success'>Добавить пользователя</Link>
                </Col>
            </Row>
            <CompleteUserList {...this.props}/>
        </Page>
    }
}

export const UsersPage = hot(module)(connect(
    (state) => state.users,
    (dispatch) => ({
        onLoadUsers: () => dispatch(load_users()),
        onDeleteUser: (user_id) => dispatch(delete_user(user_id)),
    })
)(UsersPageImpl));


class ChangePasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password1: '',
            password2: '',
            error: null
        }
    }

    set_password() {
        if (this.state.password1 != this.state.password2)
            this.setState({error: 'Пароли отличаются'});
        else if (this.state.password1 == '')
            this.setState({error: 'Пароль не может быть пустым'});
        else if (!this.props.changing_password) {
            this.setState({error: null});
            this.props.onChangePassword(this.props.user_id, this.state.password1);
        }
    }

    render() {
        return <Page>
            <h1>Сменить пароль для <samp>{this.props.username}</samp></h1>
            <Row className='mt-3'>
                <Col sm={8} md={6} lg={5}>
                    <Form>
                        <FormGroup>
                            <Label for='password1'>Новый пароль</Label>
                            <Input id='password1' type='password' value={this.state.password1}
                                onChange={(ev) => this.setState({password1: ev.target.value})}
                                onKeyDown={(ev) => {if (ev.which == 13) this.set_password()}}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for='password2'>Ещё раз</Label>
                            <Input id='password2' type='password' value={this.state.password2}
                                onChange={(ev) => this.setState({password2: ev.target.value})}
                                valid={this.state.password2 != '' && this.state.password2 == this.state.password1}
                                invalid={this.state.password2 != '' && this.state.password2 != this.state.password1}
                                onKeyDown={(ev) => {if (ev.which == 13) this.set_password()}}
                            />
                        </FormGroup>
                        <p className='text-danger'>
                            {this.state.error || (this.props.changing_password_fail && 'Не удалось изменить пароль')}
                        </p>
                        <FormGroup>
                            <Link to='/' className='btn btn-outline-secondary'>Отмена</Link>
                            {' '}
                            <Button
                                color={this.props.changing_password_fail ? 'danger' : 'primary'}
                                disabled={this.props.changing_password}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    this.set_password();
                                }}
                            >
                                Установить пароль
                            </Button>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </Page>;
    }
}


class ChangeAnothersPasswordPageImpl extends React.Component {
    componentDidMount() {
        if (this.props.username == null)
            this.props.onLoadUsers();
    }

    render() {
        if (this.props.username == null)
            return <p className='text-muted'>Загрузка...</p>;

        return <ChangePasswordPage {...this.props}/>
    }
}

export const ChangeAnothersPasswordPage = connect(
    (state, {user_id}) => ({
        user_id,
        username: state.users.users ? state.users.users.filter(user => user.id == user_id)[0].username : null,
        changing_password: state.users.changing_password,
        changing_password_fail: state.users.changing_password_fail,
    }),
    (dispatch) => ({
        onLoadUsers: () => dispatch(load_users()),
        onChangePassword: (user_id, password) => dispatch(change_password(user_id, password))
    })
)(ChangeAnothersPasswordPageImpl);


export const ChangeMyPasswordPage = connect(
    (state) => ({
        user_id: state.login.user_id,
        username: state.login.username
    }),
    (dispatch) => ({
        onChangePassword: (user_id, password) => dispatch(change_password(user_id, password))
    })
)(ChangePasswordPage);



class NewUserPageImpl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password1: '',
            password2: '',
            error: null,
        };
    }

    set_password() {
        if (this.state.username.trim() == '')
            this.setState({error: 'Логин не может быть пустым'});
        else if (this.state.password1 != this.state.password2)
            this.setState({error: 'Пароли отличаются'});
        else if (this.state.password1 == '')
            this.setState({error: 'Пароль не может быть пустым'});
        else if (!this.props.changing_password) {
            this.setState({error: null});
            this.props.onCreateUser(this.state.username.trim(), this.state.password1);
        }
    }

    errcode_to_human(errcode) {
        switch (errcode) {
            case null: return null;
            case 'username_taken': return 'Логин уже занят';
            default: return errcode;
        }
    }

    render() {
        return <Page>
            <h1>Новый пользователь</h1>
            <Row>
                <Col lg={4} md={6} sm={8}>
                    <Form>
                        <FormGroup>
                            <Label for='username'>Логин</Label>
                            <Input id='username' type='text' value={this.state.username}
                                onChange={(ev) => this.setState({username: ev.target.value})}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for='password1'>Пароль</Label>
                            <Input id='password1' type='password' value={this.state.password1}
                                onChange={(ev) => this.setState({password1: ev.target.value})}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for='password2'>Ещё раз пароль</Label>
                            <Input id='password2' type='password' value={this.state.password2}
                                onChange={(ev) => this.setState({password2: ev.target.value})}
                                valid={this.state.password2 != '' && this.state.password2 == this.state.password1}
                                invalid={this.state.password2 != '' && this.state.password2 != this.state.password1}
                                onKeyDown={(ev) => {if (ev.which == 13) this.set_password()}}
                                />
                        </FormGroup>
                        <p className='text-danger'>
                            {this.state.error || this.errcode_to_human(this.props.adding_user_error)}
                        </p>
                        <Row>
                            <Col xs={6}><Link to='/' className='btn btn-outline-secondary'>Отмена</Link></Col>
                            <Col xs={6} className='text-right'>
                                <Button color='primary' onClick={(ev) => {
                                    ev.preventDefault();
                                    this.set_password();
                                }}>Сохранить</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Page>
    }
}

export const NewUserPage = connect(
    (state) => ({
        adding_user: state.users.adding_user,
        adding_user_error: state.users.adding_user_error,
    }),

    (dispatch) => ({
        onCreateUser: (username, password) => dispatch(create_user(username, password))
    })
)(NewUserPageImpl);



class EditUserForm extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            username: props.user.username,
            plan: get_user_month_plan(props.user, new Date()),
            status: props.user.status,
            role: props.user.role,
            error: null
        };
    }

    save_user() {
        let error = null;
        if (this.state.username.trim() == '')
            error = 'Логин не может быть пустым';

        this.setState({error});
        if (error != null)
            return;

        if (!this.props.saving_user) {
            this.props.onSaveUser({
                id: this.props.user.id,
                username: this.state.username.trim(),
                plan: this.state.plan,
                status: this.state.status,
                role: this.state.role
            });
        }
    }

    render() {
        return <Row>
            <Col>
                <Form>
                    <Row><Col  lg={4} md={6} sm={8}>
                        <TextInput label='Логин' value={this.state.username} onChange={value => this.setState({username: value})}/>
                    </Col></Row>
                    <Row><Col  lg={4} md={6} sm={8}>
                        <BaseField label='Статус' type='select' value={this.state.status} onChange={value => this.setState({status: value})}>
                            <option value={USER_STATUS.active}>Активный</option>
                            <option value={USER_STATUS.inactive}>Неактивный</option>
                        </BaseField>
                    </Col></Row>
                    <Row><Col  lg={4} md={6} sm={8}>
                        <BaseField label='Роль' type='select' value={this.state.role} onChange={value => this.setState({role: value})}>
                            <option value={USER_ROLE.hunter}>Хантер</option>
                            <option value={USER_ROLE.farmer}>Фермер</option>
                            <option value={USER_ROLE.logist}>Логист</option>
                            <option value={USER_ROLE.accountant}>Бухгалтер</option>
                            <option value={USER_ROLE.admin}>Администратор</option>
                        </BaseField>
                    </Col></Row>

                    <h5 className='mt-5'>План на текущий месяц</h5>
                    <Row>
                        <Col sm={4}>
                            <NumberInput label='База' value={this.state.plan.base || ''} onChange={value => this.setState({plan: {...this.state.plan, base: value}})}/>
                        </Col>
                        <Col sm={4}>
                            <NumberInput label='Количество сделок' value={this.state.plan.deal_count || ''} onChange={value => this.setState({plan: {...this.state.plan, deal_count: value}})}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <NumberInput label='Продолжительность звонков в день' value={this.state.plan.call_duration_per_day || ''} onChange={value => this.setState({plan: {...this.state.plan, call_duration_per_day: value}})}/>
                        </Col>
                        <Col sm={4}>
                            <NumberInput label='Количество успешных звонков в день' value={this.state.plan.successful_calls_count_per_day || ''} onChange={value => this.setState({plan: {...this.state.plan, successful_calls_count_per_day: value}})}/>
                        </Col>
                    </Row>

                    <FormGroup className='mt-5'>
                        <p className='text-danger'>
                            {this.state.error || (this.props.saving_user_fail && 'Не удалось сохранить изменения')}
                        </p>
                        <Link to='/' className='btn btn-outline-secondary'>Отмена</Link>
                        {' '}
                        <Button color={this.props.saving_user_fail ? 'danger' : 'primary'}
                            disabled={this.props.saving_user}
                                onClick={ev => {
                                ev.preventDefault();
                                this.save_user();
                            }}>
                            Сохранить
                        </Button>
                    </FormGroup>
                </Form>
            </Col>
        </Row>;
    }
}


class EditUserPageImpl extends React.Component {
    componentDidMount() {
        if (this.props.user == null)
            this.props.onLoadUsers();
    }

    render() {
        if (this.props.user == null)
            return <p className='text-muted'>Загрузка...</p>

        return <Page>
            <h1>{this.props.user.username}</h1>
            <EditUserForm {...this.props}/>
        </Page>;
    }
}

export const EditUserPage = connect(
    (state, {user_id}) => ({
        user: state.users.users && state.users.users.filter(user => user.id == user_id)[0],
        saving_user: state.users.saving_user,
        saving_user_fail: state.users.saving_user_fail,
    }),
    dispatch => ({
        onLoadUsers: () => dispatch(load_users()),
        onSaveUser: (user) => dispatch(save_user(user))
    })
)(EditUserPageImpl);

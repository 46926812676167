import React from 'react';
import {hot} from 'react-hot-loader';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {connect} from 'react-redux';
import thunk from 'redux-thunk';
import {Container, Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler, Collapse} from 'reactstrap';
import {replace} from 'react-router-redux';

import {createBrowserHistory} from 'history';
import { Route, Switch, Redirect } from 'react-router';
import { NavLink as RouteLink } from 'react-router-dom';
import {ConnectedRouter, routerReducer, routerMiddleware, LOCATION_CHANGE} from 'react-router-redux';

import {UsersPage, reducer as users_reducer, ChangeAnothersPasswordPage, ChangeMyPasswordPage, NewUserPage, EditUserPage, USER_ROLE} from './Users';
import {reducer as calculator_reducer} from './Calculator';
import {LoginPage, reducer as login_reducer, logout} from './Login';
import {MyDealsPage, reducer as deals_reducer, DealEditPage, MyDealEditPage,
        DealViewPage, NewDealPage, MyNewDealPage, OthersDealsPage, AllDealsPage} from './Deals';
import {RankTablePage, reducer as ranktable_reducer} from './RankTable';

import {TransportCalc} from './transport-calc';

import {LogistsPage, reducer as logists_reducer} from './logists';
import {MyTransfersPage, reducer as transfers_reducer, TransferEditPage, MyTransferEditPage,
        TransferViewPage, MyTransferViewPage, NewTransferPage, MyNewTransferPage, OthersTransfersPage, AllTransfersPage} from './Transfers';
import {MyTransportersPage, reducer as transporters_reducer, TransporterEditPage, MyTransporterEditPage,
            TransporterViewPage, NewTransporterPage, MyTransporterViewPage, MyNewTransporterPage, OthersTransportersPage, AllTransportersPage} from './Transporters';

import './icons/open-iconic-bootstrap.css';
import './css/main.css';
import { date_from_string, iso_date_string, pad0 } from './utils';
import { ExcelExportPage, reducer as excel_export_reducer } from './ExcelExport';
import { SalaryPage, reducer as salary_reducer, UserSalaryPage } from './SalaryCalc';
import { DONE_FILTER } from './deals-math';
// import { DONE_FILTER_T } from './transfers-math';
import { SessionsPage, reducer as sessions_reducer } from './Sessions';


const history = createBrowserHistory();
const middleware = routerMiddleware(history);


const toggle_menu = () => ({type: 'TOGGLE_MENU'});


export const store = createStore(
    combineReducers({
        router: routerReducer,
        calculator: calculator_reducer,
        users: users_reducer,
        login: login_reducer,
        deals: deals_reducer,
        logists: logists_reducer,
        transfers: transfers_reducer,
        transporters: transporters_reducer,
        ranktable: ranktable_reducer,
        excel_export: excel_export_reducer,
        salary: salary_reducer,
        sessions: sessions_reducer,
        menu_open: (state = false, action) => {
            switch (action.type) {
                case 'TOGGLE_MENU': return !state;
                case LOCATION_CHANGE:
                    return false;
                default: return state;
            }
        }
    }),
    applyMiddleware(thunk, middleware)
);


const RankTableFragment = () => <React.Fragment>
    <Route exact path='/table' render={() => <Redirect to={`/table/month/${iso_date_string(new Date())}/${DONE_FILTER.DONE}`}/>}/>
    <Route path='/table/:period_type/:date/:done_filter' render={({match}) =>
        <RankTablePage period_type={match.params.period_type}
                       date={date_from_string(match.params.date)} 
                       done_filter={match.params.done_filter}/>
    }/>
</React.Fragment>;

const UsersDealsFragment = ({onRedirect}) => <React.Fragment>
    <Route exact path='/users/:user_id/deals' render={({match}) =>
        <Redirect to={`/users/${match.params.user_id}/deals/null--null--all--all--all`}/>
    }/>
    <Route path='/users/:user_id/deals/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <OthersDealsPage user_id={parseInt(match.params.user_id)}
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect(`/users/${match.params.user_id}/deals/${url}`)}/>
    }/>

    <Route exact path='/users/:user_id/deal/:deal_id/edit' render={({match}) =>
        <DealEditPage user_id={parseInt(match.params.user_id)} deal_id={parseInt(match.params.deal_id)}
            cancel_href={`/users/${match.params.user_id}/deals`}
            ok_href={`/users/${match.params.user_id}/deals`}
        />
    }/>
    <Route exact path='/users/:user_id/deal/:deal_id/view' render={({match}) =>
        <DealViewPage user_id={parseInt(match.params.user_id)} deal_id={parseInt(match.params.deal_id)}
            cancel_href={`/users/${match.params.user_id}/deals`}
            ok_href={`/users/${match.params.user_id}/deals`}
        />
    }/>
    <Route exact path='/all-deals/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <AllDealsPage
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect('/all-deals/' + url)}/>
    }/>
</React.Fragment>;

const UsersTransfersFragment = ({onRedirect}) => <React.Fragment>
    <Route exact path='/users/:user_id/transfers' render={({match}) =>
        <Redirect to={`/users/${match.params.user_id}/transfers/null--null--all--all--all`}/>
    }/>
    <Route path='/users/:user_id/transfers/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <OthersTransfersPage user_id={parseInt(match.params.user_id)}
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect(`/users/${match.params.user_id}/transfers/${url}`)}/>
    }/>

    <Route exact path='/users/:user_id/transfer/:transfer_id/edit' render={({match}) =>
        <TransferEditPage user_id={parseInt(match.params.user_id)} transfer_id={parseInt(match.params.transfer_id)}
            cancel_href={`/users/${match.params.user_id}/transfers`}
            ok_href={`/users/${match.params.user_id}/transfers`}
        />
    }/>
    <Route exact path='/users/:user_id/transfer/:transfer_id/view' render={({match}) =>
        <TransferViewPage user_id={parseInt(match.params.user_id)} transfer_id={parseInt(match.params.transfer_id)}
            cancel_href={`/users/${match.params.user_id}/transfers`}
            ok_href={`/users/${match.params.user_id}/transfers`}
        />
    }/>
    <Route exact path='/all-transfers/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <AllTransfersPage
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect('/all-transfers/' + url)}/>
    }/>
</React.Fragment>;

const UsersTransportersFragment = ({onRedirect}) => <React.Fragment>
    <Route exact path='/users/:user_id/transporters' render={({match}) =>
        <Redirect to={`/users/${match.params.user_id}/transporters/null--null--all--all--all`}/>
    }/>
    <Route path='/users/:user_id/transporters/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <OthersTransportersPage user_id={parseInt(match.params.user_id)}
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect(`/users/${match.params.user_id}/transporters/${url}`)}/>
    }/>

    <Route exact path='/users/:user_id/transporter/:transporter_id/edit' render={({match}) =>
        <TransporterEditPage user_id={parseInt(match.params.user_id)} transporter_id={parseInt(match.params.transporter_id)}
            cancel_href={`/users/${match.params.user_id}/transporters`}
            ok_href={`/users/${match.params.user_id}/transporters`}
        />
    }/>
    <Route exact path='/users/:user_id/transporter/:transporter_id/view' render={({match}) =>
        <TransporterViewPage user_id={parseInt(match.params.user_id)} transporter_id={parseInt(match.params.transporter_id)}
            cancel_href={`/users/${match.params.user_id}/transporters`}
            ok_href={`/users/${match.params.user_id}/transporters`}
        />
    }/>
    <Route exact path='/all-transporters/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <AllTransportersPage
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect('/all-transporters/' + url)}/>
    }/>
</React.Fragment>;


const user_salary_url = (year, month) => `/salary/${year}-${pad0(month, 2)}`;
const salary_url = (user_id, year, month) => `/salary/user-${user_id == null ? 'null' : user_id}/${year}-${pad0(month, 2)}`;

const MyDealsFragment = ({ok_href, cancel_href, onRedirect}) => <React.Fragment>
    <Route path='/deals/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <MyDealsPage
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect(`/deals/${url}`)}
            ok_href={ok_href}
            cancel_href={cancel_href}/>
    }/>
    <Route exact path='/new-deal' render={() => <MyNewDealPage ok_href={ok_href} cancel_href={cancel_href}/>}/>
    <Route path="/deal/:deal_id/edit" render={({match}) =>
        <MyDealEditPage deal_id={parseInt(match.params.deal_id)} ok_href={ok_href} cancel_href={cancel_href}/>
    }/>
</React.Fragment>;

const MyTransfersFragment = ({ok_href, cancel_href, onRedirect}) => <React.Fragment>
    <Route path='/transfers/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <MyTransfersPage
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect(`/transfers/${url}`)}
            ok_href={ok_href}
            cancel_href={cancel_href}/>
    }/>
    <Route exact path='/new-transfer' render={() => <MyNewTransferPage ok_href={ok_href} cancel_href={cancel_href}/>}/>
    <Route path="/transfer/:transfer_id/edit" render={({match}) =>
        <MyTransferEditPage transfer_id={parseInt(match.params.transfer_id)} ok_href={ok_href} cancel_href={cancel_href}/>
    }/>
	<Route path="/transfer/:transfer_id/view" render={({match}) =>
        <MyTransferViewPage transfer_id={parseInt(match.params.transfer_id)} ok_href={ok_href} cancel_href={cancel_href}/>
    }/>
</React.Fragment>;

const MyTransportersFragment = ({ok_href, cancel_href, onRedirect}) => <React.Fragment>
    <Route path='/transporters/:date_from--:date_to--:done_filter--:debt_filter--:tab' render={({match}) =>
        <MyTransportersPage
            date_from={date_from_string(match.params.date_from)}
            date_to={date_from_string(match.params.date_to)}
            done_filter={match.params.done_filter}
            debt_filter={match.params.debt_filter}
            tab={match.params.tab}
            onRedirect={url => onRedirect(`/transporters/${url}`)}
            ok_href={ok_href}
            cancel_href={cancel_href}/>
    }/>
    <Route exact path='/new-transporter' render={() => <MyNewTransporterPage ok_href={ok_href} cancel_href={cancel_href}/>}/>
    <Route path="/transporter/:transporter_id/edit" render={({match}) =>
        <MyTransporterEditPage transporter_id={parseInt(match.params.transporter_id)} ok_href={ok_href} cancel_href={cancel_href}/>
    }/>
    <Route path="/transporter/:transporter_id/view" render={({match}) =>
        <MyTransporterViewPage transporter_id={parseInt(match.params.transporter_id)} ok_href={ok_href} cancel_href={cancel_href}/>
    }/>
</React.Fragment>;

const UserInterface = ({onRedirect}) => <React.Fragment>
    {/* <Route exact path="/" component={MyDealsPage}/> */}
    <Route exact path='/' render={() =>
        <Redirect to='/deals/null--null--all--all--all'/>
    }/>
    <Route exact path='/deals' render={() =>
        <Redirect to='/deals/null--null--all--all--all'/>
    }/>
    <MyDealsFragment ok_href='/' cancel_href='/' onRedirect={onRedirect}/>

    <RankTableFragment/>
    <Route exact path='/change-password' component={ChangeMyPasswordPage}/>

    <Route exact path='/salary' render={() => <Redirect to={`/salary/${new Date().getFullYear()}-${new Date().getMonth()+1}`}/>}/>
    <Route path='/salary/:year-:month/' render={({match}) =>
        <UserSalaryPage year={+match.params.year} month={+match.params.month}
            onChangeMonth={(year, month) => onRedirect(user_salary_url(year, month))}/>
    }/>

    <Route exact path='/transporters' render={() =>
        <Redirect to='/transporters/null--null--all--all--all'/>
    }/>
    <MyTransportersFragment ok_href='/transporters' cancel_href='/transporters' onRedirect={onRedirect}/>

</React.Fragment>;


const LogistInterface = ({onRedirect}) => <React.Fragment>
	<Route exact path='/' render={() =>
        <Redirect to='/transfers/null--null--all--all--all'/>
    }/>
    <Route exact path='/transfers' render={() =>
        <Redirect to='/transfers/null--null--all--all--all'/>
    }/>
	<MyTransfersFragment ok_href='/transfers' cancel_href='/transfers' onRedirect={onRedirect}/>

	<Route exact path='/transporters' render={() =>
        <Redirect to='/transporters/null--null--all--all--all'/>
    }/>
    <MyTransportersFragment ok_href='/transporters' cancel_href='/transporters' onRedirect={onRedirect}/>

    <UsersTransportersFragment onRedirect={onRedirect}/>
	
	<Route exact path='/change-password' component={ChangeMyPasswordPage}/>
</React.Fragment>;


const AdminOrAccountantSalaryFragment = ({readOnly, onRedirect}) => <React.Fragment>
    <Route exact path='/salary' render={() => <Redirect to={`/salary/user-null/${new Date().getFullYear()}-${new Date().getMonth()+1}`}/>}/>
    <Route path='/salary/user-:user_id/:year-:month/' render={({match}) =>
        <SalaryPage readOnly={readOnly}
            user_id={match.params.user_id == 'null' ? null : +match.params.user_id}
            year={+match.params.year} month={+match.params.month}
            onChangeUserId={user_id => onRedirect(salary_url(user_id, match.params.year, match.params.month))}
            onChangeMonth={(year, month) => onRedirect(salary_url(match.params.user_id == 'null' ? null : match.params.user_id, year, month))} />
    }/>
</React.Fragment>;


const AccountantInterface = ({onRedirect}) => <React.Fragment>
    <Route exact path='/' render={() => <Redirect to='/table'/>}/>
    <Route exact path='/export' component={ExcelExportPage}/>
    <RankTableFragment/>
	<Route exact path='/transfers' render={() =>
        <Redirect to='/transfers/null--null--all--all--all'/>
    }/>
	<MyTransfersFragment ok_href='/transfers' cancel_href='/transfers' onRedirect={onRedirect}/>
	<Route exact path='/transporters' render={() =>
        <Redirect to='/transporters/null--null--all--all--all'/>
    }/>
    <MyTransportersFragment ok_href='/transporters' cancel_href='/transporters' onRedirect={onRedirect}/>
	<Route exact path='/logists' component={LogistsPage}/>
    <UsersDealsFragment onRedirect={onRedirect}/>
	<UsersTransfersFragment onRedirect={onRedirect}/>
    <AdminOrAccountantSalaryFragment readOnly onRedirect={onRedirect}/>
</React.Fragment>;


const AdminInterface = ({onRedirect}) => <React.Fragment>
    <Route exact path='/' component={UsersPage}/>

    <Route exact path='/deals' render={() =>
        <Redirect to='/deals/null--null--all--all--all'/>
    }/>
    <MyDealsFragment ok_href='/deals' cancel_href='/deals' onRedirect={onRedirect}/>
	
	<Route exact path='/transfers' render={() =>
        <Redirect to='/transfers/null--null--all--all--all'/>
    }/>
	<MyTransfersFragment ok_href='/transfers' cancel_href='/transfers' onRedirect={onRedirect}/>
	
    <Route exact path='/transporters' render={() =>
        <Redirect to='/transporters/null--null--all--all--all'/>
    }/>
	<MyTransportersFragment ok_href='/transporters' cancel_href='/transporters' onRedirect={onRedirect}/>

    <Route exact path='/users/new' component={NewUserPage}/>
    <Route exact path='/users/:user_id/change-password' render={({match}) =>
        <ChangeAnothersPasswordPage user_id={parseInt(match.params.user_id)}/>
    }/>
    <Route exact path='/users/:user_id/edit' render={({match}) => <EditUserPage user_id={parseInt(match.params.user_id)}/>}/>

    <UsersDealsFragment onRedirect={onRedirect}/>
	
    <UsersTransfersFragment onRedirect={onRedirect}/>

    <UsersTransportersFragment onRedirect={onRedirect}/>

    <Route exact path='/users/:user_id/new-deal' render={({match}) =>
        <NewDealPage user_id={parseInt(match.params.user_id)}
            cancel_href={`/users/${match.params.user_id}/deals`}
            ok_href={`/users/${match.params.user_id}/deals`}/>
    }/>
	
    <Route exact path='/users/:user_id/new-transfer' render={({match}) =>
        <NewTransferPage user_id={parseInt(match.params.user_id)}
            cancel_href={`/users/${match.params.user_id}/transfers`}
            ok_href={`/users/${match.params.user_id}/transfers`}/>
    }/>
	
    <Route exact path='/users/:user_id/new-transporter' render={({match}) =>
        <NewTransporterPage user_id={parseInt(match.params.user_id)}
            cancel_href={`/users/${match.params.user_id}/transporters`}
            ok_href={`/users/${match.params.user_id}/transporters`}/>
    }/>

    <RankTableFragment/>
    <Route exact path='/change-password' component={ChangeMyPasswordPage}/>

    <Route exact path='/export' component={ExcelExportPage}/>

    <Route path='/transport-calc' component={TransportCalc}/>

    <Route path='/sessions' component={SessionsPage}/>

    {/* <Route path='/salary' component={SalaryPage}/> */}

    <AdminOrAccountantSalaryFragment onRedirect={onRedirect}/>
</React.Fragment>;

const interfaces = {
    [USER_ROLE.hunter]: UserInterface,
    [USER_ROLE.farmer]: UserInterface,
    [USER_ROLE.accountant]: AccountantInterface,
    [USER_ROLE.admin]: AdminInterface,
    [USER_ROLE.logist]: LogistInterface
};

const AppImpl = ({logged_in, role, username, menu_open, onLogout, onRedirect, onToggleMenu}) => {
    const is_admin_or_accountant = [USER_ROLE.admin, USER_ROLE.accountant].indexOf(role) != -1;
    const is_accountant = [USER_ROLE.accountant].indexOf(role) != -1;
    const is_manager = [USER_ROLE.hunter, USER_ROLE.farmer].indexOf(role) != -1;
    const role_name = [role];
	
    if (logged_in)
        return <ConnectedRouter history={history}>
            <div>
                <Navbar color='light' light expand='lg'>
                    <Container>
                        <NavbarBrand href="/" className='d-lg-none'>ZPCalc</NavbarBrand>
                        <NavbarToggler onClick={onToggleMenu}/>
                        <Collapse isOpen={menu_open} navbar>
                            <Nav navbar>
                                {role == USER_ROLE.admin && <NavItem>
                                    <RouteLink className='nav-link' exact to='/'>Пользователи</RouteLink>
                                </NavItem>}
                                {(role == USER_ROLE.admin || is_manager) && <NavItem>
                                    <RouteLink className='nav-link' exact to='/deals'>Сделки</RouteLink>
                                </NavItem>}
                                {(role !== USER_ROLE.logist) && <NavItem>
                                    <RouteLink className='nav-link' exact to='/table'>Таблица результатов</RouteLink>
                                </NavItem>}
                                {/* {is_admin_or_accountant && <NavItem>
                                    <RouteLink className='nav-link' exact to='/export'>Экспорт</RouteLink>
                                </NavItem>
                                } */}
								{(role == USER_ROLE.logist) && <NavItem>
                                    <RouteLink className='nav-link' exact to='/transfers'>Экспедиция</RouteLink>
                                </NavItem>}
                                <NavItem>
                                    <RouteLink className='nav-link' exact to='/transporters'>Перевозчики</RouteLink>
                                </NavItem>
								{is_accountant && <NavItem>
                                    <RouteLink className='nav-link' exact to='/logists'>Логисты</RouteLink>
                                </NavItem>}
                                {role == USER_ROLE.adminOFF && <NavItem>
                                    <RouteLink className='nav-link' exact to='/transport-calc'>Транспортный калькулятор</RouteLink>
                                </NavItem>}
                                {is_admin_or_accountant && <NavItem>
                                    <RouteLink className='nav-link' to='/salary'>Расчёт за месяц</RouteLink>
                                </NavItem>}
                                {is_manager && <NavItem>
                                    <RouteLink className='nav-link' to='/salary'>Расчёт за месяц</RouteLink>
                                </NavItem>}
                                {role == USER_ROLE.admin && <NavItem>
                                    <RouteLink className='nav-link' to='/sessions'>Сессии</RouteLink>
                                </NavItem>}
                            </Nav>
                            <Nav navbar>
                                <NavItem>
                                    <RouteLink className='nav-link' exact to='/change-password'>Сменить пароль</RouteLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={(ev) => {ev.preventDefault(); onLogout()}}>Выход</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" ><span className='oi oi-person' title={username}/></NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
                <Switch>
                    {/* <AdminInterface onRedirect={onRedirect}/> */}
                    {React.createElement(interfaces[role], {onRedirect})}
                </Switch>
            </div>
        </ConnectedRouter>;
    else
        return <LoginPage/>;
};

export const App = hot(module)(connect(
    state => ({
        logged_in: state.login.logged_in,
        role: state.login.role,
        username: state.login.username,
        menu_open: state.menu_open
    }),

    {
        onLogout: logout,
        onRedirect: replace,
        onToggleMenu: toggle_menu
    }
)(AppImpl));

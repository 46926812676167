export const get_user_month_plan = (user, month_day) => {
    const target_year = month_day.getFullYear();
    const target_month = month_day.getMonth() + 1;

    const result = {
        base: null,
        deal_count: null,
        call_duration_per_day: null,
        successful_calls_count_per_day: null
    };

    for (let [year, month, base, deal_count, call_duration_per_day, successful_calls_count_per_day] of user.month_plans) {
        if (year > target_year || year == target_year && month > target_month)
            break;
        if (base != null) result.base = base;
        if (deal_count != null) result.deal_count = deal_count;
        if (call_duration_per_day != null) result.call_duration_per_day = call_duration_per_day;
        if (successful_calls_count_per_day != null) result.successful_calls_count_per_day = successful_calls_count_per_day;
    }

    return result;
};

import {date_from_string, iso_date_string} from './utils';


export const week_day_1_to_7 = (dt) => {
    const day = dt.getDay();
    return day == 0 ? 7 : day;
};

export const add_days = (dt, days) => new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + days);

export const simple_add_days = (value, days) => {
    const start_value = date_from_string(value);
    let new_date_value = date_from_string(value);
    if(start_value != null){
        new_date_value = new Date(start_value.getFullYear(), start_value.getMonth(), start_value.getDate() + days);
    }
    return iso_date_string(new_date_value);
};

export const round_date = dt =>
    new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0, 0);


const time_2020_02_01 = new Date(2020, 1, 1, 0, 0, 0).getTime();
const time_2023_03_22 = new Date(2023, 2, 22, 0, 0, 0).getTime();
export const get_cash_tax = (date = null) => {
    if (typeof date === 'string')
        date = date_from_string(date);

    if (date == null)
        date = new Date();

    if (date.getTime() < time_2020_02_01){
		return 0.89;
	}
	if (date.getTime() < time_2023_03_22){
		return 0.88;
	}
    console.log("086");
    return 0.86;
};


export const DEAL_TYPES = {
    buy_kg__sell_l: 'buy_kg__sell_l',                           // Купили в кг, продаем в л
    buy_kg__sell_kg: 'buy_kg__sell_kg',                         // Купили в кг, продаем в кг
    buy_kg__sell_l_cash: 'buy_kg__sell_l_cash',                 // Купили в кг, продаем в л за наличку
    buy_l__sell_l: 'buy_l__sell_l',                             // Купили в литрах, продаем за л
    buy_l__sell_l_cash: 'buy_l__sell_l_cash',                   // Купили в литрах, продаем за л за наличку
    buy_l__sell_kg: 'buy_l__sell_kg',                           // купили в литрах, продаем в кг
    buy_l_cash__sell_l: 'buy_l_cash__sell_l',                   // Купили в литрах за наличку, продали в л
    buy_l_cash__sell_l_cash: 'buy_l_cash__sell_l_cash',         // Купили в литрах за наличку, продали в л за наличку
    buy_l_cash__sell_kg: 'buy_l_cash__sell_kg',                 // Купили в литрах за наличку, продали в кг
    buy_l_cash__sell_kg_cash: 'buy_l_cash__sell_kg_cash',       // Купили в литрах за наличку, продали в кг за наличку
    buy_stock_or_rail__sell_ton: 'buy_stock_or_rail__sell_ton', // Купили на бирже или жд вагоны продали в тоннах
};



export const is_cash_sell_deal = deal => {
    switch (deal.deal_type) {
        case DEAL_TYPES.buy_kg__sell_l_cash:
        case DEAL_TYPES.buy_l__sell_l_cash:
        case DEAL_TYPES.buy_l_cash__sell_l_cash:
        case DEAL_TYPES.buy_l_cash__sell_kg_cash:
            return true;

        default:
            return false;
    }
};



export const deal_refuel = deal => parseFloat(deal.refuel) || 0;


export const calc_lost = deal => {
    const delivery = deal.distance * deal.delivery_price;
    let refuel_part;
    switch (deal.deal_type) {
        case DEAL_TYPES.buy_kg__sell_kg:
        case DEAL_TYPES.buy_kg__sell_l:
        case DEAL_TYPES.buy_kg__sell_l_cash:
            let density = 0.82;
            if (deal.deal_type != DEAL_TYPES.buy_kg__sell_kg)
                density = deal.buy_kg / deal.sell_l;
            refuel_part = deal_refuel(deal) * density / deal.buy_kg;
            return (deal.buy_kg/1000 * deal.buy_price_ton + delivery) * (1 - refuel_part);

        case DEAL_TYPES.buy_l__sell_l:
        case DEAL_TYPES.buy_l__sell_l_cash:
        case DEAL_TYPES.buy_l__sell_kg:
        case DEAL_TYPES.buy_l_cash__sell_l_cash:
        case DEAL_TYPES.buy_l_cash__sell_kg_cash:
            refuel_part = deal_refuel(deal) / deal.buy_l;
            return (deal.buy_l * deal.buy_price_l + delivery) * (1 - refuel_part);

        case DEAL_TYPES.buy_l_cash__sell_l:
        case DEAL_TYPES.buy_l_cash__sell_kg:
            refuel_part = deal_refuel(deal) / deal.buy_l;
            return (deal.buy_l * ((deal.buy_price_l / get_cash_tax(deal.date)) + 0.06) + delivery) * (1 - refuel_part);

        case DEAL_TYPES.buy_stock_or_rail__sell_ton:
            return deal.buy_ton * (deal.buy_price_ton + deal.rail_rate + deal.broker_rate);
    }
};

export const calc_found = deal => {
    switch (deal.deal_type) {
        case DEAL_TYPES.buy_kg__sell_l:
        case DEAL_TYPES.buy_kg__sell_l_cash:
        case DEAL_TYPES.buy_l__sell_l:
        case DEAL_TYPES.buy_l_cash__sell_l:
        case DEAL_TYPES.buy_l_cash__sell_l_cash:
        case DEAL_TYPES.buy_l__sell_l_cash:
            return deal.sell_price_l * (deal.sell_l - deal_refuel(deal));

        case DEAL_TYPES.buy_kg__sell_kg:
        case DEAL_TYPES.buy_l__sell_kg:
        case DEAL_TYPES.buy_l_cash__sell_kg:
        case DEAL_TYPES.buy_l_cash__sell_kg_cash:
            let density = 0.82;
            if (deal.deal_type != DEAL_TYPES.buy_kg__sell_kg)
                density = deal.sell_kg / deal.buy_l;
            return deal.sell_price_ton * (deal.sell_kg - deal_refuel(deal) * density)/1000;

        case DEAL_TYPES.buy_stock_or_rail__sell_ton:
            return deal.sell_price_ton * deal.sell_ton;
    }
};

export const calc_deal_profit = deal => {
    const found = calc_found(deal);
    const lost = calc_lost(deal);
    switch (deal.deal_type) {
        case DEAL_TYPES.buy_kg__sell_l_cash:
        case DEAL_TYPES.buy_l__sell_l_cash:
            return found / get_cash_tax(deal.date) - lost;

        default:
            return found - lost;
    }
};


const mul_if_positive = (value, multiplier) => value > 0 ? value * multiplier : value;


export const calc_base_rate = (deal) => {
    const date = date_from_string(deal.date);
    const vat = (date && date.getFullYear() <= 2018) ? 1.18 : 1.20;

    const found = calc_found(deal);
    const lost = calc_lost(deal);
    switch (deal.deal_type) {
        case DEAL_TYPES.buy_kg__sell_l_cash:
        case DEAL_TYPES.buy_l__sell_l_cash:
            return mul_if_positive((found / get_cash_tax(deal.date) - lost), 1 / vat * 0.95);

        case DEAL_TYPES.buy_l_cash__sell_l_cash:
        case DEAL_TYPES.buy_l_cash__sell_kg_cash:
            return found - lost

        default:
            return mul_if_positive((found - lost), 1 / vat * 0.8);
    }
};

export const calc_prepay_effect = ({prepay, prepay_sum, prepay_date, supplier_pay_date}) => {
    if (!prepay || !prepay_date || !supplier_pay_date)
        return 0;
    return prepay_sum * 0.24 / 365 * Math.ceil((date_from_string(supplier_pay_date).getTime() - date_from_string(prepay_date).getTime()) /24/60/60e3);
};


const is_valid_payment = (payment, not_after) => {
    const date = date_from_string(payment.date);
    if (date == null)
        return false;
    if (not_after == null)
        return true;

    // We need to compare dates, regardless of time within a date. May be there are easier ways.
    if (date.getFullYear() > not_after.getFullYear())
        return false;
    if (date.getFullYear() < not_after.getFullYear())
        return true;
    if (date.getMonth() > not_after.getMonth())
        return false;
    if (date.getMonth() < not_after.getMonth())
        return true;
    if (date.getDate() > not_after.getDate())
        return false;
    return true;
};

export const calc_payments_effects = (deal, not_after = null) => {
    const {supplier_pay_date, payments} = deal;
    const effects = [];

    const lost = calc_lost(deal);

    let paid = (deal.prepay ? deal.prepay_sum : 0);

    let prev_date = supplier_pay_date;
    for (let i = 0; i < payments.length; i++) {
        const payment = payments[i];
		let paymentMath = 0;
        if (!is_valid_payment(payment, not_after))
            continue;
        const date = date_from_string(payment.date);
        if (!date || !supplier_pay_date)
            continue;
        const days = Math.ceil((date.getTime() - new Date(prev_date).getTime()) / 24/60/60e3);
		
		if(days <= 0){
			paymentMath = Math.max(0, payment.sum) * 0.24 / 365 * days;
		}else{
			paymentMath = Math.max(0, lost - paid) * 0.24 / 365 * days;
			prev_date = new Date(date_from_string(payment.date).getTime());
			//console.log("days > 0 else");
		}
		effects.push(paymentMath);										
        paid += payment.sum;
    }
    return effects;
};

export const calc_total_payments_effect = (deal, not_after = null) =>
    calc_payments_effects(deal, not_after).reduce((a, b) => a + b, 0);

const INCOME_TAX = 0.2;

export const calc_deal_total = (deal, payments_not_after = null) => {
    const base_rate = calc_base_rate(deal);
    const prepay_effect = calc_prepay_effect(deal);
    const payments_effect = calc_total_payments_effect(deal, payments_not_after);
    const penalty_value = (deal.penalty || 0) * (1 - INCOME_TAX);

    return base_rate + prepay_effect - payments_effect + Math.max(0, Math.min(penalty_value, max_penalty_effect(deal)));
};

export const deal_debt = (deal_params, payments_not_after=null) => {
    // if (deal_params.payments.length == 0)
    //     return 0;  

    let left = calc_found(deal_params) - (deal_params.prepay ? deal_params.prepay_sum : 0);
    deal_params.payments
        .filter(payment => {
            const v = is_valid_payment(payment, payments_not_after);
            return v;
        })
        .forEach(payment => { left -= payment.sum });
    if (deal_params.penalty)
        left = Math.max(0, left - deal_params.penalty * (1 - INCOME_TAX));
    return left;
};

export const deal_debt_days = (deal, now = new Date()) => {
    if (is_complete_deal(deal))
        return null;

    const pay_date = date_from_string(deal.params.expected_pay_date);
    if (pay_date == null)
        return null;

    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return (today - pay_date) / 24/60/60e3;
};

export const is_complete_deal = (deal, payments_not_after=null) => deal_debt(deal.params, payments_not_after) < 1000;

const not = (predicate) => function () {return !predicate(...arguments);};


export const DONE_FILTER = {
    ALL: 'all',
    DONE: 'done',
    NOT_PAID: 'not_paid',
    NOT_SHIPPED: 'not_shipped',
    EXPIRED_DEBT: 'expired_debt'
};

export const filter_by_done = (deals, filter, payments_not_after=null) => {
    const is_complete = deal => is_complete_deal(deal, payments_not_after);

    switch (filter) {
        case DONE_FILTER.DONE:
            return deals.filter(is_complete);
        case DONE_FILTER.NOT_PAID:
            return deals.filter(not(is_complete)).filter(deal => date_from_string(deal.params.date) != null).filter(deal => !deal.params.exclude_from_ranktable);
        case DONE_FILTER.NOT_SHIPPED:
            return deals.filter(not(is_complete)).filter(deal => date_from_string(deal.params.date) == null);
        case DONE_FILTER.EXPIRED_DEBT:
            return deals.filter(is_expected_to_be_paid).filter(not(is_complete));
        default:
            return deals;
    }
};


export const DEBT_FILTER = {
    ALL: 'all',
    NONZERO_DEBT: 'nonzero_debt'
};

export const filter_by_debt = (deals, filter, payments_not_after=null) => {
    switch (filter) {
        case DEBT_FILTER.NONZERO_DEBT:
            return deals.filter(deal => Math.abs(deal_debt(deal.params, payments_not_after)) >= 1);
        default:
            return deals;
    }
};


export const is_expected_to_be_paid = (deal) => {
    const pay_date = date_from_string(deal.params.expected_pay_date);
    if (pay_date == null)
        return false;

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return pay_date < today;
};


// export const deal_account_date = (deal) => {
//     const {payments, date, penalty, penalty_date} = deal;

//     if (penalty && penalty_date)
//         return date_from_string(penalty_date);

//     return base_deal_date(deal);
// };


export const base_deal_date = (deal, payments_not_after=null) => {
    const {payments, date, prepay_date} = deal;

    const candidates = [
        (() => {
            if (payments) {
                for (let i = payments.length - 1; i >= 0; i--) {
                    if (!is_valid_payment(payments[i], payments_not_after))
                        continue;
                    return date_from_string(payments[i].date);
                }
            }
        })(),
        date_from_string(prepay_date),
        date_from_string(date)
    ].filter(dt => dt);

    if (candidates.length == 0)
        return null;

    return new Date(Math.max(...candidates));
};


export const date_in_optional_inclusive_range = (date, start, end) => {
    if (!start && !end)
        return true;
    if (start && !end)
        return date >= start;
    if (!start && end)
        return date <= end;
    return start <= date && date <= end;
};


export const deal_result_date_in_period = (deal, start, end, {only_unpaid=false, payments_not_after=null} = {}) => {
    const base_date = base_deal_date(deal, payments_not_after);
    const penalty_date = date_from_string(deal.penalty_date);

    if (deal.penalty && date_in_optional_inclusive_range(penalty_date, start, end)
        && (!only_unpaid || !deal.penalty_paid))
        return penalty_date;

    if (date_in_optional_inclusive_range(base_date, start, end)
        && (!only_unpaid || deal.status !== 'paid'))
        return base_date;

    return null;
};


export const max_penalty_effect = (deal, payments_not_after=null) => {
    const supplier_pay_date = date_from_string(deal.supplier_pay_date);
    const pay_date = date_from_string(deal.expected_pay_date);
    const payments_effect = calc_total_payments_effect(deal, payments_not_after);

    if (pay_date && supplier_pay_date)
    {
        const days = Math.ceil((pay_date.getTime() - supplier_pay_date.getTime()) / 24/60/60e3);
        const lost = calc_lost(deal) - (deal.prepay ? deal.prepay_sum : 0);
        return payments_effect - Math.max(0, lost) * 0.24 / 365 * days;
    }
    else
        return payments_effect;
};


export const deal_result_in_period = (deal, start, end, {only_unpaid=false, payments_not_after=null} = {}) => {
    let result = 0;

    const base_date = base_deal_date(deal, payments_not_after);
    const payments_effect = calc_total_payments_effect(deal, payments_not_after);
    const base_result = calc_base_rate(deal) + calc_prepay_effect(deal) - payments_effect;

    if (date_in_optional_inclusive_range(base_date, start, end)
        && (!only_unpaid || deal.status != 'paid'))
        result += base_result;

    const penalty_date = date_from_string(deal.penalty_date);
    if (deal.penalty && date_in_optional_inclusive_range(penalty_date, start, end)
        && (!only_unpaid || !deal.penalty_paid)) {
        const penalty_value = (deal.penalty || 0) * (1 - INCOME_TAX);
        result += Math.max(0, Math.min(penalty_value, max_penalty_effect(deal, payments_not_after)));
    }

    return result;
};


export const when_deal_may_be_paid = deal => {
    let last_payment_date = null;
    (deal.payments || []).forEach(payment => {
        const date = date_from_string(payment.date);
        if (!last_payment_date || date > last_payment_date)
            last_payment_date = date;
    });

    const date_candidates = [
        round_date(new Date()),
        date_from_string(deal.expected_pay_date),
        last_payment_date
    ].filter(dt => dt);
    
    return new Date(Math.max(...date_candidates));
};


export const deal_result_if_paid_today = deal => {
    const debt = deal_debt(deal);

    if (debt <= 0)
        return deal_result_in_period(deal, null, null);

    const debt_payment_date = when_deal_may_be_paid(deal);

    return deal_result_in_period({
        ...deal,
        payments: [
            ...(deal.payments || []),
            {
                date: debt_payment_date,
                sum: debt
            }
        ]
    }, null, null);
};



export const deal_validation_errors = (deal) => {
    const empty = value => !value || value.trim().length == 0;

    const errors = [];

    if (empty(deal.title))
        errors.push('Заполните поле Клиент');

    if (empty(deal.basis))
        errors.push('Заполните поле Базис');

    if (empty(deal.fuel_type))
        errors.push('Заполните поле Тип топлива');

    if (empty(deal.ttn_number))
        errors.push('Заполните поле Номер автомобиля, ФИО водителя');

    if (date_from_string(deal.expected_pay_date) == null)
        errors.push('Заполните поле Ожидаемая дата оплаты клиентом')

    if (deal.locked && !is_cash_sell_deal(deal) && !deal_has_files(deal))
        errors.push('Не загружены файлы УПД и Спецификации');

    return errors;
};


export const deal_has_files = deal => deal.file_upd_spec || (deal.file_upd && deal.file_spec);

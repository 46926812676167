import $ from 'jquery';
import Promise from 'promise';


$.ajaxPrefilter( function( options, originalOptions, jqXHR ) {
    options.crossDomain ={
        crossDomain: true
    };
    options.xhrFields = {
        withCredentials: true
    };
});
  

// export const api_base = 'http://localhost/api/';
export const api_base = '/api/';
// export const api_base = 'https://zpcalc.meebible.org/api/';

export const get = (method, data) => new Promise((resolve, reject) => {
    $.get(api_base + method, data)
        .done(resolve)
        .fail(reject);
});


export const post = (method, data) => new Promise((resolve, reject) => {
    $.post({
        url: api_base + method,
        data: JSON.stringify(data),
        dataType: 'json',
        contentType: 'application/json'
    })
    .done(resolve).fail(reject);
});


export const upload_file = (method, data, file) => new Promise((resolve, reject) => {
    try {
        const fdata = new FormData();
        for (const key of Object.keys(data))
            fdata.append(key, fdata[key]);
        fdata.append('file', file);

        $.post({
            url: api_base + method,
            processData: false,
            contentType: false,
            data: fdata
        })
        .done(resolve)
        .fail(reject);
    }
    catch (ex) {
        console.error(ex);
        throw ex;
    }
});

import React from 'react';
import {hot} from 'react-hot-loader';
import {connect} from 'react-redux';
import {Row, Col, Form, FormGroup, Label, Input, Button, FormText, CustomInput} from 'reactstrap';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';

import {round02} from './utils';
import {DEAL_TYPES, calc_lost, calc_found, calc_base_rate, calc_prepay_effect,
    calc_payments_effects, calc_deal_total, calc_deal_profit} from './deals-math';
import {TextInput, NumberInput, DateInput, BoundNumberField} from './form-controls';
import {USER_ROLE} from './Users';
import * as dropzoneCSS from './Calculator.css';
import { upload_file, api_base } from './Api';



const field_names = {
    buy_l: 'Объём купленного топлива (л)',
    buy_kg: 'Вес купленного топлива (кг)',
    buy_ton: 'Вес купленного топлива (тонны)',
    buy_price_l: 'Цена закупки (₽ / л)',
    buy_price_ton: 'Цена закупки (₽ / тонна)',
    sell_l: 'Объём проданного топлива (л)',
    sell_kg: 'Вес проданного топлива (кг)',
    sell_ton: 'Вес проданного топлива (тонны)',
    sell_price_l: 'Цена продажи (₽ / л)',
    sell_price_ton: 'Цена продажи (₽ / тонна)',

    rail_rate: 'Тариф ж/д (₽ / тонна)',
    broker_rate: 'Тариф брокера (₽ / тонна)',

    distance: 'Километраж рейса (км)',
    delivery_price: 'Цена доставки (₽ / км)',
    delivery_total: 'Итоговая стоимость доставки (₽)',

    refuel: 'Заправка нашей машины (л)'
};

const initial_state = {
    deal_type: DEAL_TYPES.buy_kg__sell_l,

    buy_l: 29000,
    buy_kg: 24124,
    buy_ton: 130,
    buy_price_l: 32,
    buy_price_ton: 45300,
    sell_l: 29840,
    sell_kg: 24450,
    sell_ton: 130,
    sell_price_l: 39,
    sell_price_ton: 47000,

    rail_rate: 2200,
    broker_rate: 82,

    distance: 500,
    delivery_price: 65,

    prepay: true,
    prepay_sum: 300000,
    prepay_date: '2018-04-01',
    supplier_pay_date: '2018-04-10',
    expected_pay_date: '2018-04-10',

    payments: [
        {date: '2018-04-13', sum: 400000},
        {date: '2018-04-16', sum: 150000},
        {date: '2018-04-20', sum: 313760}
    ]
};

const CHANGE_DEAL_FIELD = 'CHANGE_DEAL_FIELD';
const change_deal_field = (field, value) => ({type: CHANGE_DEAL_FIELD, field, value});

const CHANGE_PAYMENT_FIELD = 'CHANGE_PAYMENT_FIELD';
const change_payment_field = (payment_no, field, value) => ({type: CHANGE_PAYMENT_FIELD, payment_no, field, value});

const ADD_PAYMENT = 'ADD_PAYMENT';
const add_payment = () => ({type: ADD_PAYMENT});

const REMOVE_PAYMENT = 'REMOVE_PAYMENT';
const remove_payment = (payment_no) => ({type: REMOVE_PAYMENT, payment_no});


export const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case CHANGE_DEAL_FIELD:
            return {...state, [action.field]: action.value};

        case CHANGE_PAYMENT_FIELD:
            return {
                ...state,
                payments: state.payments.map((payment, i) => {
                    if (i == action.payment_no)
                        return {...payment, [action.field]: action.value};
                    return payment;
                })
            }

        case ADD_PAYMENT:
            return {
                ...state,
                payments: [...state.payments, {sum: 0, date: ''}]
            }

        case REMOVE_PAYMENT:
            return {
                ...state,
                payments: state.payments.filter((payment, i) => i != action.payment_no)
            }

        default: return state;
    }
};



const get_deal_fields = (deal_type) => {
    switch (deal_type) {
        case DEAL_TYPES.buy_kg__sell_l:
        case DEAL_TYPES.buy_kg__sell_l_cash:
            return ['buy_kg', 'buy_price_ton', 'sell_l', 'sell_price_l', 'distance', 'delivery_price', 'refuel'];
        case DEAL_TYPES.buy_kg__sell_kg:
            return ['buy_kg', 'buy_price_ton', 'sell_kg', 'sell_price_ton', 'distance', 'delivery_price', 'refuel'];
        case DEAL_TYPES.buy_l__sell_l:
        case DEAL_TYPES.buy_l__sell_l_cash:
        case DEAL_TYPES.buy_l_cash__sell_l:
        case DEAL_TYPES.buy_l_cash__sell_l_cash:
            return ['buy_l', 'buy_price_l', 'sell_l', 'sell_price_l', 'distance', 'delivery_price', 'refuel'];
        case DEAL_TYPES.buy_l__sell_kg:
        case DEAL_TYPES.buy_l_cash__sell_kg:
        case DEAL_TYPES.buy_l_cash__sell_kg_cash:
            return ['buy_l', 'buy_price_l', 'sell_kg', 'sell_price_ton', 'distance', 'delivery_price', 'refuel'];
        case DEAL_TYPES.buy_stock_or_rail__sell_ton:
            return ['buy_ton', 'buy_price_ton', 'sell_ton', 'sell_price_ton', 'rail_rate', 'broker_rate'];
    }
};

const DealFields = ({params, readOnly, onChangeField, onChangeFields}) => {
    const common = {
        deal: params,
        readOnly,
        onChangeField: onChangeField
    };

    const fields = get_deal_fields(params.deal_type);

    const field_elements = fields.filter(field_name => ['distance', 'delivery_price', 'refuel'].indexOf(field_name) == -1).map(field_name =>
        <Col sm={6} key={field_name}>
            <BoundNumberField field_name={field_name} label={field_names[field_name]} {...common}/>
        </Col>
    );

    if (fields.indexOf('refuel') != -1) {
        field_elements.push(
            <Col sm={4} key='refuel'>
                <BoundNumberField field_name='refuel' label={field_names['refuel']} {...common}/>
            </Col>
        );
    }

    const rows = [<Row key='field_elements'>{field_elements}</Row>];

    if (fields.indexOf('distance') != -1) {
        rows.push(<Row key='distance'>
            <Col sm={4} key='distance'>
                <NumberInput readOnly={readOnly} label={field_names['distance']}
                    value={params.distance || ''}
                    onChange={value => onChangeFields({
                        distance: value,
                        delivery_total: Math.round(value * params.delivery_price * 100) / 100
                    })} />
            </Col>
            <Col sm={4} key='delivery_price'>
                <NumberInput readOnly={readOnly} label={field_names['delivery_price']}
                    value={params.delivery_price || ''}
                    onChange={value => onChangeFields({
                        delivery_price: value,
                        delivery_total: Math.round(params.distance * value * 100) / 100
                    })} />
            </Col>
            <Col sm={4} key='delivery_total'>
                <NumberInput readOnly={readOnly} label={field_names['delivery_total']}
                    value={params.delivery_total || params.distance * params.delivery_price || ''}
                    onChange={value => onChangeFields({
                        delivery_total: value,
                        delivery_price: Math.round(value / params.distance * 100) / 100
                    })} />
            </Col>
        </Row>);
    }

    return <React.Fragment>{rows}</React.Fragment>;
};


export const Calculator = ({params, readOnly, role, onChangeField, onChangeFields, onAddPayment, onRemovePayment, onChangePaymentField}) => {
    const is_manager = [USER_ROLE.hunter, USER_ROLE.farmer].indexOf(role) != -1;
    const editable = !readOnly && (
        role == USER_ROLE.admin
     || is_manager && params.status != 'paid' && !params.locked
    );
    const penalty_editable = !readOnly && (
        role == USER_ROLE.admin
     || is_manager && !params.penalty_paid && !params.locked
    );
    const paid_editable = !readOnly && (
        role == USER_ROLE.admin
     || role == USER_ROLE.accountant
    );


    params = {
        deal_type: 'buy_kg__sell_l',
        payments: [],
        ...params
    };

    const lost = calc_lost(params);
    const found = calc_found(params);
    // const profit = found - lost;
    const profit = calc_deal_profit(params);
    const base_rate = calc_base_rate(params);
    const prepay_effect = calc_prepay_effect(params);
    const payments_effects = calc_payments_effects(params);

    const total = calc_deal_total(params);

    let left = found - (params.prepay ? params.prepay_sum : 0);
    const payment_controls = [];
    for (let i = 0; i < params.payments.length; i++) {
        const payment = params.payments[i];
        left -= payment.sum;
        let min_date = '';
        if (i > 0)
            min_date = params.payments[i-1].date;
        
        const left_is_zero = left < 1;

        payment_controls.push(
            <React.Fragment key={i}>
                <hr className="mt-4"/>
                <Row>
                    <Col sm={4}>
                        {editable && <a className='float-right text-info' href='#'
                            onClick={((i) => (ev) => {ev.preventDefault(); onRemovePayment(i)})(i)}>
                            удалить
                        </a>}
                        <h5>Платёж №{i+1}</h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <DateInput readOnly={!editable} label='Дата платежа' value={payment.date || ''}
                            min={min_date}
                            onChange={((i) => (date) => onChangePaymentField(i, 'date', date))(i)}/>
                    </Col>
                    <Col sm={4}>
                        <NumberInput readOnly={!editable} label='Сумма платежа' value={payment.sum || ''}
                            onChange={((i) => (sum) => onChangePaymentField(i, 'sum', sum))(i)}/>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <Label>Остаток</Label>
                            <Input value={round02(left)} readOnly
                                invalid={i == params.payments.length - 1 && !left_is_zero}
                                valid={i == params.payments.length - 1 && left_is_zero}/>
                        </FormGroup>
                    </Col>
                </Row>
                <FormText>
                    {payments_effects[i] > 0 ? 'Стоимость пользования отсрочкой ' : 'Бонус '}:
                    <strong> {round02(Math.abs(payments_effects[i]))}</strong>
                </FormText>
            </React.Fragment>
        );
    }

    return (
        <Form style={{marginTop: '30px'}}>
            <Row>
                <Col sm={6}>
                    <TextInput readOnly={!editable} label='Клиент'
                        value={params.title || ''} onChange={value => onChangeField('title', value)}
                        />
                </Col>
                <Col sm={3}>
                    <TextInput readOnly={!editable} label='Базис'
                        value={params.basis || ''} onChange={value => onChangeField('basis', value)}
                        />
                </Col>
                <Col sm={3}>
                    <TextInput readOnly={!editable} label='Тип топлива'
                        value={params.fuel_type || ''} onChange={value => onChangeField('fuel_type', value)}
                        />
                </Col>
            </Row>
            <Row className='justify-content-between'>
                <Col sm={6}>
                    <TextInput readOnly={!editable} label='Номер автомобиля, ФИО водителя'
                        value={params.ttn_number || ''} onChange={value => onChangeField('ttn_number', value)}
                        />
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label>Статус</Label>
                        <Input readOnly={!paid_editable} disabled={!paid_editable} type='select' value={params.status || 'unpaid'} onChange={ev => onChangeField('status', ev.target.value)}
                            className={classnames({
                                'border-success': params.status == 'paid',
                            })}>
                            <option value='unpaid'>Не оплачено</option>
                            <option value='paid'>Оплачено</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label>Тип сделки</Label>
                        <Input readOnly={!editable} disabled={!editable} type='select' value={params.deal_type} onChange={ev => onChangeField('deal_type', ev.target.value)}>
                            <option value={DEAL_TYPES.buy_kg__sell_l}>Купили в кг, продали в литрах</option>
                            <option value={DEAL_TYPES.buy_kg__sell_kg}>Купили в кг, продали в кг</option>
                            <option value={DEAL_TYPES.buy_kg__sell_l_cash}>Купили в кг, продали в л по бартеру</option>
                            <option value={DEAL_TYPES.buy_l__sell_l}>Купили в литрах, продали в литрах</option>
                            <option value={DEAL_TYPES.buy_l__sell_l_cash}>Купили в литрах, продали в литрах по бартеру</option>
                            <option value={DEAL_TYPES.buy_l__sell_kg}>Купили в литрах, продали в кг</option>
                            <option value={DEAL_TYPES.buy_l_cash__sell_l}>Купили в литрах по бартеру, продали в литрах</option>
                            <option value={DEAL_TYPES.buy_l_cash__sell_l_cash}>Купили в литрах по бартеру, продали в литрах по бартеру</option>
                            <option value={DEAL_TYPES.buy_l_cash__sell_kg}>Купили в литрах по бартеру, продали в кг</option>
                            <option value={DEAL_TYPES.buy_l_cash__sell_kg_cash}>Купили в литрах по бартеру, продали в кг по бартеру</option>
                            <option value={DEAL_TYPES.buy_stock_or_rail__sell_ton}>Купили на бирже или ж/д вагоны, продали в тоннах</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <DateInput readOnly={!editable} name='date' label='Дата реализации'
                        value={params.date || ''} onChange={(value) => onChangeField('date', value)}/>
                </Col>
                <Col sm={3}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <CustomInput type='checkbox' id='exclude_from_ranktable'
                            disabled={!editable}
                            label='Учитывать в Таблице'
                            checked={!params.exclude_from_ranktable}
                            onChange={ev => onChangeField('exclude_from_ranktable', !ev.target.checked)}/>
                    </FormGroup>
                </Col>
            </Row>

            <hr/>

            <DealFields readOnly={!editable} params={params} onChangeField={onChangeField} onChangeFields={onChangeFields}/>

            <dl className="row mb-0 text-muted small">
                <dd className="col-3 col-sm-2">Затраты</dd>
                <dt className="col-3 col-sm-2">{round02(lost)}</dt>
                <dd className="col-3 col-sm-2">Реализация</dd>
                <dt className="col-3 col-sm-6">{round02(found)}</dt>
                <dd className="col-3 col-sm-2">Прибыль</dd>
                <dt className="col-3 col-sm-2">{round02(profit)}</dt>
                <dd className="col-3 col-sm-2">База</dd>
                <dt className="col-3 col-sm-6">{(round02(base_rate)).toString()}</dt>
            </dl>

            <hr className="mt-4"/>

            <Row>
                <Col sm={4}>
                    <DateInput readOnly={!editable} name='supplier_pay_date' label='Дата оплаты поставщику'
                        // min={params.prepay && params.prepay_date || ''}
                        // max={params.payments[0] && params.payments[0].date || ''}
                        value={params.supplier_pay_date || ''} onChange={(value) => onChangeField('supplier_pay_date', value)}/>
                </Col>
                <Col sm={4}>
                    <DateInput readOnly={!editable} name='expected_pay_date' label='Ожидаемая дата оплаты клиентом'
                        value={params.expected_pay_date || ''} onChange={value => onChangeField('expected_pay_date', value)}/>
                </Col>
            </Row>

            <FormGroup check row>
                <h5 className='mt-3'>
                    <CustomInput id='prepay' disabled={!editable} type='checkbox'
                        checked={params.prepay || false}
                        onChange={(ev) => onChangeField('prepay', ev.target.checked)}
                        label='Предоплата'/>
                </h5>
            </FormGroup>

            {params.prepay && <Row>
                <Col sm={4}>
                    <NumberInput readOnly={!editable} name='prepay_sum' label='Сумма предоплаты'
                        value={params.prepay_sum || ''} onChange={(value) => onChangeField('prepay_sum', value)}/>
                </Col>
                <Col sm={4}>
                    <DateInput readOnly={!editable} name='prepay_date' label='Дата внесения предоплаты'
                        max={params.date || ''}
                        value={params.prepay_date || ''} onChange={(value) => onChangeField('prepay_date', value)}/>
                </Col>
                <Col sm={12}>
                    <FormText>
                        Бонус: <strong>{round02(prepay_effect)}</strong>
                    </FormText>
                </Col>
            </Row>}

            {payment_controls}
            {editable && <Row className='mt-4'>
                <Col sm={12}>
                    <Button outline size='sm' color="info" onClick={onAddPayment}>Добавить платёж</Button>
                </Col>
            </Row>}

            <hr/>

            <Row className='mt-4'>
                <Col sm={4}>
                    <NumberInput readOnly={!penalty_editable} name='penalty' label='Неустойка'
                        value={params.penalty || ''} onChange={(value) => onChangeField('penalty', value)}/>
                </Col>
                <Col sm={4}>
                    <DateInput readOnly={!penalty_editable} name='penalty_date' label='Дата платежа неустойки'
                        value={params.penalty_date || ''} onChange={value => onChangeField('penalty_date', value)}/>
                </Col>
                <Col sm={4}>
                    <Label>&nbsp;</Label>
                    <Input readOnly={!paid_editable} disabled={!paid_editable} name='penalty_paid' type='select'
                        value={params.penalty_paid ? 'true' : 'false'}
                        onChange={ev => onChangeField('penalty_paid', ev.target.value == 'true')}>
                        <option value='false'>Не оплачена</option>
                        <option value='true'>Оплачена</option>
                    </Input>
                </Col>
            </Row>
            

            <hr/>

            <Row>
                <Col md={6}>
                    <h2>Итог: {round02(total)} {total ? '₽' : ''}</h2>
                </Col>
                {role == USER_ROLE.admin && <Col sm={6} className='text-right'>
                    <FormGroup>
                        <CustomInput type='checkbox' id='locked'
                            label='Проверено'
                            readOnly={readOnly} checked={params.locked || false}
                            onChange={ev => onChangeField('locked', ev.target.checked)}/>
                    </FormGroup>
                </Col>}
            </Row>

            <hr/>

            <Row className='mb-3'>
                <Col md={3}>
                    <FileUploader readOnly={readOnly} title='Скан УПД' file_spec={params.file_upd} onChange={file_spec => onChangeField('file_upd', file_spec)} />
                </Col>
                <Col md={3}>
                    <FileUploader readOnly={readOnly} title='Скан Спецификации' file_spec={params.file_spec} onChange={file_spec => onChangeField('file_spec', file_spec)} />
                </Col>
                <Col md={2} style={{display: 'flex', flexFlow: 'column nowrap', alignItems: 'center'}}>
                    <div style={{width: '1px', background: '#ccc', flexGrow: '1'}}/>
                    <div style={{color: '#888', margin: '0.5em 0'}}>ИЛИ</div>
                    <div style={{width: '1px', background: '#ccc', flexGrow: '1'}}/>
                </Col>
                <Col md={4}>
                    <FileUploader readOnly={readOnly} title='Скан УПД+Спецификации' file_spec={params.file_upd_spec} onChange={file_spec => onChangeField('file_upd_spec', file_spec)} />
                </Col>
            </Row>
        </Form>
    );
};


export const DealForm = hot(module)(connect(
    ({calculator}) => ({
        deal: calculator,
        result: calc_base_rate(calculator)
    }),

    {
        onChangeField: change_deal_field,
        onChangePaymentField: change_payment_field,
        onAddPayment: add_payment,
        onRemovePayment: remove_payment
    }
)(Calculator));



class FileUploader extends React.PureComponent {

    constructor() {
        super(...arguments);

        this.onDrop = this.onDrop.bind(this);
        this.onClear = this.onClear.bind(this);

        this.state = {
            loading: false,
            error: null
        };
    }

    async onDrop(files) {
        if (files.length != 1)
            return;

        const file = files[0];

        const filename = file.name;
        this.setState({error: null, loading: true})

        try
        {
            const {ok, id} = await upload_file('upload-file', {}, file);
            if (ok) {
                this.setState({loading: false, error: null});
                this.props.onChange({id, filename, size: file.size});
            }
            else
                this.setState({loading: false, error: 'Не удалось загрузить файл'});
        }
        catch (e)
        {
            this.setState({loading: false, error: 'Не удалось загрузить файл'});
        }
    }

    onClear(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.onChange(null);
    }
    
    render() {
        return <Dropzone onDrop={this.onDrop} disabled={this.props.readOnly || this.state.loading}>
            {({getRootProps, getInputProps, isDragActive}) => 
                <div {...getRootProps({className: classnames(dropzoneCSS.dropzone, {
                    [dropzoneCSS.active]: isDragActive,
                    [dropzoneCSS.filled]: this.props.file_spec != null,
                    [dropzoneCSS.error]: this.state.error != null
                })})}>
                    <h5>{this.props.title}</h5>
                    <input {...getInputProps()}/>

                    {this.props.file_spec == null &&
                        <small>Перетащите сюда файл или&nbsp;нажмите для выбора</small>}

                    {this.props.file_spec &&
                        <div className={dropzoneCSS.row}>
                            <a href={`${api_base}download-file?id=${this.props.file_spec.id}`} className={dropzoneCSS.title}
                                onClick={event => event.stopPropagation()}
                                title={this.props.file_spec.filename}>
                                <span className={`oi oi-document ${dropzoneCSS.icon}`}/>
                                {this.props.file_spec.filename}
                            </a>
                            {!this.props.readOnly &&
                                <a href='#' className='text-danger' onClick={this.onClear}>
                                    <span className='oi oi-trash'/>
                                </a>}
                        </div>}

                    {this.state.loading &&
                        <div className={dropzoneCSS.loading}>загрузка файла...</div>}

                    {this.state.error &&
                        <div>{this.state.error}</div>}
                </div>
            }
        </Dropzone>;
    }
};

import React from 'react';
import ReactDOM from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import {Provider} from 'react-redux';

import {App, store} from './components/App';

import 'bootstrap/dist/css/bootstrap.css';

window.store = store;

const render = Component =>
    ReactDOM.render(
        <AppContainer>
            <Provider store={store}>
                <Component/>
            </Provider>
        </AppContainer>,
        document.getElementById('app')
    )

render(App);

if (module.hot) module.hot.accept('./components/App', () => render(App));

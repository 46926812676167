import React from 'react';
import { Container } from 'reactstrap';


export const Page = ({wide=false, children}) => {
    const style = {};
    if (wide)
        style.maxWidth = '1720px';

    return <Container className='mt-2 mb-4' style={style}>
        {children}
    </Container>;
};

import {date_from_string} from './utils';


export const TRANSPORTER_TYPES = {
    buy_transporter: 'buy_transporter',
};


export const transporter_validation_errors = (transporter) => {
    const empty = value => !value || String(value).trim().length == 0;

    const errors = [];

    if (empty(transporter.region))
        errors.push('Регион базирования');

    if (empty(transporter.name))
        errors.push('Имя');

    if (empty(transporter.phone))
        errors.push('Телефон');
        
    return errors;
};


import React from 'react';
import {hot} from 'react-hot-loader';
import {connect} from 'react-redux';
import {Table, Form, FormGroup, Label, Input, Row, Col, Button} from 'reactstrap';
import {push} from 'react-router-redux';
import { Link } from 'react-router-dom';

import { get, post } from './Api';
import {LOGOUT} from './Login';
import plural from './plural';
import {TextInput, NumberInput, BaseField} from './form-controls';
import { Page } from './Page';
import { get_user_month_plan } from './user-utils';

export const USER_STATUS = {
    active: 'active',
    inactive: 'inactive'
};


export const USER_ROLE = {
    accountant: 'accountant',
    admin: 'admin',
    hunter: 'hunter',
    farmer: 'farmer',
    logist: 'logist',
};


const LOADING_USERS = 'LOADING_USERS';
const LOAD_USERS__OK = 'LOAD_USERS__OK';
const LOAD_USERS__FAIL = 'LOAD_USERS__FAIL';


export const load_users = () => async (dispatch) => {
    dispatch({type: LOADING_USERS});
    try {
        const response = await get('users');
        dispatch({type: LOAD_USERS__OK, users: response});
    }
    catch (failure) {
        dispatch({type: LOAD_USERS__FAIL, loading_failure: failure});
    }
};


const initial = {
    loaded: false,
    loading: false,
    fail: false,

    users: null,

};

export const reducer = (state = initial, action) => {
    switch (action.type) {
        case LOADING_USERS: return {...state, loading: true, fail: false};
        case LOAD_USERS__OK: return {...state, loading: false, loaded: true, fail: false, users: action.users};
        case LOAD_USERS__FAIL: return {...state, loading: false, loaded: true, fail: true, users: []};

        case LOGOUT: return initial;

        default: return state;
    }
};


export const cmp_by_username = (u1, u2) => {
    if (u1.username.toLowerCase() < u2.username.toLowerCase()) return -1;
    if (u1.username.toLowerCase() > u2.username.toLowerCase()) return +1;
    return 0;
};


const UserList = ({users, onDeleteUser}) => {
    const rows = users.slice().sort(cmp_by_username).map((user) => 
        <tr key={user.id}>
            <td>
				<Link to={`/users/${user.id}/transfers`}>
						{user.role == USER_ROLE.logist && '🚗 '}
						{user.username}
				</Link>
            </td>
            <td>
				{user.role == USER_ROLE.logist &&
					<Link to={`/users/${user.id}/transfers`}>
						{user.transfer_count} {plural(user.transfer_count, 'перевозка', 'перевозки', 'перевозок')}
					</Link>					
				}
            </td>
        </tr>
    );

    return <Table className='mt-4' responsive>
        <thead>
            <tr>
                <th>Логин</th>
                <th>Количество перевозок</th>
            </tr>
        </thead>
        <tbody>{rows}</tbody>
    </Table>;
};


const CompleteUserList = ({loading, fail, users, onDeleteUser}) => {
    if (fail)
        return <p className='text-danger'>Ошибка загрузки</p>;

    if (users == null)
        return <p className='text-muted'>Загрузка...</p>;

    const active = users.filter(user => user.status == USER_STATUS.active).filter(user => user.role == USER_ROLE.logist);
    const inactive = users.filter(user => user.status != USER_STATUS.active).filter(user => user.role == USER_ROLE.logist);

    return <div>
        <UserList users={active} onDeleteUser={onDeleteUser}/>
        {inactive.length > 0 && <React.Fragment>
            <hr/>
            <h2>Неактивные</h2>
            <UserList users={inactive} onDeleteUser={onDeleteUser}/>
        </React.Fragment>}
    </div>;
};


class LogistsPageImpl extends React.Component {
    componentDidMount() {
        this.props.onLoadUsers();
    }

    render() {
        return <Page>
            <Row className='align-items-center'>
                <Col sm={7}><h1>Пользователи</h1></Col>
            </Row>
            <CompleteUserList {...this.props}/>
        </Page>
    }
}

export const LogistsPage = hot(module)(connect(
    (state) => state.users,
    (dispatch) => ({
        onLoadUsers: () => dispatch(load_users()),
        onDeleteUser: (user_id) => dispatch(delete_user(user_id)),
    })
)(LogistsPageImpl));


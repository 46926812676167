import React from 'react';
import {hot} from 'react-hot-loader';
import {connect} from 'react-redux';
import {Row, Col, Form, FormGroup, Label, Input, Button, FormText, CustomInput, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';

import {TRANSPORTER_TYPES} from './transporters-math';
import {TextInput, NumberInput, TextareaInput} from './form-controls';
import {USER_ROLE} from './Users';

const initial_state = {
    transporter_type: TRANSPORTER_TYPES.buy_transporter,
};

const CHANGE_TRANSPORTER_FIELD = 'CHANGE_TRANSPORTER_FIELD';
const change_transporter_field = (field, value) => ({type: CHANGE_TRANSPORTER_FIELD, field, value});

export const reducer = (state = initial_state, action) => {
    switch (action.type) {
        case CHANGE_TRANSPORTER_FIELD:
            return {...state, [action.field]: action.value};

        default: return state;
    }
};

export const Calculator = ({params, readOnly, role, onChangeField, onChangePaymentField}) => {
    const is_manager = [USER_ROLE.hunter, USER_ROLE.farmer].indexOf(role) != -1;
    const editable = !readOnly && (
        role == USER_ROLE.admin
     || is_manager && !params.locked
    );
 
    params = {
        transporter_type: 'buy_transporter',
        ...params
    };
    
    let disabled;
    if (params.tarif_price_check) {
        disabled = '';
    } else {
        disabled = "disabled";
    }

    return (
        <Form style={{marginTop: '30px'}}>
            <Row>
                <Col sm={6}>
                    <TextInput readOnly={!editable} label='Регион базирования' name='region'
                        value={params.region || ''} onChange={value => onChangeField('region', value)}
                        />
                </Col>
                <Col sm={6}>
                    <TextInput readOnly={!editable} label='Калибровка общая' name='calibration'
                        value={params.calibration || ''} onChange={value => onChangeField('calibration', value)}
                        />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <TextInput readOnly={!editable} label='Перевозчик' name='title'
                        value={params.title || ''} onChange={value => onChangeField('title', value)}
                        />
                </Col>
                <Col sm={6}>
                    <TextInput readOnly={!editable} label='Калибровка по отсекам' name='calibrationByCell'
                        value={params.calibrationByCell || ''} onChange={value => onChangeField('calibrationByCell', value)}
                        />
                </Col>
            </Row>
            <Row>
                <Col sm={6} style={{padding: 0}}>
                    <Col sm={12}>
                        <TextInput readOnly={!editable} label='Имя' name='name'
                            value={params.name || ''} onChange={value => onChangeField('name', value)}
                            />
                    </Col>
                    <Col sm={12}>
                        <TextInput readOnly={!editable} label='Телефон' name='phone'
                            value={params.phone || ''} onChange={value => onChangeField('phone', value)}
                            />
                    </Col>
                    <Col sm={12}>
                        <TextInput readOnly={!editable} label='E-mail' name='email'
                            value={params.email || ''} onChange={value => onChangeField('email', value)}
                            />
                    </Col>
                    <Col sm={12}>
                        <NumberInput readOnly={!editable} label='Ставка' name='bet'
                            value={params.bet || ''} onChange={value => onChangeField('bet', value)}
                            />
                    </Col>
                </Col>
                <Col sm={6} style={{padding: 0}}>
                    <Col sm={12}>
                        <TextareaInput readOnly={!editable} rows = {3} label='Марка и гос. номер' name='auto'
                            value={params.auto || ''} onChange={value => onChangeField('auto', value)}
                            />
                    </Col>
                    <Col sm={12}>
                        <TextareaInput readOnly={!editable} rows = {6} label='Комментарий' name='comment'
                            value={params.comment || ''} onChange={value => onChangeField('comment', value)}
                            />
                    </Col>
                </Col>
            </Row>
            <hr/>		
        </Form>
    );
};


export const TransporterForm = hot(module)(connect(
    ({calculator}) => ({
        transporter: calculator
    }),

    {
        onChangeField: change_transporter_field
    }
)(Calculator));


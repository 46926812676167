import React from 'react';
import {connect} from 'react-redux';
import XLSX from 'xlsx';
import {Row, Col, Form, FormGroup, Label, Input, Button, Alert} from 'reactstrap';

import { Page } from './Page';
import {add_days, is_complete_deal, deal_result_date_in_period, deal_result_in_period} from './deals-math';
import { iso_date_string, date_from_string, format_price, rus_date_string } from './utils';
import { get } from './Api';


const GENERATE_REPORT = 'GENERATE_REPORT';
const GENERATE_REPORT__OK = 'GENERATE_REPORT__OK';
const GENERATE_REPORT__FAIL = 'GENERATE_REPORT__FAIL';

const generate_report = (date_from, date_to) => async dispatch => {
    dispatch({type: GENERATE_REPORT, date_from, date_to});

    try {
        const response = await get('data_for_report', {
            date_from: iso_date_string(date_from),
            date_to: iso_date_string(add_days(date_to, 1))
        });
        dispatch({type: GENERATE_REPORT__OK});

        const [wb, filename] = create_report_workbook(response, date_from, date_to);
        XLSX.writeFile(wb, filename);
    }
    catch (failure) {
        dispatch({type: GENERATE_REPORT__FAIL, error: 'Не удалось создать отчёт: ' + failure});
        throw failure;
    }
};


const initial = {
    loading: false,
    error: null
};

export const reducer = (state = initial, action) => {
    switch (action.type) {
        case GENERATE_REPORT:
            return {loading: true, error: null};
        case GENERATE_REPORT__OK:
            return {loading: false, error: null};
        case GENERATE_REPORT__FAIL:
            return {loading: false, error: action.error};
        default:
            return state;
    }
}


const create_report_workbook = (data, date_from, date_to) => {
    const {usernames, deals} = data;
    const now = new Date();
    const complete_deals = deals
        .filter(deal => !deal.params.exclude_from_ranktable)
        .filter(deal => is_complete_deal(deal, now))
        .filter(deal => deal.params.locked)
    ;

    const lines = [];

    for (let deal of complete_deals) {
        const date = deal_result_date_in_period(deal.params, date_from, date_to, {only_unpaid: true, payments_not_after: now});
        if (!date) continue;

        const result = deal_result_in_period(deal.params, date_from, date_to, {only_unpaid: true, payments_not_after: now});
        if (!result) continue;

        lines.push({
            username: usernames[deal.user_id],
            date,
            title: deal.params.title,
            basis: deal.params.basis,
            fuel_type: deal.params.fuel_type,
            result
        });
    }

    lines.sort((a, b) => {
        if (a.username < b.username) return -1;
        if (a.username > b.username) return +1;
        if (a.date < b.date) return -1;
        if (a.date > b.date) return +1;
        if (a.title < b.title) return -1;
        if (a.title > b.title) return +1;
        if (a.basis < b.basis) return -1;
        if (a.basis > b.basis) return +1;
        if (a.fuel_type < b.fuel_type) return -1;
        if (a.fuel_type > b.fuel_type) return +1;
        return 0;
    });

    const report_name = `Отчёт за ${rus_date_string(date_from)}—${rus_date_string(date_to)}`;

    const xls_lines = [
        [report_name],
        ['Менеджер', 'Дата', 'Клиент', 'Базис', 'Тип топлива', 'Результат']
    ].concat(
        lines.map(line => [line.username, line.date, line.title, line.basis, line.fuel_type, {v: Math.round(line.result*100)/100, t: 'n', z: '0.00'}])
    );

    const ws = XLSX.utils.aoa_to_sheet(xls_lines);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, report_name);
    ws['!cols'] = [
        {wch: 20},
        {wch: 10},
        {wch: 30},
        {wch: 10}
    ];
    ws['!rows'] = xls_lines.map((_, i) => ({hpt: 20}));
    return [wb, `${report_name}.xlsx`];
};


const on_click = () => {
    const ws = XLSX.utils.aoa_to_sheet([
        ['hello', 'world'],
        ['excel', 'test']
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Export");
    XLSX.writeFile(wb, 'export.xlsx');
};


class ExcelExportPageImpl extends React.PureComponent {
    constructor() {
        super(...arguments);

        const now = new Date();

        this.state = {
            from: new Date(now.getFullYear(), now.getMonth(), 1),
            to: add_days(new Date(now.getFullYear(), now.getMonth()+1, 1), -1)
        };
    }
    
    render() {
        return <Page>
            <h1>Экспорт начислений</h1>
            <Row className='mt-2 mb-3'>
                <Col>
                    <Form inline>
                        <FormGroup>
                            <Label className='mr-2'>Дата:</Label>
                            <Input className='mr-2' type='date' value={iso_date_string(this.state.from)}
                                onChange={ev => this.setState({from: date_from_string(ev.target.value)})}/>
                            —
                            <Input className='ml-2' type='date' value={iso_date_string(this.state.to)}
                                onChange={ev => this.setState({to: date_from_string(ev.target.value)})}/>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color={this.props.loading ? 'secondary' : 'primary'} disabled={this.props.loading}
                        onClick={() => this.props.onGenerateReport(this.state.from, this.state.to)}>
                        {this.props.loading ? 'Загрузка...' : 'Создать отчёт'}
                    </Button>
                </Col>
            </Row>
            {this.props.error &&
                <Row className='mt-2'>
                    <Col>
                        <Alert style={{display: 'inline-block'}} color='danger'>{this.props.error}</Alert>
                    </Col>
                </Row>}
        </Page>;
    }
}

export const ExcelExportPage = connect(
    state => state.excel_export,

    {
        onGenerateReport: generate_report
    }
)(ExcelExportPageImpl);

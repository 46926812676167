import React from 'react';
import {connect} from 'react-redux';
import {Row, Col, Table, Button, Badge, Nav, NavItem, NavLink, TabContent, TabPane,
        Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter,
        InputGroup, InputGroupAddon} from 'reactstrap';
import {push} from 'react-router-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import {get, post} from './Api';
import {Calculator} from './Calculator';
import {LOGOUT} from './Login';
import {load_users} from './Users';
import plural from './plural';
import {date_from_string, iso_date_string, format_price, month_names} from './utils.js';
import {DONE_FILTER, filter_by_done, base_deal_date, date_in_optional_inclusive_range,
        deal_result_in_period, filter_by_debt, DEBT_FILTER, deal_validation_errors,
        deal_debt, when_deal_may_be_paid, deal_result_if_paid_today, is_complete_deal,
        deal_debt_days, is_cash_sell_deal, deal_has_files, simple_add_days} from './deals-math';
import {BaseField, TextInput} from './form-controls';
import {Page} from './Page';


const LOADING_DEALS = 'LOADING_DEALS';
const LOAD_DEALS__OK = 'LOAD_DEALS__OK';
const LOAD_DEALS__FAIL = 'LOAD_DEALS__FAIL';

const SAVING_DEAL = 'SAVING_DEAL';
const SAVING_DEAL__OK = 'SAVING_DEAL__OK';
const SAVING_DEAL__FAIL = 'SAVING_DEAL__FAIL';

const SAVING_NEW_DEAL = 'SAVING_NEW_DEAL';
const SAVING_NEW_DEAL__OK = 'SAVING_NEW_DEAL__OK';
const SAVING_NEW_DEAL__FAIL = 'SAVING_NEW_DEAL__FAIL';

const DELETE_DEAL = 'DELETE_DEAL';

const LOADING_ALL_DEALS = 'LOADING_ALL_DEALS';
const LOAD_ALL_DEALS__OK = 'LOAD_ALL_DEALS__OK';
const LOAD_ALL_DEALS__FAIL = 'LOAD_ALL_DEALS__FAIL';


const load_deals = (user_id) => async (dispatch) => {
    dispatch({type: LOADING_DEALS, user_id});
    try {
        const response = await get('deals', {user_id});
        dispatch({type: LOAD_DEALS__OK, user_id, deals: response});
    }
    catch (failure) {
        dispatch({type: LOAD_DEALS__FAIL, user_id, loading_failure: failure});
    }
};

const load_all_deals = (done_filter, date_from, date_to) => async (dispatch) => {
    dispatch({type: LOADING_ALL_DEALS, date_from, date_to});
    try {
        const response = await get('all-deals', {
            done_filter: done_filter,
            date_from: [DONE_FILTER.ALL, DONE_FILTER.DONE].indexOf(done_filter) != -1 ? iso_date_string(date_from) : null,
            date_to: [DONE_FILTER.ALL, DONE_FILTER.DONE].indexOf(done_filter) != -1 ? iso_date_string(date_to) : null,
        });
        dispatch({
            type: LOAD_ALL_DEALS__OK,
            done_filter,
            date_from, date_to,
            deals: response.deals,
            usernames: response.usernames
        });
    }
    catch (failure) {
        dispatch({type: LOAD_ALL_DEALS__FAIL, loading_failure: failure});
    }
};

const save_deal = (user_id, deal_id, params, ok_url) => async (dispatch) => {
    dispatch({type: SAVING_DEAL, user_id});
    try {
        const response = await post('save-deal', {
            user_id, deal_id,
            params: {
                ...params,
                last_date: iso_date_string(base_deal_date(params)),
                penalty_date: iso_date_string(date_from_string(params.penalty_date))
            },
            debt: deal_debt(params)
        });
        dispatch({type: SAVING_DEAL__OK, user_id, deal_id, params});
        dispatch(push(ok_url));
    }
    catch (failure) {
        console.log(failure);
        dispatch({type: SAVING_DEAL__FAIL, user_id, failure});
    }
};

const save_new_deal = (user_id, params, ok_url) => async (dispatch) => {
    dispatch({type: SAVING_NEW_DEAL, user_id});
    try {
        const response = await post('save-new-deal', {
            user_id, 
            params: {
                ...params,
                last_date: iso_date_string(base_deal_date(params)),
                penalty_date: iso_date_string(date_from_string(params.penalty_date))
            },
            debt: deal_debt(params)
        });
        dispatch({
            type: SAVING_NEW_DEAL__OK,
            user_id,
            deal_id: response.id,
            params: response.params
        });
        dispatch(push(ok_url));
    }
    catch (failure) {
        console.log(failure);
        dispatch({type: SAVING_NEW_DEAL__FAIL, user_id, failure});
    }
};

const delete_deal = (user_id, deal_id) => (dispatch) => {
    if (confirm('Действительно удалить запись о сделке?')) {
        post('delete-deal', {user_id, id: deal_id});
        dispatch({type: DELETE_DEAL, user_id, deal_id});
    }
};


const by_user_initial = {
    loaded: false,
    loading: false,
    fail: false,

    deals: [],

    saving_deal: false,
    saving_deal_fail: false,

    saving_new_deal: false,
    saving_new_deal_fail: false,
};


const sort_deals = (deals) => deals.slice().sort((d1, d2) => d1.params.date > d2.params.date);

const user_reducer = (state = by_user_initial, action) => {
    switch (action.type) {
        case LOADING_DEALS: return {...state, loading: true, fail: false};
        case LOAD_DEALS__OK: return {...state, loading: false, loaded: true, fail: false, deals: action.deals};
        case LOAD_DEALS__FAIL: return {...state, loading: false, loaded: true, fail: true, deals: []};

        case SAVING_DEAL: return {...state, saving_deal: true, saving_deal_fail: false};
        case SAVING_DEAL__OK: return {
            ...state,
            saving_deal: false,
            saving_deal_fail: false,
            deals: sort_deals(state.deals.map(deal => deal.id == action.deal_id ? {...deal, params: action.params} : deal))
        };
        case SAVING_DEAL__FAIL: return {...state, saving_deal: false, saving_deal_fail: true};

        case SAVING_NEW_DEAL: return {...state, saving_new_deal: true, saving_new_deal_fail: false};
        case SAVING_NEW_DEAL__OK:
            return {
                ...state,
                saving_new_deal: false,
                saving_new_deal_fail: false,
                deals: sort_deals([
                    ...state.deals,
                    {id: action.deal_id, params: action.params}
                ])
            };
        case SAVING_NEW_DEAL__FAIL: return {...state, saving_new_deal: false, saving_new_deal_fail: true};

        case DELETE_DEAL: return {
            ...state,
            deals: state.deals.filter(deal => deal.id != action.deal_id)
        }

        case LOGOUT: return by_user_initial;

        default: return state;
    }
};


const all_deals_initial = {
    loaded: false,
    loading: false,
    fail: false,

    date_from: null,
    date_to: null,
    deals: [],
    usernames: {}
};

const all_deals_reducer = (state = all_deals_initial, action) => {
    switch (action.type) {
        case LOADING_ALL_DEALS: return {
            ...state,
            loading: true, fail: false,
            date_from: action.date_from, date_to: action.date_to
        };
        case LOAD_ALL_DEALS__OK: return {
            ...state,
            loading: false, loaded: true, fail: null,
            date_from: action.date_from,
            date_to: action.date_to,
            deals: action.deals,
            usernames: action.usernames
        };
        case LOAD_ALL_DEALS__FAIL: return {
            ...state,
            loading: false, loaded: true,
            fail: action.loading_failure
        };
        default: return state;
    }
};


const initial = {
    by_user: by_user_initial,
    all_deals: all_deals_initial
}

export const reducer = (state = initial, action) => {
    if (action.user_id != null) {
        return {
            ...state,
            by_user: {
                ...state.by_user,
                [action.user_id]: user_reducer(state.by_user[action.user_id], action)
            }
        }
    }
    else
        return {
            ...state,
            all_deals: all_deals_reducer(state.all_deals, action)
        };
};


const cmp_deals_by_date = (a, b) => {
    if (! a.params.date) return -1;
    if (! b.params.date) return +1;
    return date_from_string(b.params.date).getTime() - date_from_string(a.params.date).getTime();
};


const DealsListImpl = ({deals, period_start, period_end, view_href, edit_href, firstHeader, firstCell, onDeleteDeal, onPushUrl}) => {
    const now = new Date();
    const rows = deals.slice().sort(cmp_deals_by_date).map(deal => {
        let debt_days_element;
        const debt_days = deal_debt_days(deal);
        if (debt_days == null)
            debt_days_element = null;
        else if (debt_days < 0)
            debt_days_element = <span className='text-success' title='Количество дней до оплаты'>({-debt_days} дн) </span>;
        else
            debt_days_element = <span className='text-danger' title='Количество дней просрочки'>({debt_days} дн) </span>;

        return <tr key={deal.id}
                   onClick={(ev) => {
                       // FIXME: too hacky :(
                       if (ev.target.tagName != 'A' && (ev.target.parentElement && ev.target.parentElement.tagName) != 'A')
                           onPushUrl(edit_href(deal.user_id, deal.id))}
                    }
                   style={{
                       cursor: 'pointer',
                       backgroundColor: deal.params.exclude_from_ranktable ? '#e0e0e0' : null
                    }}>
            {firstCell && firstCell(deal)}
            <td>{deal.params && deal.params.date && date_from_string(deal.params.date).toLocaleDateString()}</td>
            <td className='text-right' style={{width: '100px', whiteSpace: 'nowrap'}}>
                {/* {format_price(deal_result_in_period(deal.params, period_start, period_end))} */}
                {format_price(
                    is_complete_deal(deal)
                    ? deal_result_in_period(deal.params, period_start, period_end)
                    : deal_result_if_paid_today(deal.params)
                )}
            </td>
            <td>{is_cash_sell_deal(deal.params) &&
                    <Badge color='' className='border border-success text-success' title='Нал'>
                        <span className='oi oi-dollar'/>
                    </Badge>}
            </td>
            <td className='text-right' style={{width: '100px', whiteSpace: 'nowrap'}}>
                {debt_days_element}
                {format_price(deal_debt(deal.params, now))}
            </td>
            <td>
                {deal.params.status == 'paid' &&
                    <Badge color='' className='border border-success text-success' title='Оплачено'>
                        <span className='oi oi-check'/>
                    </Badge>
                }
            </td>
            <td>
                {deal.params.locked &&
                    <Badge color='' className='border border-info text-info' title='Проверено'>
                        <span className='oi oi-lock-locked'/>
                    </Badge>
                }
            </td>
            <td>{deal_has_files(deal.params) &&
                    <Badge color='' className='border border-primary text-primary' title='Сканы'>
                        <span className='oi oi-file'/>
                    </Badge>}
            </td>
            <td>{deal.params && deal.params.title}</td>
            <td>{deal.params && deal.params.basis}</td>
            <td>{deal.params && deal.params.fuel_type}</td>
            <td>{deal.params && deal.params.ttn_number}</td>
            <td width='1' style={{whiteSpace: 'nowrap'}}>
                {view_href &&
                    <Link to={view_href(deal.user_id, deal.id)} className='btn btn-sm btn-outline-primary'>
                        <span className='oi oi-eye'/>
                    </Link>
                }
                <Link to={edit_href(deal.user_id, deal.id)} className={
                    classnames('btn', 'btn-sm', 'ml-2', {
                        'btn-outline-primary': !view_href,
                        'btn-outline-info': !!view_href,
                    })
                }>
                    {view_href ? <span className='oi oi-pencil'/> : 'Редактировать'}
                </Link>
                {onDeleteDeal && <a href='#' className='btn btn-sm btn-outline-danger ml-4'
                    onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteDeal(deal.user_id, deal.id);
                    }}>
                    <span className='oi oi-trash'/>
                </a>}
            </td>
        </tr>;
    });

    return <div className='mt-4'>
        <Table responsive hover>
            <thead>
                <tr>
                    {firstHeader && firstHeader()}
                    <th style={{width: '1px'}}>Дата&nbsp;реал.</th>
                    <th className='text-right'>Итог</th>
                    <th style={{width: '1px', textAlign: 'center'}} title='Нал'><span className='oi oi-dollar'/></th>
                    <th className='text-right'>Долг</th>
                    <th style={{width: '1px', textAlign: 'center'}} title='Оплачено'><span className='oi oi-check'/></th>
                    <th style={{width: '1px', textAlign: 'center'}} title='Проверено'><span className='oi oi-lock-locked'/></th>
                    <th style={{width: '1px', textAlign: 'center'}} title='Сканы'><span className='oi oi-file'/></th>
                    <th>Клиент</th>
                    <th>Базис</th>
                    <th>Топливо</th>
                    <th>Номер автом., ФИО вод-ля</th>
                    <th>Действия</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    </div>
};

export const DealsList = connect(
    null,

    {
        onPushUrl: push
    }
)(DealsListImpl);


const sort_by_key = (items, key_func) =>
    items.slice().sort((a, b) => {
        const ka = key_func(a);
        const kb = key_func(b);
        return kb - ka;
    });

const PendingDealsList = ({deals, view_href, edit_href, onDeleteDeal, firstHeader, firstCell}) => {
    const by_month = {};
    sort_by_key(deals, when_deal_may_be_paid).forEach(deal => {
        const date = when_deal_may_be_paid(deal);
        const month_no = date.getFullYear() * 12 + date.getMonth();
        if (!by_month[month_no])
            by_month[month_no] = [];
        by_month[month_no].push(deal);
    });

    const month_no_to_human = month_no =>
        `${month_names[month_no % 12]} ${parseInt(month_no/12, 10)}`;

    return <div className='mt-4'>{
        Object.keys(by_month).sort().map(month_no =>
            <React.Fragment key={month_no}>
                <h4>План на {month_no_to_human(month_no)}</h4>

                <p>
                    <b>{by_month[month_no].length}</b>
                    {' '}{plural(by_month[month_no].length, 'сделка', 'сделки', 'сделок')}
                    {' '}на общую сумму
                    {' '}<b>{format_price(by_month[month_no].reduce((acc, deal) => acc + deal_result_if_paid_today(deal.params), 0))}</b>
                </p>

                <DealsList
                    deals={by_month[month_no]}
                    view_href={view_href}
                    edit_href={edit_href}
                    onDeleteDeal={onDeleteDeal}
                    firstHeader={firstHeader}
                    firstCell={firstCell}
                />
            </React.Fragment>
        )
    }</div>;
};


const deals_url = (date_from, date_to, done_filter, debt_filter, tab) =>
    `${iso_date_string(date_from)}--${iso_date_string(date_to)}--${done_filter}--${debt_filter}--${tab}`;


class DealsPageImpl extends React.Component {
    constructor(props) {
        super(...arguments);

        this.setDateFrom = this.setDateFrom.bind(this);
        this.setDateTo = this.setDateTo.bind(this);
        this.setDoneFilter = this.setDoneFilter.bind(this);

        this.state = {
            search: ''
        };
    }

    redirect({date_from, date_to, done_filter, debt_filter, tab}) {
        this.props.onRedirect(deals_url(
            date_from !== undefined ? date_from : this.props.date_from,
            date_to !== undefined ? date_to : this.props.date_to,
            done_filter !== undefined ? done_filter : this.props.done_filter,
            debt_filter !== undefined ? debt_filter : this.props.debt_filter,
            tab !== undefined ? tab : this.props.tab,
        ));
    }

    filter_deals() {
        const from = this.props.date_from ? date_from_string(this.props.date_from) : null;
        const to = this.props.date_to ? date_from_string(this.props.date_to) : null;
        const is_date_ok = deal => {
            const base_date = base_deal_date(deal.params);
            const penalty_date = date_from_string(deal.params.penalty_date);

            return date_in_optional_inclusive_range(base_date, from, to) || date_in_optional_inclusive_range(penalty_date, from, to);
        };

        const normalize_for_search = subject =>
            (subject || '').trim().toLowerCase().replace(/,/g, '.');

        const needle = normalize_for_search(this.state.search);
        const deal_matches_search = deal => {
            if (needle.length == 0) return true;
            if (normalize_for_search(deal.params.title).indexOf(needle) !== -1) return true;
            if (normalize_for_search(deal.params.basis).indexOf(needle) !== -1) return true;
            if (normalize_for_search(deal.params.ttn_number).indexOf(needle) !== -1) return true;
            return false;
        }

        let deals = this.props.deals;
        if ([DONE_FILTER.ALL, DONE_FILTER.DONE].indexOf(this.props.done_filter) != -1)
            deals = deals.filter(is_date_ok);
        deals = filter_by_done(deals, this.props.done_filter)
        deals = filter_by_debt(deals, this.props.debt_filter);
        deals = deals.filter(deal_matches_search);
        return deals;
    }

    setDateFrom(ev) {
        const date_from = date_from_string(ev.target.value);
        this.redirect({date_from});
        this.raiseOnQueryChanged({date_from});
    }

    setDateTo(ev) {
        const date_to = date_from_string(ev.target.value);
        this.redirect({date_to});
        this.raiseOnQueryChanged({date_to});
    }

    setDoneFilter(done_filter) {
        this.redirect({done_filter});
        this.raiseOnQueryChanged({done_filter});
    }

    raiseOnQueryChanged({done_filter, date_from, date_to}) {
        if (!this.props.onQueryChanged)
            return;
        this.props.onQueryChanged(
            done_filter === undefined ? this.props.done_filter : done_filter,
            date_from === undefined ? this.props.date_from : date_from,
            date_to === undefined ? this.props.date_to : date_to
        );
    }

    render() {
        const deals = this.filter_deals();

        const total = deals.reduce((acc, deal) => {
            let total = 0;
            if (is_complete_deal(deal))
                total = deal_result_in_period(deal.params, this.props.date_from, this.props.date_to);
            else
                total = deal_result_if_paid_today(deal.params);
            return acc + (isNaN(total) ? 0 : total);
        }, 0);


        const form = <Row className='mt-2 mb-3'>
            <Col sm={4}>
                <BaseField label='Показывать' type='select' value={this.props.done_filter} onChange={this.setDoneFilter}>
                    <option value={DONE_FILTER.DONE}>Завершённые</option>
                    <option value={DONE_FILTER.NOT_PAID}>Неоплаченные</option>
                    <option value={DONE_FILTER.NOT_SHIPPED}>Неотгруженные</option>
                    <option value={DONE_FILTER.EXPIRED_DEBT}>С просроченным долгом</option>
                    <option value={DONE_FILTER.ALL}>Все</option>
                </BaseField>
            </Col>
            {[DONE_FILTER.DONE, DONE_FILTER.ALL].indexOf(this.props.done_filter) != -1 && <Col sm={4}>
                <FormGroup>
                    <Label className='mr-2'>Дата:</Label>
                    <Form inline>
                    <Input className='mr-2' type='date' value={iso_date_string(this.props.date_from) || ''}
                        onChange={this.setDateFrom}/>
                    —
                    <Input className='ml-2' type='date' value={iso_date_string(this.props.date_to) || ''}
                        onChange={this.setDateTo}/>
                    </Form>
                </FormGroup>
            </Col>}
            {[DONE_FILTER.NOT_PAID, DONE_FILTER.NOT_SHIPPED, DONE_FILTER.EXPIRED_DEBT].indexOf(this.props.done_filter) != -1 && <Col sm={4}>
                <BaseField label='Долг' type='select' value={this.props.debt_filter} onChange={value => this.redirect({debt_filter: value})}>
                    <option value={DEBT_FILTER.ALL}>Все</option>
                    <option value={DEBT_FILTER.NONZERO_DEBT}>С ненулевым долгом</option>
                </BaseField>
            </Col>}
            <Col sm={4}>
                <TextInput label='Поиск по Клиенту или Автомобилю/ФИО вод-ля' value={this.state.search} onChange={value => this.setState({search: value})}/>
            </Col>
        </Row>;

        
        let content = null;
        if (this.props.loading)
            content = <p className="text-muted">Загрузка...</p>;

        else if (this.props.fail)
            content = <p className="text-danger">Ошибка загрузки</p>;

        else
            content = <React.Fragment>
                <Row>
                    <Col sm={5}>
                        <p>Итого: <strong>{deals.length}</strong>{' '}
                        {plural(deals.length, 'сделка', 'сделки', 'сделок')}{' '}
                        на общую сумму <strong>{format_price(total)}</strong></p>
                    </Col>
                </Row>
                {[DONE_FILTER.NOT_PAID, DONE_FILTER.NOT_SHIPPED, DONE_FILTER.EXPIRED_DEBT].indexOf(this.props.done_filter) != -1  ?
                    <PendingDealsList deals={deals}
                        view_href={this.props.view_href}
                        edit_href={this.props.edit_href}
                        onDeleteDeal={this.props.onDeleteDeal}
                        firstHeader={this.props.firstHeader}
                        firstCell={this.props.firstCell}/>
                    :
                    <React.Fragment>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={this.props.tab == 'all' ? 'active' : ''} onClick={() => this.redirect({tab: 'all'})} href='#'>
                                    Все сделки
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={this.props.tab == 'by_week' ? 'active' : ''} onClick={() => this.redirect({tab: 'by_week'})} href='#'>
                                    По неделям
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={this.props.tab == 'by_month' ? 'active' : ''} onClick={() => this.redirect({tab: 'by_month'})} href='#'>
                                    По месяцам
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.props.tab}>
                            <TabPane tabId='all'>
                                <DealsList deals={deals}
                                    period_start={this.props.date_from}
                                    period_end={this.props.date_to}
                                    view_href={this.props.view_href}
                                    edit_href={this.props.edit_href}
                                    onDeleteDeal={this.props.onDeleteDeal}
                                    firstHeader={this.props.firstHeader}
                                    firstCell={this.props.firstCell}/>
                            </TabPane>
                            <TabPane tabId='by_week'>
                                <DealsByWeek deals={deals}/>
                            </TabPane>
                            <TabPane tabId='by_month'>
                                <DealsByMonth deals={deals}/>
                            </TabPane>
                        </TabContent>
                    </React.Fragment>
                }
            </React.Fragment>;


        return <React.Fragment>
            {form}
            {content}
        </React.Fragment>;
    }
}

export const DealsPage = connect(
    null,
    (dispatch) => ({
        onDeleteDeal: (user_id, deal_id) => dispatch(delete_deal(user_id, deal_id)),
    })
)(DealsPageImpl);


class MyDealsPageImpl extends React.Component {
    componentDidMount() {
        this.props.onLoadDeals(this.props.user_id);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.loaded && !nextProps.loading)
            this.props.onLoadDeals(nextProps.user_id);
    }

    render() {
        return <Page wide>
            <Row className='align-items-center'>
                <Col xs={7}><h1>Мои сделки</h1></Col>
                <Col xs={5} className='text-right'>
                    <Link to='/new-deal' className='btn btn-outline-success'>Добавить сделку</Link>
                </Col>
            </Row>
            <DealsPage
                {...this.props}
                edit_href={(user_id, deal_id) => `/deal/${deal_id}/edit`}
            />
        </Page>;
    }
}

export const MyDealsPage = connect(
    (state) => ({
        user_id: state.login.user_id,
        ...(state.deals.by_user[state.login.user_id] || by_user_initial)
    }),
    dispatch => ({
        onLoadDeals: user_id => dispatch(load_deals(user_id))
    })
)(MyDealsPageImpl);


class OthersDealsPageImpl extends React.Component {
    componentDidMount() {
        if (!this.props.username)
            this.props.onLoadUsers();

        this.props.onLoadDeals(this.props.user_id);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.username)
            nextProps.onLoadUsers();

        if (!nextProps.loaded && !nextProps.loading)
            this.props.onLoadDeals(nextProps.user_id);
    }

    render() {
        return <Page wide>
            <Link to='/'>← к списку пользователей</Link>
            <Row className='align-items-center'>
                <Col xs={7}><h1>
                    Сделки {this.props.username}
                </h1></Col>
                <Col xs={5} className='text-right'>
                    <Link to={`/users/${this.props.user_id}/new-deal`} className='btn btn-outline-success'>Добавить сделку</Link>
                </Col>
            </Row>
            <DealsPage
                {...this.props}
                edit_href={(user_id, deal_id) => `/users/${user_id}/deal/${deal_id}/edit`}
                view_href={(user_id, deal_id) => `/users/${user_id}/deal/${deal_id}/view`}
            />
        </Page>;
    }
}

export const OthersDealsPage = connect(
    (state, {user_id}) => {
        const user = (state.users.users || []).filter(user => user.id == user_id)[0];
        return {
            username: user && user.username,
            ...(state.deals.by_user[user_id] || by_user_initial),
        }
    },
    dispatch => ({
        onLoadUsers: () => dispatch(load_users()),
        onLoadDeals: user_id => dispatch(load_deals(user_id))
    })
)(OthersDealsPageImpl);


class AllDealsPageImpl extends React.PureComponent {
    componentDidMount() {
        this.props.onLoadDeals(this.props.done_filter, this.props.date_from, this.props.date_to);
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.state.loaded && !nextProps.state.loading)
            this.props.onLoadDeals(this.props.done_filter, nextProps.date_from, nextProps.date_to);
    }

    render() {
        return <Page wide>
            <Row className='align-items-center'>
                <Col><h1>Все сделки</h1></Col>
            </Row>
            <DealsPage
                {...this.props.state}
                date_from={this.props.date_from}
                date_to={this.props.date_to}
                done_filter={this.props.done_filter}
                tab={this.props.tab}
                onRedirect={this.props.onRedirect}
                edit_href={(user_id, deal_id) => `/users/${user_id}/deal/${deal_id}/edit`}
                view_href={(user_id, deal_id) => `/users/${user_id}/deal/${deal_id}/view`}
                onQueryChanged={this.props.onLoadDeals}
                firstHeader={() => <th>Менеджер</th>}
                firstCell={deal => <td>{this.props.state.usernames[deal.user_id]}</td>}
            />
        </Page>
    }
}

export const AllDealsPage = connect(
    state => ({state: state.deals.all_deals}),
    {
        onLoadDeals: load_all_deals
    }
)(AllDealsPageImpl);



class StatefulCalculator extends React.Component {
    constructor(props) {
        super(props);

        this.state = props.deal || {
            params: {
                deal_type: 'buy_kg__sell_l',
				date: simple_add_days(new Date(), 1),
				supplier_pay_date: simple_add_days(new Date(), 0),
				expected_pay_date: simple_add_days(new Date(), 1),
                payments: []
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.deal != nextProps.deal)
            this.setState(nextProps.deal);
    }

    render() {
        return <Calculator
            params={this.state.params}
            role={this.props.role}
            onChangeField={(field, value) => this.setState({params: {...this.state.params, [field]: value}})}
            onChangeFields={setter => this.setState({params: {...this.state.params, ...setter}})}
            onAddPayment={() => this.setState({params: {...this.state.params, payments: [...this.state.params.payments, {sum: 0, date: ''}]}})}
            onRemovePayment={(number) => this.setState({params: {...this.state.params, payments: this.state.params.payments.filter((payment, i) => i != number)}})}
            onChangePaymentField={(number, field, value) => {
                this.setState({params: {
                    ...this.state.params,
                    payments: this.state.params.payments.map((payment, i) => i == number ? {...payment, [field]: value} : payment)
                }})
            }}
        />
    }
}


class EditOrNewDealForm extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            shown_errors: null
        };

        this.calculator = React.createRef();

        this.onSave = this.onSave.bind(this);
        this.closeErrors = this.closeErrors.bind(this);
    }

    onSave() {
        const errors = deal_validation_errors(this.calculator.current.state.params);
        this.setState({shown_errors: errors});
        if (errors.length == 0)
            this.props.onSave(this.calculator.current.state.params);
    }

    closeErrors() { this.setState({shown_errors: null}); }

    buttons() {
        return <React.Fragment>
            <Button outline color='secondary' onClick={this.props.onCancel}>Отмена</Button>
            {' '}
            <Button outline color='primary' onClick={this.onSave} disabled={this.props.saving}>
                {this.props.fail ? 'Ошибка сохранения' : 'Сохранить'}
            </Button>
        </React.Fragment>
    }

    render() {
        return <Page>
            <Row className='align-items-center'>
                <Col sm={8}><h1>{this.props.title}</h1></Col>
                <Col sm={4} className="text-sm-right">{this.buttons()}</Col>
            </Row>
            <StatefulCalculator ref={this.calculator} deal={this.props.deal} role={this.props.role}/>
            <Row>
                <Col className="text-sm-right">{this.buttons()}</Col>
            </Row>

            <Modal isOpen={this.state.shown_errors && this.state.shown_errors.length > 0} toggle={this.closeErrors}>
                <ModalHeader toggle={this.closeErrors}>Форма содержит ошибки</ModalHeader>
                <ModalBody>
                    <ul>{(this.state.shown_errors || []).map((error, i) =>
                        <li key={i}>{error}</li>
                    )}</ul>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={this.closeErrors}>Вернуться</Button>
                </ModalFooter>
            </Modal>
        </Page>;
    }
}


class DealEditPageImpl extends React.Component {
    componentDidMount() {
        // if (!this.props.loaded && !this.props.loading)
            this.props.onLoadDeals(this.props.user_id);
    }

    render() {
        if (!this.props.loaded && this.props.loading)
            return <h2 className="text-muted">Загрузка...</h2>

        if (!this.props.loaded)
            return <h2 className="text-danger">Ошибка загрузки</h2>

        return <EditOrNewDealForm
            title={<span>
                Редактирование сделки {
                    this.props.deal.params.locked && 
                    <span className='text-muted' style={{fontSize: '70%'}}><span className='oi oi-lock-locked'/></span>
                }
            </span>}
            deal={this.props.deal}
            role={this.props.role}
            onCancel={() => this.props.onPushUrl(this.props.cancel_href)}
            onSave={deal => this.props.onSaveDeal(this.props.user_id, this.props.deal.id, deal, this.props.ok_href)}
            saving={this.props.saving_deal}
            fail={this.props.saving_deal_fail}
        />;
    }
}

export const DealEditPage = connect(
    (state, {user_id, deal_id}) => {
        const by_user_state = state.deals.by_user[user_id] || by_user_initial;
        return {
            user_id,
            role: state.login.role,
            loaded: by_user_state.loaded,
            loading: by_user_state.loading,
            deal: by_user_state.deals.filter(deal => deal.id == deal_id)[0],
            saving_deal: by_user_state.saving_deal,
            saving_deal_fail: by_user_state.saving_deal_fail,
        }
    },

    {
        onLoadDeals: load_deals,
        onSaveDeal: save_deal,
        onPushUrl: push
    }
)(DealEditPageImpl)

export const MyDealEditPage = connect(
    (state) => ({
        user_id: state.login.user_id,
    })
)(DealEditPage);



class DealViewPageImpl extends React.Component {
    componentDidMount() {
        this.props.onLoadDeals(this.props.user_id);
    }

    buttons() {
        return <Link to={this.props.cancel_href} className='btn btn-outline-secondary'>Назад</Link>;
    }
    
    render() {
        if (!this.props.loaded && this.props.loading)
            return <h2 className="text-muted">Загрузка...</h2>

        if (!this.props.loaded)
            return <h2 className="text-danger">Ошибка загрузки</h2>

        return <Page>
            <Row className='align-items-center'>
                <Col sm={8}><h1>Просмотр сделки</h1></Col>
                <Col sm={4} className="text-sm-right">{this.buttons()}</Col>
            </Row>
            <Calculator params={this.props.deal.params} readOnly/>
            <Row>
                <Col className="text-sm-right">{this.buttons()}</Col>
            </Row>
        </Page>;
    }
}

export const DealViewPage = connect(
    (state, {user_id, deal_id}) => {
        const by_user_state = state.deals.by_user[user_id] || by_user_initial;
        return {
            user_id,
            loaded: by_user_state.loaded,
            loading: by_user_state.loading,
            deal: by_user_state.deals.filter(deal => deal.id == deal_id)[0],
        }
    },

    (dispatch) => ({
        onLoadDeals: (user_id) => dispatch(load_deals(user_id)),
    })
)(DealViewPageImpl);


const NewDealPageImpl = (props) => <EditOrNewDealForm
    title='Новая сделка'
    deal={undefined}
    role={props.role}
    onCancel={() => props.onPushUrl(props.cancel_href)}
    onSave={deal => props.onSaveNewDeal(props.user_id, deal, props.ok_href)}
    saving={props.adding_deal}
    fail={props.adding_deal_fail}
/>;

export const NewDealPage = connect(
    (state, {user_id}) => {
        const by_user_state = state.deals.by_user[user_id] || by_user_initial;
        return {
            role: state.login.role,
            adding_deal: by_user_state.adding_deal,
            adding_deal_fail: by_user_state.adding_deal_fail,
        }
    },

    {
        onSaveNewDeal: save_new_deal,
        onPushUrl: push
    }
)(NewDealPageImpl);

export const MyNewDealPage = connect(
    (state) => ({
        user_id: state.login.user_id,
    })
)(NewDealPage);



const group_by = (dt2key, key2dates, deals) => {
    const per_period = {};
    deals.forEach(deal => {
        // if (!deal.params.date)
        //     return;
        const base_date = base_deal_date(deal.params);
        const penalty_date = date_from_string(deal.params.penalty_date);

        let period_key, group;

        if (base_date) {
            period_key = dt2key(date_from_string(base_date));
            group = per_period[period_key] = per_period[period_key] || [];
            group.push(deal);
        }

        if (penalty_date) {
            period_key = dt2key(date_from_string(penalty_date));
            group = per_period[period_key] = per_period[period_key] || [];
            group.push(deal);
        }

    });
    return Object.keys(per_period).sort().map(k => parseInt(k)).map(period_key => ({
        deals: per_period[period_key],
        ...key2dates(period_key)
    }));
};

const group_by_week = (deals) => {
    const week_calc_base = new Date(2015, 0, 5, 0, 0, 0).getTime();
    const day = 24*60*60e3;
    const week = 7*day;
    const get_week_no = dt => parseInt((new Date(dt).getTime() - week_calc_base) / week);
    const week_no_to_dates = (week_no) => ({
        from: new Date(week_no * week + week_calc_base),
        to: new Date((week_no+1) * week - day + week_calc_base),
    });

    return group_by(get_week_no, week_no_to_dates, deals);
};

const group_by_month = (deals) => {
    const get_month_no = dt => dt.getFullYear() * 12 + dt.getMonth();
    const month_no_to_dates = (month_no) => {
        const year = parseInt(month_no / 12);
        const month = month_no % 12;
        return {
            month_no,
            from: new Date(year, month, 1),
            to: new Date(year, month+1, 0),
        };
    };
    return group_by(get_month_no, month_no_to_dates, deals);
};


const GroupedDealsList = ({grouping_func, deals}) => {
    const groups = grouping_func(deals);
    const groups_with_sums = groups.slice().reverse().map(group => ({
        ...group,
        sum: group.deals.reduce((acc, deal) => {
            const total = deal_result_in_period(deal.params, group.from, group.to);
            return acc + (isNaN(total) ? 0 : total);
        }, 0)
    }));
    const rows = groups_with_sums.map((group, i) => {
        const sum = group.sum;
        const prev_sum = groups_with_sums[i+1] && groups_with_sums[i+1].sum;
        let delta_percent = parseInt((sum - prev_sum) / prev_sum * 100);
        let delta_percent_str = null;
        if (!isNaN(delta_percent))
            // delta_percent_str = `${delta_percent >= 0 ? '+' : '–'}${Math.abs(delta_percent)}%`;
            delta_percent_str = <React.Fragment>
                <span className={delta_percent >= 0 ? 'text-success' : 'text-danger'}>
                    {`${delta_percent >= 0 ? '+' : '–'}${Math.abs(delta_percent)}%`}
                </span>
            </React.Fragment>;
        return <tr key={i}>
            <td>{group.from.toLocaleDateString()} — {group.to.toLocaleDateString()}</td>
            <td className='text-right'>{format_price(sum)}</td>
            <td>{delta_percent_str}</td>
        </tr>
    });


    return <Table className='mt-4' hover>
        <thead>
            <tr>
                <th>Период</th>
                <th className='text-right'>Общая сумма</th>
                <th>Изменение к прошлому периоду</th>
            </tr>
        </thead>
        <tbody>{rows}</tbody>
    </Table>
};

const DealsByWeek = (props) => <GroupedDealsList grouping_func={group_by_week} {...props}/>
const DealsByMonth = (props) => <GroupedDealsList grouping_func={group_by_month} {...props}/>

import React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';


class WithUniqueId extends React.Component {
    constructor (props) {
        super(props)
        this._id = `id_${Math.random().toString().substr(2)}`;
    }

    render () {
        return this.props.children(this._id);
    }
}


export const BaseField = (props) => (<WithUniqueId>{(id) =>
    <FormGroup>
        <Label for={id}>{props.label}</Label>
        <Input id={id} {...props} onChange={ev => props.onChange(ev.target.value)}/>
    </FormGroup>
}</WithUniqueId>)

export const TextInput = (props) =>
    <BaseField {...props} type='text'/>
;

export const TextareaInput = (props) =>
    <BaseField {...props} type='textarea' />
;


const parse_float_or_null = (string) => {
    if (string == null)
        return null;
    if (typeof string == 'number')
        return string;
    const value = parseFloat(string.replace(',', '.'));
    if (isNaN(value))
        return null;
    return value;
};

export const NumberInput = (props) =>
    <BaseField {...props} type='number'
        onChange={text => props.onChange(parse_float_or_null(text))}/>
;

export const DateInput = (props) =>
    <BaseField {...props} type='date'/>
;


export const BoundNumberField = ({readOnly, label, deal, field_name, onChangeField}) => (
    <NumberInput readOnly={readOnly} label={label} value={deal[field_name] || ''}
        onChange={value => onChangeField(field_name, value)} />
);
export const BoundNumberFieldTransfer = ({readOnly, label, transfer, field_name, onChangeField}) => (
    <NumberInput readOnly={readOnly} label={label} value={transfer[field_name] || ''}
        onChange={value => onChangeField(field_name, value)} />
);
export const BoundNumberFieldCol4 = (props) => <Col sm={4}><BoundNumberField {...props}/></Col>;


export const UnlabelledField = props => <Input {...props} onChange={ev => props.onChange(ev.target.value)}/>;
export const UnlabelledNumberInput = props =>
    <UnlabelledField {...props} type='number' onChange={text => props.onChange(parse_float_or_null(text))}/>;

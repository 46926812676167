export const month_names = [
    'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
    'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'
];

export const month_names_short = [
    'янв', 'фев', 'мар', 'апр', 'мая', 'июня',
    'июля', 'авг', 'сен', 'окт', 'ноя', 'дек'
];


export const date_from_string = (str) => {
    if (str == null)
        return null;
        
    if (typeof str == 'string') {
        if (str == '' || str == 'null')
            return null;
        const date_str = str.split(' ')[0];
        const [ys, ms, ds] = date_str.split('-');
        return new Date(parseInt(ys, 10), parseInt(ms, 10)-1, parseInt(ds, 10), 0, 0, 0);
    }

    return new Date(str);
};


export const iso_date_string = (date) => {
    if (date == null)
        return null;
    return new Date(date.getTime() - date.getTimezoneOffset()*60e3).toISOString().slice(0, 10);
}

export const rus_date_string = date => {
    if (date == null)
        return null;
    return `${pad0(date.getDate(), 2)}.${pad0(date.getMonth()+1, 2)}.${date.getFullYear()}`;
};

export const rus_date_time_string = date => {
    if (date == null)
        return null;

    return `${pad0(date.getDate(), 2)}.${pad0(date.getMonth()+1, 2)}.${date.getFullYear()} ${pad0(date.getHours(), 2)}:${pad0(date.getMinutes(), 2)}:${pad0(date.getSeconds(), 2)}`;
};


export const pad0 = (str, width) => {
    str = str.toString();
    while (str.length < width)
        str = '0' + str;
    return str;
};

export const format_price = price => format_price_x100(Math.round(price * 100));

const nnbsp = '\u202f';

export const format_price_x100 = (price_x100, {with_rouble = false} = {}) => {
    if (price_x100 == null || isNaN(price_x100) || !isFinite(price_x100))
        return '';

    const sign = price_x100 >= 0 ? '' : '–';
    const abs_x100 = Math.abs(price_x100);
    const frac = abs_x100 % 100;
    const int_part = Math.floor(abs_x100 / 100);
    let frac_str;
    switch (false) {
        case frac !== 0:
            frac_str =  '';
        case frac % 10 !== 0:
            frac_str =  `.${frac / 10}`;
        default:
            frac_str =  `.${pad0(frac, 2)}`;
    }

    let parts = [];
    let tmp = int_part;
    for (var i = 1; i <= 10; i++) {
        const group = tmp % 1000;
        tmp = Math.floor(tmp / 1000);
        parts.push(tmp !== 0 ? pad0(group, 3) : group);
        if (tmp === 0)
            break;
    }
    const int_str = parts.reverse().join(nnbsp);
    parts = [sign, int_str, frac_str];
    if (with_rouble)
        parts.push(`${nnbsp}₽`);
    return parts.join('');
};

export const round02 = (value) => {
    if (value !== null && !isNaN(value)) {
        const rounded = Math.round(value * 100) / 100;
        if (rounded % 1 == 0)
            return rounded;
        else
            return rounded.toFixed(2);
    }
    return '';
};


export const group_by = function* (collection, key_func) {
    let items = [];
    let cur_key = undefined;
    for (let item of collection) {
        const key = key_func(item);
        if (key === cur_key)
            items.push(item);
        else {
            if (cur_key !== undefined)
                yield {key: cur_key, items};
            items = [item];
            cur_key = key;
        }
    }
    if (items.length > 0)
        yield {key: cur_key, items};
};

export const unique_sort = (arr) => {
    const unique_arr = Array.from(new Set(arr));
    return unique_arr.sort();
};
import {date_from_string, iso_date_string} from './utils';


export const week_day_1_to_7 = (dt) => {
    const day = dt.getDay();
    return day == 0 ? 7 : day;
};

export const add_days = (dt, days) => new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + days);

export const round_date = dt =>
    new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0, 0);


const time_2020_02_01 = new Date(2020, 1, 1, 0, 0, 0).getTime();
export const get_cash_tax = (date = null) => {
    if (typeof date === 'string')
        date = date_from_string(date);

    if (date == null)
        date = new Date();

    if (date.getTime() < time_2020_02_01)
        return 0.89;
    return 0.88;
};


export const TRANSFER_TYPES = {
    buy_transfer: 'buy_transfer', // Купили продали перевозку
};



export const is_cash_sell_transfer = transfer => {
    switch (transfer.transfer_type) {
        case TRANSFER_TYPES.buy_kg__sell_l_cash:
        case TRANSFER_TYPES.buy_l__sell_l_cash:
        case TRANSFER_TYPES.buy_l_cash__sell_l_cash:
        case TRANSFER_TYPES.buy_l_cash__sell_kg_cash:
            return true;

        default:
            return false;
    }
};



export const transfer_refuel = transfer => parseFloat(transfer.refuel) || 0;


export const calc_lost = transfer => {
    switch (transfer.transfer_type) {
        case TRANSFER_TYPES.buy_transfer:
            return transfer.buy_amount;

    }
};

export const calc_found = transfer => {
    switch (transfer.transfer_type) {
        case TRANSFER_TYPES.buy_transfer:
            return transfer.sell_amount;
    }
};

export const calc_transfer_profit = transfer => {
    const found = calc_found(transfer);
    const lost = calc_lost(transfer);
    switch (transfer.transfer_type) {
        case TRANSFER_TYPES.buy_transfer:
            return found - lost;

        default:
            return found - lost;
    }
};
// console.log(transfer);


const mul_if_positive = (value, multiplier) => value > 0 ? value * multiplier : value;


export const calc_base_rate = (transfer) => {
    const date = date_from_string(transfer.date);
    const vat = (date && date.getFullYear() <= 2018) ? 1.18 : 1.20;

    const found = calc_found(transfer);
    const lost = calc_lost(transfer);
    switch (transfer.transfer_type) {
        case TRANSFER_TYPES.buy_transfer:
            return mul_if_positive((found - lost), 0.8 / 1.20);

        default:
            return mul_if_positive((found - lost), 0.8 / 1.20);
    }
};

export const calc_prepay_effect = ({prepay, prepay_sum, prepay_date, supplier_pay_date}) => {
    if (!prepay || !prepay_date || !supplier_pay_date)
        return 0;
    return prepay_sum * 0.24 / 365 * Math.ceil((date_from_string(supplier_pay_date).getTime() - date_from_string(prepay_date).getTime()) /24/60/60e3);
};


const is_valid_payment = (payment, not_after) => {
    const date = date_from_string(payment.date);
    if (date == null)
        return false;
    if (not_after == null)
        return true;

    // We need to compare dates, regardless of time within a date. May be there are easier ways.
    if (date.getFullYear() > not_after.getFullYear())
        return false;
    if (date.getFullYear() < not_after.getFullYear())
        return true;
    if (date.getMonth() > not_after.getMonth())
        return false;
    if (date.getMonth() < not_after.getMonth())
        return true;
    if (date.getDate() > not_after.getDate())
        return false;
    return true;
};

export const calc_payments_effects = (transfer, not_after = null) => {
    const {supplier_pay_date, payments} = transfer;
    const effects = [];

    const lost = calc_lost(transfer);

    let paid = (transfer.prepay ? transfer.prepay_sum : 0);

    let prev_date = supplier_pay_date;
    for (let i = 0; i < payments.length; i++) {
        const payment = payments[i];
        if (!is_valid_payment(payment, not_after))
            continue;
        const date = date_from_string(payment.date);
        if (!date || !supplier_pay_date)
            continue;
        const days = Math.ceil((date.getTime() - new Date(prev_date).getTime()) / 24/60/60e3);
        effects.push(Math.max(0, lost - paid) * 0.24 / 365 * days);
        paid += payment.sum;
        prev_date = new Date(date_from_string(payment.date).getTime());
    }

    return effects;
};

export const calc_total_payments_effect = (transfer, not_after = null) =>
    calc_payments_effects(transfer, not_after).reduce((a, b) => a + b, 0);

const INCOME_TAX = 0.2;

export const calc_transfer_total = (transfer, payments_not_after = null) => {
    const base_rate = calc_base_rate(transfer);
    const prepay_effect = calc_prepay_effect(transfer);
    const payments_effect = calc_total_payments_effect(transfer, payments_not_after);
    const penalty_value = (transfer.penalty || 0) * (1 - INCOME_TAX);

    return base_rate + prepay_effect - payments_effect + Math.max(0, Math.min(penalty_value, max_penalty_effect(transfer)));
};

export const transfer_debt = (transfer_params, payments_not_after=null) => {
    // if (transfer_params.payments.length == 0)
    //     return 0;  

    let left = calc_found(transfer_params) - (transfer_params.prepay ? transfer_params.prepay_sum : 0);
    transfer_params.payments
        .filter(payment => {
            const v = is_valid_payment(payment, payments_not_after);
            return v;
        })
        .forEach(payment => { left -= payment.sum });
    if (transfer_params.penalty)
        left = Math.max(0, left - transfer_params.penalty * (1 - INCOME_TAX));
    return left;
};

export const transfer_sums = (transfer_params, payments_not_after=null) => {
	
    if (calc_found(transfer_params) > 0 && calc_lost(transfer_params) > 0){
        return 2;  
	}
		
	if (calc_found(transfer_params) > 0 && calc_lost(transfer_params) == null){
		return 1;
	}
	
	return 0;
};

export const transfer_debt_days = (transfer, now = new Date()) => {
    if (is_complete_transfer(transfer))
        return null;

    const pay_date = date_from_string(transfer.params.expected_pay_date);
    if (pay_date == null)
        return null;

    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return (today - pay_date) / 24/60/60e3;
};

export const is_complete_transfer = (transfer, payments_not_after=null) => transfer_sums(transfer.params, payments_not_after) == 2;
export const is_not_paid_transfer = (transfer, payments_not_after=null) => transfer_sums(transfer.params, payments_not_after) == 1;

const not = (predicate) => function () {return !predicate(...arguments);};


export const DONE_FILTER = {
    ALL: 'all',
    DONE: 'done',
    NOT_PAID: 'not_paid',
    NOT_SHIPPED: 'not_shipped',
    EXPIRED_DEBT: 'expired_debt'
};

export const filter_by_done = (transfers, filter, payments_not_after=null) => {
    const is_complete = transfer => is_complete_transfer(transfer, payments_not_after);
    const is_not_paid = transfer => is_not_paid_transfer(transfer, payments_not_after);

    switch (filter) {
        case DONE_FILTER.DONE:
            return transfers.filter(is_complete);
        case DONE_FILTER.NOT_PAID:
            return transfers.filter(is_not_paid);
        default:
            return transfers;
    }
};


export const DEBT_FILTER = {
    ALL: 'all',
    NONZERO_DEBT: 'nonzero_debt'
};

export const filter_by_debt = (transfers, filter, payments_not_after=null) => {
    switch (filter) {
        case DEBT_FILTER.NONZERO_DEBT:
            return transfers.filter(transfer => Math.abs(transfer_debt(transfer.params, payments_not_after)) >= 1);
        default:
            return transfers;
    }
};


export const is_expected_to_be_paid = (transfer) => {
    const pay_date = date_from_string(transfer.params.expected_pay_date);
    if (pay_date == null)
        return false;

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return pay_date < today;
};


// export const transfer_account_date = (transfer) => {
//     const {payments, date, penalty, penalty_date} = transfer;

//     if (penalty && penalty_date)
//         return date_from_string(penalty_date);

//     return base_transfer_date(transfer);
// };


export const base_transfer_date = (transfer, payments_not_after=null) => {
    const {payments, date, prepay_date} = transfer;

    const candidates = [
        (() => {
            if (payments) {
                for (let i = payments.length - 1; i >= 0; i--) {
                    if (!is_valid_payment(payments[i], payments_not_after))
                        continue;
                    return date_from_string(payments[i].date);
                }
            }
        })(),
        date_from_string(prepay_date),
        date_from_string(date)
    ].filter(dt => dt);

    if (candidates.length == 0)
        return null;

    return new Date(Math.max(...candidates));
};


export const date_in_optional_inclusive_range = (date, start, end) => {
    if (!start && !end)
        return true;
    if (start && !end)
        return date >= start;
    if (!start && end)
        return date <= end;
    return start <= date && date <= end;
};


export const transfer_result_date_in_period = (transfer, start, end, {only_unpaid=false, payments_not_after=null} = {}) => {
    const base_date = base_transfer_date(transfer, payments_not_after);
    const penalty_date = date_from_string(transfer.penalty_date);

    if (transfer.penalty && date_in_optional_inclusive_range(penalty_date, start, end)
        && (!only_unpaid || !transfer.penalty_paid))
        return penalty_date;

    if (date_in_optional_inclusive_range(base_date, start, end)
        && (!only_unpaid || transfer.status !== 'paid'))
        return base_date;

    return null;
};


export const max_penalty_effect = (transfer, payments_not_after=null) => {
    const supplier_pay_date = date_from_string(transfer.supplier_pay_date);
    const pay_date = date_from_string(transfer.expected_pay_date);
    const payments_effect = calc_total_payments_effect(transfer, payments_not_after);

    if (pay_date && supplier_pay_date)
    {
        const days = Math.ceil((pay_date.getTime() - supplier_pay_date.getTime()) / 24/60/60e3);
        const lost = calc_lost(transfer) - (transfer.prepay ? transfer.prepay_sum : 0);
        return payments_effect - Math.max(0, lost) * 0.24 / 365 * days;
    }
    else
        return payments_effect;
};


export const transfer_result_in_period = (transfer, start, end, {only_unpaid=false, payments_not_after=null} = {}) => {
    let result = 0;

    const base_date = base_transfer_date(transfer, payments_not_after);
    const payments_effect = calc_total_payments_effect(transfer, payments_not_after);
    const base_result = calc_base_rate(transfer) + calc_prepay_effect(transfer) - payments_effect;

    if (date_in_optional_inclusive_range(base_date, start, end)
        && (!only_unpaid || transfer.status != 'paid'))
        result += base_result;

    const penalty_date = date_from_string(transfer.penalty_date);
    if (transfer.penalty && date_in_optional_inclusive_range(penalty_date, start, end)
        && (!only_unpaid || !transfer.penalty_paid)) {
        const penalty_value = (transfer.penalty || 0) * (1 - INCOME_TAX);
        result += Math.max(0, Math.min(penalty_value, max_penalty_effect(transfer, payments_not_after)));
    }

    return result;
};


export const when_transfer_may_be_paid = transfer => {
    let last_payment_date = null;
    (transfer.payments || []).forEach(payment => {
        const date = date_from_string(payment.date);
        if (!last_payment_date || date > last_payment_date)
            last_payment_date = date;
    });

    const date_candidates = [
        round_date(new Date()),
        date_from_string(transfer.expected_pay_date),
        last_payment_date
    ].filter(dt => dt);
    
    return new Date(Math.max(...date_candidates));
};


export const transfer_result_if_paid_today = transfer => {
    const debt = transfer_debt(transfer);

    if (debt <= 0)
        return transfer_result_in_period(transfer, null, null);

    const debt_payment_date = when_transfer_may_be_paid(transfer);

    return transfer_result_in_period({
        ...transfer,
        payments: [
            ...(transfer.payments || []),
            {
                date: debt_payment_date,
                sum: debt
            }
        ]
    }, null, null);
};



export const transfer_validation_errors = (transfer) => {
    const empty = value => !value || value.trim().length == 0;

    const errors = [];

    if (empty(transfer.title))
        errors.push('Заполните поле Клиент');

    if (empty(transfer.basis))
        errors.push('Заполните поле Базис');

    if (empty(transfer.fuel_type))
        errors.push('Заполните поле Тип топлива');

    if (empty(transfer.ttn_number))
        errors.push('Заполните поле Номер ТТН');

    if (date_from_string(transfer.expected_pay_date) == null)
        errors.push('Заполните поле Дата поступления оплаты')

    if (transfer.locked && !transfer_has_files(transfer))
        errors.push('Загрузите все файлы Заявка, Документы от перевозчика, УПД');

    return errors;
};


export const transfer_has_files = transfer => transfer.file_upd_spec && transfer.file_upd && transfer.file_spec;
